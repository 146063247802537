%listgrid3 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  //place-content: flex-end space-between;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  &:after {
    content: "";
    -ms-flex: auto;
    flex: auto;
    width: 30.4%;
    display: block;
  }

  &.cnt3 {
    &:after {
      display: none;
    }
  }

  @include bp('mobile') {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

%listgrid3__trigger {
  width: 30.4%;
  flex-shrink: 1;

  &:last-of-type {
    margin-left: 4.5%;
  }

  &:nth-child(3n),
  &:nth-child(3n +1),
  &:first-of-type {
    margin-left: 0;
  }

  @include bp('tablet') {
    width: 48%;

    &:last-of-type {
      margin-left: 0;
    }
  }

  @include bp('mobile') {
    -ms-flex-wrap: wrap;
    width: 100%;
    align-self: left;

    &:last-of-type {
      margin-left: 0;
    }
  }
}

.l-listgrid--3__content {

}

