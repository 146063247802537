.m-digest-entity {
  margin-bottom: 50px;
}

.m-digest-entity__tags {
  li {
    display: inline-block;
    &:not(:last-child) {
      padding-right: 8px;
    }
  }
  ul {
    display: inline-block;
    padding: 0;
    margin: 0;
  }
}

.m-digest-entity__text {
  @include content_formater;
}


.m-digest-entity__title a {
  @include font1;
  @include font_size_line_height(18, 24);
  color:$blue-light;
  font-weight: $regular;
}
