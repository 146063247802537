$height: 53;

.m-toc {
  @extend %ul;
  width: 465px;
  border-radius: 26.5px;
  background-color: #EEEFF2;
  margin: 0 auto 60px auto;

  @include bp('mobile') {
    width: 100%;
    margin: 0 auto 25px auto;
  }

  @include bp('print') {
    display: none;
  }
}

%tocitem_base {
  @extend %list_item;

  a {
    margin-left: 32px;
    margin-right: 32px;
    display: block;
  }
}

.m-toc__selector {
  @extend %tocitem_base;

  a {
    @include icon('\EA03');
    @include font1();
    font-size: px2em(15);
    font-weight: $bold;
    line-height: px2em($height, 15);
    color: $blue-light;

    &:before {
      color: $blue-light;
      left: auto;
      right: 0;
      font-size: 28px;
      line-height: 190%;

      @include bp('mobile') {
        font-size: 25px;
      }
    }
  }
}

.m-toc__trigger {
  @extend %tocitem_base;
  display: none;

  a {
    padding: 14px 0;
    display: block;
    font-size: px2em(17);
    line-height: px2em(26, 17);
    color: $blue-light;
    font-weight: $regular;

    @include bp('mobile') {
      font-size: 1em;
    }
  }

  &:hover {
    a {
      text-decoration: none;
    }
  }
}

.m-toc__trigger + .m-toc__trigger {
  a {
    border-top: 1px solid $grey3;
  }
}

////STATES//////
.m-toc {
  &.#{$isopend} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .m-toc__selector {
      a {
        &:before {
          content: '\EA04';
        }
      }
    }

    .m-toc__trigger {
      display: block;
    }
  }
}

