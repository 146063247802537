.hidden {
  display: none;
}

.m-research-map__filter {
  @extend .m-network__filter;
}

.m-research-map__filter-inner {
  @extend .m-network__filter_inner;
  justify-content: flex-end;
}

.m-research-map__search {
  @extend .m-network__filter_search;
  width: calc(100% - 328px);
  max-width: 647px;
  @include bp('tablet') {
    max-width: 100%;
    width: 100%;
  }
}

.m-research-map__legend {
  @extend .m-network__filter_members-checkboxes;
  max-width: 300px;

  .type {
    @extend %networ__filter_type;
  }

  .event::before {
    background-color: $yellow;
  }

  .interest_point::before {
    background-color: $green;
  }

}

input[type=text].m-research-map__search__input {
  @extend %search_input;

  margin: 0 auto;
  font-size: 1em;
  box-sizing: border-box;

  border-radius: 2em;
  height: auto;
  width: 100%;
  padding: .6em 3em .5em 1em;
  border: 2px solid #f5f5f5;

  .icon::before {
    top: 7px !important;
  }

  &:focus {
    width: 100%;
    border: 2px solid #1E9FCF;
    outline: none;
  }
}

.m-research-map__search__result-counter {
  position: absolute;
  background-color: #1E9FCF;
  color: #fff;
  border-radius: 50%;
  top: 5px;
  left: 5px;
  bottom: 5px;
  max-height: 3em;
  width: 3.3em;
  text-align: center;
  padding-top: .5em;
  font-size: .7em;
  font-weight: bold;
}

.m-research-map__list_heading {
  text-align: center;
  overflow: hidden;

  h1 {
    @extend %node_title;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 36px;
    padding: 0 70px 25px;
    border-bottom: 3px $blue-light solid;

    @include bp('mobile') {
      margin-bottom: 19px;
      padding: 0 0 16px 0;
    }
  }

  @include bp('mobile') {
    text-align: left;
  }
}

.m-research-map__list_teaser {
  margin: 0 auto 60px;
  width: 60%;
  text-align: center;

  p {
    margin: 0 0 1em;
    font-size: px2em(19);

    &:last-child {
      margin: 0;
    }
  }
}

.m-research-map__list {
  @extend %network_list;
}

.m-research-map__list__item {
  @extend .m-member__row;
  //list-style: none;
  //margin: 0;
  //padding: 0;
  //border-bottom: 1px solid #f5f5f5;
  //
  //width: 100%;
  //overflow: hidden;
  //padding: 24px 0;
  //display: flex;
  //align-items: center;
  //flex-wrap: wrap;
  //flex-direction: row;
  //
  //&:not(:last-child) {
  //  border-bottom: 1px #CECFD2 solid;
  //}
  //
  //a {
  //  display: block;
  //
  //  &:focus {
  //    outline: none;
  //  }
  //
  //  &:hover {
  //    cursor: pointer;
  //    color: #1E9FCF;
  //  }
  //}
  //
  //&:hover {
  //  background: rgba(29, 158, 205, 0.1);
  //}
  //
  //&.event {
  //  .m-research-map__list__item__type {
  //    background-color: $yellow;
  //  }
  //}
  //
  //&.interest_point {
  //  .m-research-map__list__item__type {
  //    background-color: $green;
  //  }
  //}
}

.m-research-map__list__item__title {
  display: flex;
  flex-basis: 52%;
  flex-grow: 4 !important;
  @include bp('tablet') {
    flex-grow: 2 !important;
  }
  a {
    font-weight: bold;
  }
}

.m-research-map__list__item__tags {
  flex-basis: 25%;

  a {
    padding: 0;
    display: inline-block;
    margin-right: 10px;
  }
}

.m-research-map__list__item__location {
  display: flex;
  flex-basis: 23%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.m-research-map__list__item__type {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 20px;
}

.m-research-map__list__item__city {
  a {
    padding: 0;
  }
}

.m-research-map__list__no-results {
  display: inline-block;
  margin-left: 1em;

  &.hidden {
    display: none;
  }
}
