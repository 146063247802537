.m-node--event--toppicks {
  position: relative;
}

.m-node--event--toppicks__type {
  @extend %node_icon_small;
  position: relative;
  top: auto;
  margin-bottom: 26px;

  a, span {
    @include ci('event');
  }

  @include bp('mobile') {
    margin-left: $mobile_diff;
  }
}

.m-node--event--toppicks__title {
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 0;

  a {
    @extend %node_title;
    text-align: left;
    margin-top: 0;
    font-size: px2em(19);
    font-weight: $bold;
    line-height: px2em(27, 19);
    margin-bottom: 0;

    @include bp('mobile') {
      font-size: px2em(16);
      line-height: px2em(21, 16);
    }

    &:hover {
      color: $blue-light;
    }
  }
}

.m-node--event--toppicks__date {
  @extend %node_date;
  text-align: left;
  margin-bottom: 9px;

  @include bp('mobile') {
    font-size: px2em(13);
  }

  > div {
    display: inline-block;
  }
}
