.m-twitter_sharelines {
  border-bottom: 1px solid $blue-light;
  list-style: none;
  padding: 0;
  margin: 2.5em 0 2.5em 0;
}

.m-twitter_sharelines__line {
  border-top: 1px solid $blue-light;
  font-weight: $semi-bold;
  padding: 0;
  display: table;
}

.m-twitter_sharelines__line_button {
  display: table-cell;
  height: 100%;
  width: 6%;
  vertical-align: middle;
}

.m-twitter_sharelines__line_text {
  padding: 1.4em 0;
  display: table-cell;
  height: 100%;
  width: 94%;
}
