.m-node--dossier {
  @extend %node;
  background-color: $white;
  margin-top: -494px;

  @include bp('tablet') {
    margin-top: -320px;
    display: inline-block;
  }

  @include bp('mobile') {
    margin-top: 0;
    display: block;
  }

  @include bp('print') {
    all: unset !important;
  }
}

.m-node--dossier__type {
  @extend %node_icon;

  @include bp('mobile') {
    left: 0;
  }

  a::before,
  span::before {
    content: $icon-dossiers;
  }
}

.m-node--dossier__printbar {
  @extend %node_printbar;

  @include bp('tablet') {
    top: 30px;
  }
}

.m-node--dossier__socialshare {
  @extend %node_socialshare;
  @extend %page_center_field_small;

  @include bp('tablet') {
    width: 75%;
  }

  @include bp('mobile') {
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 18px;
    text-align: left;
  }

  .m-socialmedia-share {
    @include bp('tablet') {
      width: 100%;
      display: inline-block;
    }

    @include bp('mobile') {
      text-align: left;
    }

    &:after {
      clear: none;
    }
  }
}

.m-node--dossier__title {
  @extend %node_title;
  @extend %page_center_field;
}

.m-node--dossier__teaser {
  @extend %node_teaser;
  @extend %page_center_field;
}

.m-node--dossier__subtitle {
  @extend %node_subtitle;
  @extend %page_center_field;
}

.m-node--dossier__date-authors {
  margin-bottom: 35px;

  @include bp('mobile') {
    margin-bottom: 18px;
  }
}

.m-node--dossier__date {
  @extend %node_date;
  @extend %page_center_field;
  margin-bottom: 5px;

  @include bp('mobile') {
    font-size: px2em(12);
    line-height: px2em(17, 12);
  }
}

.m-node--dossier__authors {
  @extend %page_center_field;
  text-align: center;

  @include bp('mobile') {
    font-size: px2em(12);
    line-height: px2em(17, 12);
    text-align: left;
  }

  @include bp('print') {
    text-align: left;
  }
}

.m-node--dossier__authors_inner {
  margin: 0 auto;
}

.m-node--dossier__authors_author {
  @extend %node_oldstyle_authors;

  &:not(:last-child) {
    &:after {
      content: ',';
      padding-right: 4px;
      color: $blue-light;
    }
  }
}

.m-node--dossier__location {
  @extend %page_center_field;
  text-align: center;
  margin-top: 2px;

  @include bp('mobile') {
    font-size: px2em(12);
    line-height: px2em(17, 12);
    text-align: left;
    margin-top: 5px;
  }

  @include bp('print') {
    text-align: left;
  }
}

.m-node--dossier__cc {
  @extend %page_center_field;
}

.m-node--dossier__tags {
  @extend %node_tags;
  margin-bottom: 30px;

  @include bp('mobile') {
    margin-bottom: 17px;
  }

  a {
    color: $text2;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $blue-light;
    }
}
}

.m-node--dossier__ctypes {
  @include node_ctypes {
    &.m-paragraph--quote {
      @extend %page_full_field;
    }

    &.m-paragraph--morebox {
      @extend %page_full_field;
    }
  }

  @include bp('mobile') {
    font-size: px2em(14);
    line-height: px2em(21, 14);
  }
}

.m-node--dossier__expertfacts {
  margin-bottom: 56px;

  @include bp('mobile') {
    margin-bottom: 23px;
  }

  @include bp('print') {
    display: none;
    margin-bottom: 33px;
  }
}

.m-node--dossier__block1 {
  margin-top: 66px;
  padding-top: 66px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: $blue-light;
    position: absolute;
    top: 0;
  }

  @include bp('print') {
    display: none;
  }
}

.m-node--dossier__block1__title {
  @extend %ccasetitled;
  text-align: center;
  margin-bottom: 50px;

  @include bp('mobile') {
    text-align: left;
    font-size: px2em(19);
    line-height: px2em(26, 19);
  }
}

.m-node--dossier__block1__list {
  @extend %grid3;

  > .m-node--dossier__block1__trigger + .m-node--dossier__block1__trigger {
    &:before {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -30px;
      background-color: $grey3;

      @include bp('tablet') {
        display: none;
      }
    }
  }
}

.m-node--dossier__block1__trigger {
  @extend %grid3__trigger;
  position: relative;

  @include bp('tablet') {
    -ms-flex-wrap: wrap;
    width: 100%;
    align-self: left;
    border-top: 0;
    padding-top: 21px;
    position: relative;
    margin-top: 16px;
    margin-bottom: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 76px;
      height: 1px;
      background-color: $grey3;
    }
    &:nth-child(1) {
      border-top: 0;
      padding-top: 0;
      &::after {
        display: none;
      }
    }
    &:nth-child(2) {
      padding-top: 24px;
    }
  }

  @include bp('mobile') {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}

.m-node--dossier__bgimage_title {
  display: inline-block;

  font-size: px2em(12);
  color: $white;
  text-shadow: 0 1px 2px $black;

  transform: rotate(-90deg);
  transform-origin: top left;
  float: left;
  padding-left: 10px;

  a {
    @include image_caption_link();
  }

  @include bp('mobile') {
    position: relative;
    transform: none;
    float: right;
    padding-right: 10px;
    margin-top: -25px; // TODO --- how to? not fixed
  }
}
