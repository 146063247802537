@mixin image_caption_link {
  font-size: inherit;
  line-height: inherit;
  color: $blue-light;
}

@mixin teaser_image_title {
  @include font1();
  font-size: px2em(15);
  line-height: px2em(21, 15);
  color: $text1;
  font-weight: $regular;
  margin-bottom: 35px;
  a {
    @include image_caption_link();
  }
}

.m-global__image_title_inject {
  @include teaser_image_title;
}

.m-global__image--portrait {
  display: inline-block;
  margin: 0 20%;

  @include bp('mobile') {
    margin: 0;
  }
}
