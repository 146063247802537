$height-icon: 18px;
$width-line: 30px;
$height-line: 3px;

$transition-time: 0.3s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;

$color: #fff;

.m-mainmenu__container {
  position: static;

  .menu_icon {
    display: none;

    @include bp('mobile') {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.m-mainmenu__search {
  display: none;

  @include bp('mobile') {
    display: block;
    position: absolute;
    right: 85px;
    top: 29px;
    width: 33px;
    transition: all 0.2s ease-in-out;
  }

  .shrink & {

    @include bp('mobile') {
      top: 21px;
    }
  }

  a {
    @include icon($icon-search);

    display: block;
    width: 24px;
    height: 30px;
    text-indent: -9999px;

    @include bp('mobile') {
      width: 33px;
      height: 33px;
    }

    &::before {
      color: $grey4;
      font-size: 24px;

      @include bp('mobile') {
        color: $blue-dark;
        font-size: 30px;
        line-height: 1em;
      }
    }
  }
}

.menu_icon--hamburger {
  display: block;
  position: relative;
  width: 42px;
  height: 27px;
  background-image: url('../../img/menu.svg');
  background-repeat: no-repeat;
  background-size: auto 27px;

  &:before,
  &:after {
    content: "";
    display: none;
    background: $blue-dark;
    width: 38px;
    height: 3px;
    position: absolute;
    top: 21px;
    left: 1px;
    border-radius: 1.5px;
    transform: translateY(-9px) translateX(0) rotate(-45deg);
  }

  &:after {
    content: "";
    top: 3px;
    transform: translateY(9px) translateX(0) rotate(45deg);
  }

  &.active {
    background-image: none;

    &:before,
    &:after {
      display: block;
    }
  }

  // width: $width-line;
  // height: $height-icon;

  // &:hover, &:focus {
  //   .line-1 {
  //     transform: translateY(2px);
  //     -webkit-transform: translateY(2px);
  //     -moz-transform: translateY(2px);
  //   }
  //   .line-3 {
  //     transform: translateY(-2px);
  //     -webkit-transform: translateY(-2px);
  //     -moz-transform: translateY(-2px);
  //   }
  // }
  // &.active {
  //   .line-1 {
  //     transform: translateY($translateY) translateX($translateX) rotate($rotation);
  //     -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
  //     -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
  //   }
  //   .line-2 {
  //     opacity: 0;
  //   }
  //   .line-3 {
  //     transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
  //     -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
  //     -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
  //   }
  // }
}

// .menu_icon--hamburger--line {
//   display: block;
//   background: $color;
//   width: $width-line;
//   height: $height-line;
//   position: absolute;
//   left: 0;
//   border-radius: ($height-line / 2);
//   transition: all $transition-time;
//   -webkit-transition: all $transition-time;
//   -moz-transition: all $transition-time;

//   &.line-1 {
//     top: 0%;
//   }
//   &.line-2 {
//     top: 50%;
//   }
//   &.line-3 {
//     top: 100%;
//   }
// }

.m-mainmenu__inner {
  @extend %transitionAll;

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  margin-top: -8px;

  @include bp('tablet') {
    margin-top: -20px;
  }

  .shrink & {
    margin-top: -12px;

    @include bp('tablet') {
      margin-top: -14px;
    }
  }

  @include bp('mobile') {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: $header-height;
    background-color: $blue-light;
    z-index: 11;
    padding: 63px 0 0;
    top: 89px;
    margin-top: 0;
    flex-direction: column;
  }

  @include bp('print') {
    display: none;
  }

  > .innerWrapper {
    @include bp('desktop') {
      width: auto;
      margin: 0;
    }

    @include bp('smalldesktop') {
      width: auto;
      margin: 0;
    }

    @include bp('tablet') {
      width: auto;
      margin: 0;
    }

    @include bp('mobile') {
      width: $mobile_width;
      margin: 0 auto;
    }
  }

  > div > ul {
    @extend %ul;
    @extend %mainnavgrid;

    > li {
      @extend %list_item;
      @extend %mainnavgrid__trigger;
    }

    > li + li {
      @include bp('mobile') {
        border-top: 1px solid $blue-dark;
      }
    }
  }

  @include bp('tablet') {
    padding: 0;
    // top: $mobile-header-height;
    position: relative;
  }

  @include bp('mobile') {
    // padding: 0;
    top: $mobile-header-height;
  }
}

//STATES
.m-mainmenu__container {
  &.#{$isactive} {
    .m-mainmenu__inner {
      @include bp('mobile') {
        display: block;
        position: absolute;
        top: $mobile-header-height;
        padding-top: 10px;
      }
    }
  }
}

.m-mainmenu__element {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @include bp('mobile') {
    justify-content: flex-start;
    flex-direction: column;
  }
}
