.region-overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;

  @include bp('print') {
    display: none;
  }
}

.ask-clew-overlay__wrapper {
  position: fixed;
  right: 65px;
  bottom: 65px;
  z-index: 1000;
  display: none;

  h2 {
    color: $white;
  }
}

.ask-clew-overlay {
  background-color: $green-medium;
  color: $white;
  width: 450px;
  z-index: 1000;
  position: relative;
  opacity: 0;

  &.bounceIn.go {
    opacity: 1;
  }

  h2 {
    background-color: $green-dark;
    padding: 17px 40px 16px 67px;
    font-size: 1.7em;
    line-height: 1em;
    font-weight: 100;
    margin: 0;
    background-image: url(/sites/all/modules/_publicgarden/clew_grid/img/info-white.png);
    background-size: auto 53%;
    background-position: 40px 12px;
    background-repeat: no-repeat;
  }

  a {
    color: $white;
  }

  .content {
    padding: 25px 40px 20px 40px;
    font-size: 1.23em;
    line-height: 1.5em;
    clear: both;
    overflow: hidden;
    font-weight: 100;

    .ask-clew-overlay__close {
      width: 25px;
      height: 25px;
      background-image: url(../../img/big-close.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 24px;
      position: absolute;
      top: 15px;
      right: 40px;
      cursor: pointer;
    }

    .ask-clew-overlay__portrait {
      float: left;
      width: 28%;

      img {
        width: 80px;
        height: auto;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
      }
    }

    .ask-clew-overlay__content {
      float: left;
      width: 72%;

      a {
        font-weight: 600;
        display: inline-block;
      }

      p {
        margin: 0;
        line-height: 1.5em;
      }

      .ask-clew-overlay__mail {
        margin-top: 1.3em;
      }

      .ask-clew-overlay__phone {
        font-size: 1.38em;
        margin-top: 0.35em;
      }
    }
  }
}

@media (max-width: 768px) {
  .ask-clew-overlay__wrapper {
    display: none !important;
  }
}

@media (max-width: 1400px) {
  .ask-clew-overlay {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .ask-clew-overlay__wrapper {
    right: 45px;
    bottom: 45px;
  }
  .ask-clew-overlay {
    font-size: 11px;
    width: 400px;

    h2 {
      padding-left: 47px;
      padding-right: 20px;
      background-position: 20px 12px;
    }
    .content {
      padding: 20px 25px 20px 25px;

      .ask-clew-overlay__close {
        right: 20px;
      }
    }
  }
}

