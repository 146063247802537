.m-sidebars--news {
  position: relative;
  padding-top: 70px;
  margin-bottom: 60px;
}

.m-sidebars--news__title {
  @extend %teaserlistgrid__type;
  background-color: $grey2;
  font-size: 1em;
  padding-left: 65px;
  top: 0;

  &:before {
    top: 4px;
    left: 27px;
    content: $icon-news;
    color: $blue-light;
    font-size: 33px;

    @include bp('tablet') {
      top: 2px;
      font-size: 26px;
    }
  }

  &:after {
    background-color: $grey2;
  }

  span {
    font-size: px2em(15, 16);
    color: $text2;
  }
}

.m-sidebars--news__content {
  font-size: px2em(14);
  line-height: px2em(19, 14);
  padding: 0 0 0 7%;

  @include bp('tablet') {
    padding-left: 0;
  }
}

.m-sidebars--news__news-digests {
  @extend %sidebars-list__block;
}

.m-sidebars--news__news-digests__title {
  @extend %sidebars-list__title;
  &:before {
    content: $icon-news;
  }
}

.m-sidebars--news__news-digests__content {
  @extend %sidebars-list__content;
}

.m-sidebars--news__news-digests .view-content,
.m-sidebars--news__related-news--list {
  @extend %sidebars_item_list;
}

.m-sidebars--news__news-digests--item,
.m-sidebars--news__related-news--item {
  @extend %sidebars_item_list_item;
}

.m-sidebars--news__news-digests--item h3,
.m-sidebars--news__related-news--title {
  @extend %sidebars_item_list_title;
}
