.m-loadmore {
  //@include icon('\EA04');
  position: absolute;
  right: 0;
  top: 0;
  width: 27px;
  height: 100%;

  &:after {
    width: 5px;
    height: 100%;
    content: '';
    display: block;
    background-color: $grey2;
  }

  @include bp('mobile') {
    width: 100%;
    position: static;
    right: auto;
    top: auto;
    margin-top: 14px;
    text-align: center;

    &:before {
      //display: none;
    }
  }
}

%linkitem {
  a {
    @include icon('\EA03');
    color: rgba($grey3, 0.5);
    @include font1();
    font-size: px2em(19);
    font-weight: $bold;
    line-height: px2em(26, 19);
    text-transform: uppercase;
    white-space: nowrap;
    background-color: $white;
    display: block;
    padding: 0 10px 0 10px;
    cursor: default;

    &:before {
      color: rgba($grey3, 0.5);
      top: 2px;
      left: 0;
      font-size: 14px;
    }
  }

  &.#{$isactive} {
    a {
      cursor: pointer;
      color: rgba($blue-light, 0.38);

      &:before {
        color: rgba($blue-light, 0.38);
      }

      &:hover {
        color: $blue-light;

        &:before {
          color: $blue-light;
        }
      }
    }
  }

  @include bp('mobile') {
    display: inline-block;
    transform: rotate(0deg);
    position: relative;
    top: auto;
    right: auto;

    &:before {
      transform: rotate(0deg);
      top: auto;
      bottom: -14px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.m-loadmore__prev {
  transform: rotate(-90deg);
  position: absolute;
  top: 48px;
  right: -145%;

  @extend %linkitem;

  a {
    padding: 0 20px 0 10px;

    &:before {
      transform: rotate(-90deg);
      top: 3px;
      bottom: auto;
      left: auto;
      right: 0;
    }
  }

  @include bp('mobile') {
    top: -41px;
    right: 0;
    transform: rotate(0deg);
  }
}

.m-loadmore__next {
  transform: rotate(-90deg);
  position: absolute;
  bottom: 25px;
  right: -60%;

  @extend %linkitem;

  a {
    padding: 0 10px 0 20px;

    &:before {
      transform: rotate(90deg);
    }
  }

  @include bp('mobile') {
    transform: rotate(0deg);
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    text-align: center;
    padding: 0;
    z-index: 2;
    width: 100%;
    background-color: $white;

    a {
      padding: 0 10px;
      display: inline-block;

      &:before {
        transform: rotate(0deg);
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: 20px;
      }
    }

    &:before {
      content: '';
      display: block;
      height: 5px;
      width: 100%;
      position: absolute;
      top: 10px;
      z-index: -1;
      background-color: $grey2;
    }
  }
}
