@import "_m-node_presets";

.m-node {
  @extend %node;
}

.m-node__title {
  @extend %node_title;
}

.unpublished--title {
  color: red !important;
}

@import "_m-node--dossier";
@import "_m-node--dossier--topnews";
@import "_m-node--dossier--toppicks";
@import "_m-node--dossier--teaserbox";
@import "_m-content--network";
@import "_m-node--frontpage";
@import "_m-node--factsheet--topnews";
@import "_m-node--factsheet--toppicks";
@import "_m-node--factsheet--teaserbox";
@import "_m-node--factsheet--full";
@import "_m-node--landingpage";
@import "_m-node--blogpost--teaser";
@import "_m-node--blogpost--teasersmall";
@import "_m-node--blogpost--teaserbox";
@import "_m-node--blogpost--network-latest";
@import "_m-node--blogpost--toppicks";
@import "_m-node--blogpost";
@import "_m-node--list";
@import "_m-node--page";
@import "_m-node--page--teaserbox";
@import "_m-node--page--network-latest";
@import "_m-node--article--full";
@import "_m-node--article--toppicks";
@import "_m-node--article--teaserbox";
@import "_m-node--member--full";
@import "_m-node--event";
@import "_m-node--event--teaserbox";
@import "_m-node--event--toppicks";
@import "_m-node--event--network-latest";
@import "_m-node--specialpage";
@import "_m-node--expert--full";
@import "_m-node--interestpoint--full";
@import "_m-page--system";
@import "_m-node--webform";
@import "_m-content--digest-entry--full";
@import "_m-node--list--experts";
@import "_m-node--landingpageguide";
