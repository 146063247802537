.m-paragraph--toolbox {
  margin-top: 75px !important;

  @include bp('tablet') {
    margin-top: 35px !important;
  }
}

.m-paragraph--toolbox__row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 20px;
}

.m-paragraph--toolbox__item {
  width: calc(25% - 1px);
  border-left: 1px solid $blue-dark;

  @include bp('tablet') {
    width: calc(50% - 1px);
  }

  &:first-child {
    width: 25%;
    border-left: 0;

    @include bp('tablet') {
      width: 50%;
    }
  }

  &:nth-child(3) {
    @include bp('tablet') {
      border-left: 0;
      width: 50%;
    }
  }
}

.m-paragraph--toolbox__link {
  display: block;
  text-align: center;
  padding: 17px 24px 27px;

  &:hover {
    .m-paragraph--toolbox__icon {
      &:before {
        color: $blue-light;
      }
    }

    .m-paragraph--toolbox__title {
      color: $blue-light;
    }
  }
}

.m-paragraph--toolbox__icon {
  @include icon($icon-experts);
  @extend %data_icons;

  width: 72px;
  height: 72px;
  margin: 0 auto;

  &:before {
    color: $blue-dark;
    font-size: 72px;
    line-height: 100%;
    transition: all 0.3s ease-in-out;
  }
}

.m-paragraph--toolbox__title {
  font-weight: $bold;
  line-height: px2em(31, 20);
  letter-spacing: normal;
  color: $blue-dark;
  margin: 9px 0 0 0;
  transition: all 0.3s ease-in-out;
}
