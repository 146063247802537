.m-event-latest {

}

.m-event-latest__meta {
  font-size: px2em(15);
  line-height: 1.55;
}

.m-event-latest__type {
  color: $blue-light;
}

.m-event-latest__date {

  div {
    display: inline;
  }
}

.m-event-latest__title {
  margin: 7px 0 0 0;

  a {
    @include font2();
    @include icon($icon-arrow-with-line);

    display: inline-block;
    font-size: px2em(16, 20);
    line-height: 1.55em;
    font-weight: $bold;
    letter-spacing: normal;
    transition: all 0.2s ease-in-out;
    position: relative;
    padding-bottom: 40px;

    &:before {
      color: $black;
      font-size: 24px;
      top: auto;
      bottom: 0;
      line-height: 1em;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      color: $blue-light;

      &:before {
        color: $blue-light;
        left: 20px;
      }
    }
  }
}
