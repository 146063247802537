%masonrygrid3 {
	width: 100%;
}

%masonrygrid3__trigger {
	margin-bottom: 50px;
	width: 30.5%;

	@include bp('tablet') {
		width: 100%;
		align-self: left;
		margin-bottom: 25px;

		&:last-of-type {
			margin-left: 0;
			margin-bottom: 50px;
		}
	}

	@include bp('mobile') {
		width: 100%;
		align-self: left;
		margin-bottom: 25px;

		&:last-of-type {
			margin-left: 0;
			margin-bottom: 50px;
		}
	}
}

%masonrygrid3__gutter {
	width: 4%;

	@include bp('tablet') {
		width: 4%;
	}

	@include bp('mobile') {
		width: 0;
	}
}