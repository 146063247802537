.field-type-text-long {
  img {
    display: block;
  }

  img.image-left {
    display: block;
    float: left;
    margin: .4em 1.5em .4em 0;
  }

  img.image-right {
    display: block;
    float: right;
    margin: .4em 0 .4em 1.5em ;
  }

  img.image-medium {
    width: 33%;
    height: auto;
  }

  img.image-large {
    max-width: 100%;
    height: auto;
    clear: both;
    overflow: hidden;
  }
}
