$ctype_icons: (
  dossier: $icon-dossiers,
  factsheet: $icon-factsheet,
  article: $icon-news,
  interview: $icon-interview,
  blogpost: $icon-blog,
  expert: $icon-experts,
  event: $icon-calendar,
);

@mixin ci($type) {
  @if map-has-key($ctype_icons, $type) {
    &:before {
      content: map-get($ctype_icons, $type);
    }
  }
}
