%grid2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: stretch;
  //align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  -ms-flex-pack: justify;
  justify-content: space-between;
  place-content: flex-end space-between;
  align-items: stretch;

  @include bp('tablet') {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  @include bp('mobile') {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

%grid2__trigger {
  width: 48%;
  flex-shrink: 1;

  @include bp('tablet') {
    width: 100%;
  }

  @include bp('mobile') {
    width: 100%;
  }
}

.l-grid--2 {
  @extend %grid2;
}

.l-grid--2__trigger {
  @extend %grid2__trigger;
}
