.l-page--dossier {
  @extend %page;

  .admin-menu & {
    // padding-top: calc(#{$header-height} + 28px);
  }

  @include bp('print') {
    padding-top: 30px;
  }
}

.l-page--dossier__bgimage {
  position: relative;
  height: 802px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include bp('tablet') {
    height: 500px;
  }

  @include bp('mobile') {
    height: 180px;
  }

  @include bp('print') {
    display: none;
  }
}
