body.page-research-map {
  overflow-y: scroll;
}

.l-page--events {
  padding-top: 180px;

  @include bp('mobile') {
    padding-top: calc(#{$mobile-header-height} + 36px);
  }

  .region-content {
    position: relative;
    margin-top: -120px;
    background: #fff;
    z-index: 5;
  }
}

.m-events__map {
  position: relative;
}

#research-map {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  clear: both;

  @include bp('mobile') {
    height: 350px;
  }

  canvas {
    &:focus {
      outline: none;
    }
  }

  .mapboxgl-ctrl-top-left {
    @include bp('desktop') {
      top: auto;
      bottom: 103px;
      left: 50%;
      margin-left: calc( (#{$page_width} / 2 + 50px) * -1);

      .mapboxgl-ctrl {
        float: right;
      }
    }

    @include bp('tablet') {
      top: 13px;
    }

    @include bp('mobile') {
      top: 23px;
    }
  }

  .marker {
    background-color: #1E9FCF;
    border: 2px solid darken(#1E9FCF, 5);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .3s ease-in-out, width .3s ease-in-out, height .3s ease-in-out, border .3s ease-in-out;

    &.ambassador,
    &.interest_point {
      background-color: #0c5f7e;
      border-color: darken(#0c5f7e, 5);
    }

    &.active {
      width: 10px;
      height: 10px;
      box-shadow: none;
      background-color: transparent;

      &.ambassador,
      &.interest_point {
        border-color: #0c5f7e;
      }
    }

    &:hover {
      width: 30px;
      height: 30px;
    }
  }

  .mapboxgl-popup {
    max-width: 250px;
  }

  .mapboxgl-popup-content {
    text-align: left;
    padding: 15px 20px;
    line-height: 1.3em;

    .mute {
      color: #888;
    }

    .title {
      font-weight: bold;
      font-size: 1.2em;
    }
  }
}

#research-list {
  @extend .m-content--network;
}

.m-research-map__no-results {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 700px;
  background: rgba($white, .3);
}

.m-research-map__no-results__text {
  position: absolute;
  left: 50%;
  top: 45%;
  width: 200px;
  margin-left: -100px;
  text-align: center;
}

.mapbox-improve-map {
  display: none;
}

@import "_m-research-map--list";
@import "_m-research-map--panel";
