.m-menuitem__title {
  position: relative;

  a {
    color: $text1;
    font-size: 1.2em;
    line-height: 1.55;
    font-weight: $bold;

    @include bp('tablet') {
      font-size: px2em(16);
    }

    @include bp('mobile') {
      font-size: px2em(15);
    }
  }

  @include bp('mobile') {
    @include icon('\EA03');

    &:before {
      right: 0;
      left: auto;
      top: 50%;
      font-size: 1.3em;
      line-height: 100%;
      margin-top: -.5em;
      color: $blue-dark;
    }
  }

  &[data-icon] {
    a {
      @include icon('\EA01');
      color: $text1;
      display: block;
      padding: 0;
      transition: all 0.2s ease-in-out;

      @include bp('mobile') {
        padding: 1.5em 3em 1.5em 0;
      }

      &:before {
        display: none;
      }

      // &:before {
      //   display: none;
      //   top: 50%;
      //   margin-top: -0.5em;
      //   width: 1em;
      //   height: 100%;
      //   line-height: 100%;
      //   font-size: 2.5em;
      //   color: $blue-dark;

      //   @include bp('mobile') {
      //     display: block;
      //   }
      // }

      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: -12px;
        width: 100%;
        height: 4px;
        background-color: $blue-light;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease-in-out;

        @include bp('tablet') {
          bottom: -6px;
        }

        .shrink & {
          bottom: -6px;
        }
      }

      &:hover {
        color: $blue-light;

        &:after {
          @include bp('smalldesktop') {
            visibility: visible;
            opacity: 1;
          }

          @include bp('desktop') {
            visibility: visible;
            opacity: 1;
          }

          @include bp('tablet') {
            visibility: visible;
            opacity: 1;
          }

          @include bp('mobile') {
            visibility: hidden;
            opacity: 0;
          }
        }

        @include bp('mobile') {
          color: $blue-dark;
        }
      }

      &.active-trail,
      &.active {
        color: $blue-light;

        @include bp('mobile') {
          color: $blue-dark;
        }

        &:after {
          // @include bp('desktop') {
            visibility: visible;
            opacity: 1;
          // }
        }
      }
    }

    @extend %data_icons_with_link;
  }
}

// .m-menuitem__teaser {
//   color: $white;
//   font-size: px2em(15);
//   line-height: px2em(20, 15);
// }

.m-menuitem__subnavi {
  display: none;
  margin-top: 0;
  position: absolute;
  left: -56px;
  top: 100%;
  padding: 40px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  border-radius: 0 0 20px 20px;
  background-color: $white;
  min-width: 165px;

  @include bp('mobile') {
    display: block;
    // margin-top: 14px;
    position: relative;
    left: auto;
    top: auto;
    background-color: transparent;
    padding: 0;
    min-width: auto;
    box-shadow: none;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    left: -15px;
    right: -15px;
    height: 15px;
    top: -15px;
    background-color: $white;

    @include bp('mobile') {
      display: none;
    }
  }

  ul {
    @extend %ul;

    display: flex;
    flex-direction: column;
    row-gap: 25px;

    @include bp('mobile') {
      row-gap: 0;
    }

    li {
      @extend %list_item;

      &.is-overview {
        display: none;

        @include bp('tablet') {
          display: block;
        }
      }
    }
  }

  a {
    @include icon($icon-arrow-with-line);

    display: block;
    color: $text1;
    font-size: px2em(18);
    line-height: 1.55;
    font-weight: $semi-bold;
    transition: all 0.3s ease-in-out;

    @include bp('mobile') {
      color: $white;
    }

    &:before {
      display: block;
      color: $blue-light;
      font-size: 19px;
      top: 4px;
      line-height: 1em;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-in-out;

      @include bp('mobile') {
        color: $white;
      }
    }
  }

  li.collapsed a,
  a.active,
  a:hover {
    color: $blue-light;
    padding-left: 29px;

    &:before {
      visibility: visible;
      opacity: 1;
    }

    @include bp('mobile') {
      color: $white;
    }
  }
}

.m-menuitem__inner {
  @include bp('mobile') {
    display: none;
    margin-bottom: 30px;

    a {
      padding-right: 3em;
    }
  }
}

//STATES
.m-menuitem {
  @include bp('mobile') {
    &.#{$isactive} {
      .m-menuitem__inner {
        display: block;
      }

      .m-menuitem__title {
        &:before {
          content: '\EA04';
        }
      }
    }
  }
}
