.m-searchresultitem {
  margin-bottom: 20px;
  margin-right: 10px;

  @include bp('mobile') {
    &:nth-child(n+5) {
      display: none;

      &.#{$isactive} {
        display: block;
      }
    }
  }
}

.m-searchresultitem__meta {
  margin-bottom: 0;
}

.m-searchresultitem__date {
  font-size: px2em(12);
  line-height: px2em(17, 12);
  display: inline-block;
}

.m-searchresultitem__tags {
  display: inline-block;

  a {
    font-size: px2em(12);
    line-height: px2em(17, 12);
    display: inline-block;

    &:before {
      content: '#';
    }
  }

  a + a {
    margin-left: 6px;
  }
}

.m-searchresultitem__title {
  @include font1();
  font-size: px2em(19);
  line-height: px2em(26, 19);
  margin-top: 0;
  color: $grey1;
}

.m-searchresultitem__snippet {
  display: none;
}

.m-searchresultitem + .m-searchresultitem {
  padding-top: 20px;
  border-top: 1px solid $grey3;
}
