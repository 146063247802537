@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("embedded-opentype"),
  url("../fonts/icons.woff") format("woff"),
  url("../fonts/icons.ttf") format("truetype"),
  url("../fonts/icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Light'), local('Merriweather-Light'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Regular'), local('Merriweather-Regular'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-300italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 300;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-300italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Italic'), local('Merriweather-Italic'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Bold'), local('Merriweather-Bold'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-700.svg#Merriweather') format('svg'), /* Legacy iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/merriweather/merriweather-v19-latin-700.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/sites/all/themes/cleanenergywire/assets/fonts/open-sans/open-sans-v15-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@mixin font1 {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

@mixin font2 {
  font-family: 'Merriweather', Georgia, 'Times New Roman', serif;
}

@mixin font_size_line_height($font-size, $line-height) {
  font-size: px2em($font-size);
  line-height: px2em($line-height, $font-size);
}
