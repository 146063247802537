$white: #FFFFFF;
$black: #000000;

$blue-light: #1E9FCF;
$blue-medium: #007FA8;
$blue-dark: #122F4E;

$green-medium: #009870;
$green-dark: #006b4f;

$blue-light-hover: $blue-medium;
$blue-maplist-hover: #E6F4FA;

$red-light: #A30C2F;
$red-dark: #67011C;

$yellow: #FAC600;

$grey1: #122F4E;
$grey2: #EEEFF2;
$grey3: #CECFD2;
$grey4: #9B9B9B;
$grey5: #D8D8D8;
$grey6: #EEEFF2;

$purple1: #422D6D;
$purple2: #2d0860;

$green: #009870;

$text1: #333333;
$text2: #555555;

$member_icon_color_member: $blue-light;
$member_icon_color_ambassador: $purple1;

$header-height: 152px;
$tablet-header-height: 110px;
$mobile-header-height: 92px;

$topic_height: 60px;
$tablet_topic_height: 48px;
$mobile_topic_height: 48px;

$bold: 700;
$regular: 400;
$light: 300;
$semi-bold: 600;

//####states####
$isactive: 'is-active';
$ishover: 'is-hover';
$isfocus: 'is-focus';
$isopend: 'is-opened';
$isclosed: 'is-closed';
$hasvalue: 'has-value';

$base_font: 16;
$base-line-height: 1.35%;

$page_width: 1240px;
$smalldesktop_width: 90%;
$tablet_width: 90%;
$tablet_diff: 5%;
$mobile_width: 87%;
$mobile_diff: 6.5%;

// Icons
$icon-infobox: "\EA0A";
$icon-interview: "\EA0B";
$icon-network: "\EA0D";
$icon-news: "\EA0E";
$icon-newsletter: "\EA0F";
$icon-facebook: "\EA1A";
$icon-twitter: "\EA1B";
$icon-search: "\EA1C";
$icon-quote: "\EA1D";
$icon-about: "\EA01";
$icon-analysis: "\EA02";
$icon-arrow-down: "\EA03";
$icon-arrow-up: "\EA04";
$icon-calendar: "\EA05";
$icon-close: "\EA06";
$icon-contact: "\EA07";
$icon-dossiers: "\EA08";
$icon-factsheet: "\EA09";
$icon-blog: "\EA10";
$icon-linkedin: "\EAB1";
$icon-print: "\EAB2";
$icon-mail: "\EAB3";
$icon-ask-clew: "\EAB4";
$icon-attachment: "\EAB5";
$icon-experts: "\EAB6";
$icon-import: "\EAB7";
$icon-easyguide: "\EAB8";
$icon-pin: "\EAB9";
$icon-bluesky: "\EBA1";
$icon-arrow-with-line: "\EBA2";

$facebook: #38598f;
$facebook_hover: $grey1;
$bluesky: #1185fe;
$bluesky_hover: $grey1;
$twitter: #000000;
$twitter_hover: $grey1;
$whatsup: #455a64;
$whatsup_hover: $grey1;
$linkedin: #0077B5;
$linkedin_hover: $grey1;

$bg_opacity: 0.8;
