::-moz-selection {
  background-color: rgba($blue-light, 0.99);
  color: $white;
  text-shadow: none;
}

::selection {
  background-color: rgba($blue-light, 0.99);
  color: $white;
  text-shadow: none;
}

html, body, .inner_items {
  /*overflow: auto;
	-webkit-overflow-scrolling: touch;*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html.menuisopen {
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  position: relative;
  padding: 0;
  margin: 0;

  &:after {
    content: 'desktop';
    display: none;
  }
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include bp('tabletmobile') {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: transparent;
  }
}

body {
  position: relative;

}

body, a, input, textarea {
  color: $text1;
  @include font1();
  font-size: #{$base_font}px;
  line-height: px2em(26, $base_font);
  font-weight: $regular;

  @include bp('tablet') {

  }

  @include bp('mobile') {
  }

  @include bp('print') {
    font-size: 14px;
    line-height: 20px;
  }
}

a {
  &.lexicon-term {
    color: $text1;
    border-bottom: 1px dotted $text1;

    &:hover {
      color: $text1;
    }
  }

  &:target {
    display: block;
    content: " ";
    margin-top: -135px;
    height: 135px;
    visibility: hidden;
    pointer-events: none;
  }
}

h1 {
  @include font2();
  color: $text1;
  font-weight: $light;
  font-size: px2em(43);
  line-height: px2em(48, 43);

  @include bp('mobile') {
    font-size: px2em(24);
    line-height: px2em(29, 24);
  }
}

@mixin h2_font {
  @include font2();
  color: $text1;
  font-weight: $light;
  font-size: px2em(30);
  line-height: px2em(37, 30);
  letter-spacing: 0.0022em;

  @include bp('mobile') {
    font-size: px2em(22);
    line-height: px2em(29, 22);
  }
}

h2 {
  @include h2_font;
}

h3 {
  @include font1();
  color: $text1;
  font-weight: $light;
  font-size: px2em(20);
  line-height: px2em(26, 20);
  letter-spacing: 0.005em;

  @include bp('mobile') {
    font-size: px2em(18);
    line-height: px2em(24, 18);
  }
}

h4 {
  @include font1();
  color: $text1;
  font-weight: $light;
  font-size: px2em(20);
  line-height: px2em(26, 20);
  letter-spacing: 0.005em;

  @include bp('mobile') {
    font-size: px2em(18);
    line-height: px2em(24, 18);
  }
}

strong, th {
  font-weight: 600;
}

.innerWrapper {
  width: $page_width;
  margin: 0 auto;

  @include bp('smalldesktop') {
    width: $smalldesktop_width;
    margin: 0 auto;
  }

  @include bp('tablet') {
    width: $tablet_width;
    margin: 0 auto;
  }

  @include bp('mobile') {
    width: $mobile_width;
    margin: 0 auto;
  }
}

.print {
  display: none;
}

.menu_hover_bg {
  position: fixed;
  left: 0;
  right: 0;
  top: $header_height;
  top: 0;
  bottom: 0;
  background-color: rgba($black, 0.4);
  z-index: 2;
  display: none;

  .toolbar-tray-open & {
    top: calc(#{$header_height} + 85px);
  }

  &.#{$isopend} {
    display: block;
  }

  @include bp('mobile') {
    display: none;
    top: 78px;

    &.#{$isopend} {
      display: block;
    }
  }
}

#page {
  > .innerWrapper {
    position: relative;
  }
}
