$color: $white;

%slidegrid3 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items: stretch;

	@include bp('tablet') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

%slidegrid3__trigger {
	width: 30.5%;

	@include bp('mobile') {
		width: 100%;
	}
}

.l-slidegrid--3__content {

}

.l_slidegrid__3__pglightslider {
	position: relative;
	margin-bottom: px2em(75);
	height: 100%;

	.lSSlideWrapper {
		height: 100%;
	}

	@include bp('tablet') {
		margin-bottom: 45px;
	}

	@include bp('mobile') {
		margin-bottom: 12px;
	}
}

%controllitem {
	@include icon('\EA13');
	width: 32px;
	display: inline-block;
	top: calc(50% - 16px);
	top: 244px;
	height: 32px;
	cursor: pointer;
	position: absolute;
	z-index: 10;
	margin-top: -16px;
	-webkit-transition: opacity 0.35s linear 0s;
	transition: opacity 0.35s linear 0s;
	text-indent: -9999px;

	&:before {
		color: $color;
		display: block;
	}
}

.l_slidegrid__3__pglightslider__next {
	@extend %controllitem;
	right: -66px;
}

.l_slidegrid__3__pglightslider__prev {
	@extend %controllitem;
	left: -66px;

	&:before {
		content: '\EA18';
	}
}
