$inner_head_width: 84%;

.l-page--glossary {
  > .innerWrapper {
    margin-top: -128px;
    background-color: $white;

    @include bp('tablet') {
      padding-top: 0;
    }

    @include bp('mobile') {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .region.region-content {

  }

  .view-lexicon {
    ul {
      @extend %ul;
    }

    .views-row {
      @extend %list_item;
      margin: 0 26px;

      a {
        padding: 5px 0;
        display: block;

        &:hover {
          color: $blue-light;
        }
      }

      & + .views-row {
        border-top: 1px solid $grey3;
      }
    }
  }
}

.l-page--glossary__bgimage {
  background-color: $blue-dark;
  height: 360px;
  margin-top: $header-height;

  @include bp('tablet') {
    margin-top: $tablet-header-height;
    height: 320px;
  }

  @include bp('mobile') {
    margin-top: $mobile-header-height;
    height: 146px;
  }
}

.m-page--glossary__title {
  @extend %node_header_title;
  text-align: center;
}
