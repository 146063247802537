$ml: 100px;

.m-paragraph--quote {
  @extend %paragraph;
  @include icon($icon-quote);
  border-top: 3px solid $blue-light;
  border-bottom: 3px solid $blue-light;
  padding: 43px 0;
  margin: 20px 0;

  &:before {
    width: 74px;
    height: 46px;
    color: $blue-light;
    top: -24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 34px;
    background-color: $white;
  }
}

.m-paragraph--quote__inner {
  width: 700px;
  margin: 0 auto;

  @include bp('tabletmobile') {
    width: 100%;
  }
}

.m-paragraph--quote__cover {
  float: left;
  width: 68px;
  height: 68px;
  margin-top: 18px;

  @include fullimage;

  img {
    border-radius: 50%;
  }
}

%author {
  color: $text2;
  font-size: px2em(13);
  line-height: px2em(18, 13);

  a {
    font-size: 1em;
    text-decoration: underline;
  }
}

.m-paragraph--quote__author {
  @extend %author;
  margin-left: $ml;
  margin-top: 1em;
  color: $grey4;
}

.m-paragraph--quote__author--noimage {
  @extend %author;
  text-align: center;
  color: $grey4;
}

%quote_link {
  font-size: px2em(13);
  line-height: px2em(18, 13);

  a {
    color: $blue-light;
    font-size: 1em;
  }
}

.m-paragraph--quote__link {
  @extend %quote_link;
  margin-left: $ml;
}

.m-paragraph--quote__link--noimage {
  @extend %quote_link;
  text-align: center;
}

%quote {
  font-size: px2em(33);
  line-height: px2em(43, 33);
  font-weight: $bold;
  color: rgba($blue-light, 0.38);

  p {
    margin: 0;
  }

  @include bp('tablet') {
    font-size: px2em(28);
    line-height: px2em(35, 28);
  }

  @include bp('mobile') {
    font-size: px2em(24);
    line-height: px2em(30, 24);
  }
}

.m-paragraph--quote__quote {
  @extend %quote;
  margin-left: $ml;

  a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    font-style: inherit;
    text-decoration: underline;
    border-bottom: none;
  }
}

.m-paragraph--quote__quote--noimage {
  @extend %quote;
  text-align: center;

  a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    font-style: inherit;
    text-decoration: underline;
    border-bottom: none;
  }
}
