%ICON0A {
  &:before {
    content: '\EA01';
  }
}

%ICON0B {
  &:before {
    content: '\EA0E';
  }
}

%ICON0C {
  &:before {
    content: '\EA02';
  }
}

%ICON0D {
  &:before {
    content: '\EA0D';
  }
}

%ICON0E {
  &:before {
    content: '\EA05';
  }
}

%ICON0F {
  &:before {
    content: '\EA07';
  }
}

%ICON0G {
  &:before {
    content: '\EA08';
  }
}

%ICON0H {
  &:before {
    content: '\EA09';
  }
}

%ICON0I {
  &:before {
    content: '\EA0B';
  }
}

%ICON0J {
  &:before {
    content: '\EA0A';
  }
}

%ICON0K {
  &:before {
    content: '\EAB4';
  }
}

%ICON0L {
  &:before {
    content: '\EA10';
  }
}

%ICON0M {
  &:before {
    content: '\EAB6';
  }
}

%ICON0N {
  &:before {
    content: '\EAB8';
  }
}

%ICON0O {
  &:before {
    content: '\EA05';
  }
}

%ICON0P {
  &:before {
    content: '\EA0F';
  }
}

%data_icons {
  &[data-icon=""] {
    &:before {
      display: none;
    }
  }
  &[data-icon="0A"] {
    @extend %ICON0A;
  }
  &[data-icon="0B"] {
    @extend %ICON0B;
  }
  &[data-icon="0C"] {
    @extend %ICON0C;
  }
  &[data-icon="0D"] {
    @extend %ICON0D;
  }
  &[data-icon="0E"] {
    @extend %ICON0E;
  }
  &[data-icon="0F"] {
    @extend %ICON0F;
  }
  &[data-icon="0G"] {
    @extend %ICON0G;
  }
  &[data-icon="0H"] {
    @extend %ICON0H;
  }
  &[data-icon="0I"] {
    @extend %ICON0I;
  }
  &[data-icon="0J"] {
    @extend %ICON0J;
  }
  &[data-icon="0K"] {
    @extend %ICON0K;
  }
  &[data-icon="0L"] {
    @extend %ICON0L;
  }
  &[data-icon="0M"] {
    @extend %ICON0M;
  }
  &[data-icon="0N"] {
    @extend %ICON0N;
  }
  &[data-icon="0O"] {
    @extend %ICON0O;
  }
  &[data-icon="0P"] {
    @extend %ICON0P;
  }
}

%data_icons_with_link {
  &[data-icon="0A"] a {
    @extend %ICON0A;
  }
  &[data-icon="0B"] a {
    @extend %ICON0B;
  }
  &[data-icon="0C"] a {
    @extend %ICON0C;
  }
  &[data-icon="0D"] a {
    @extend %ICON0D;
  }
  &[data-icon="0E"] a {
    @extend %ICON0E;
  }
  &[data-icon="0F"] a {
    @extend %ICON0F;
  }
  &[data-icon="0G"] a {
    @extend %ICON0G;
  }
  &[data-icon="0H"] a {
    @extend %ICON0H;
  }
  &[data-icon="0I"] a {
    @extend %ICON0I;
  }
  &[data-icon="0J"] a {
    @extend %ICON0J;
  }
  &[data-icon="0K"] a {
    @extend %ICON0K;
  }
  &[data-icon="0L"] a {
    @extend %ICON0L;
  }
  &[data-icon="0M"] a {
    @extend %ICON0M;
  }
  &[data-icon="0N"] a {
    @extend %ICON0N;
  }
  &[data-icon="0O"] a {
    @extend %ICON0O;
  }
  &[data-icon="0P"] a {
    @extend %ICON0P;
  }
}
