.m-form--contact,
#webform-client-form-13474 {

  #webform-component-journalist,
  #webform-component-message {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include bp('mobile') {
      display: block;
    }

    label {
      width: 20%;
      margin-right: 2%;

      @include bp('mobile') {
        width: 93%;
        margin-right: 0;
        font-size: px2em(14);
        line-height: px2em(18, 14);
      }
    }

    .form-radios,
    .form-textarea-wrapper {
      width: 77.2%;
      align-self: flex-start;

      @include bp('mobile') {
        width: 93%;
      }
    }

    .form-textarea-wrapper {
      @include bp('mobile') {
        width: calc(100% - 2px);
      }
    }

    .description {
      padding-left: $webform-label-width;
      width: 78%;

      @include bp('mobile') {
        padding-left: 0;
        width: 93%;
      }
    }
  }

  #webform-component-message {
    textarea {
      height: 200px;
    }
  }

  .webform-component-markup {
    padding-left: $webform-label-width;
    padding-bottom: 15px;

    @include bp('mobile') {
      padding-left: 0;
    }

    p {
      margin: 0;
    }
  }

  .captcha {
    margin-left: $webform-label-width;
    margin-bottom: 25px;
    padding: 0;
    border: 0;

    @include bp('mobile') {
      margin-left: 0;
    }

    legend,
    .fieldset-description {
      display: none;
    }
  }

  .form-actions {
    padding-left: $webform-label-width;

    @include bp('mobile') {
      padding-left: 0;
      font-size: px2em(14);
      line-height: px2em(20, 14);

      label, a {
        font-size: inherit;
        line-height: inherit;
      }
    }

    input[type=submit] {
      @include bp('mobile') {
        font-size: px2em(14, 14);
        line-height: px2em(18, 14);
        padding-top: 4px;
      }
    }
  }

  .webform-confirmation {
    font-size: px2em(17, 16);
    color: green;
  }

  .links .ajax-processed {
    // @extend %button;
    display: none;
  }
}
