$border_color: $blue-dark;

%listgrid {
	width: 100%;
}

%listgrid__trigger {
	padding-bottom: px2em(28);
	margin-bottom: px2em(34);
	border-bottom: 1px solid $border_color;

	&:last-of-type {
		border-bottom: none;
	}

	@include bp('tablet') {
		padding-bottom: px2em(20);
		margin-bottom: px2em(22);
	}

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		width: 100%;
		align-self: left;
	}
}
