.m-paragraph--country {
  margin-top: 40px;
}

.m-paragraph--country__inner {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 20px;
  margin: 0 auto;
  width: 82%;

  @include bp('smalldesktop') {
    width: 100%;
  }

  @include bp('tablet') {
    width: 100%;
  }

  @include bp('mobile') {
    row-gap: 10px;
  }

  @include bp('print') {
    width: 100%;
  }
}

.m-paragraph--country__left {
  width: 48%;

  @include bp('tablet') {
    width: 43%;
  }

  @include bp('mobile') {
    width: 100%;
  }
}

.m-paragraph--country__image {

  img {
    width: 100%;
    height: auto;

    @include bp('mobile') {
      width: 60%;
    }
  }
}

.m-paragraph--country__content {
  width: calc(100% - 48% - 50px);
  padding-top: 35px;

  @include bp('tablet') {
    width: calc(100% - 43% - 50px);
    padding-top: 0;
  }

  @include bp('mobile') {
    width: 100%;
  }
}

.m-paragraph--country__tag {
  font-size: px2em(15);
  line-height: 1.55;
  font-weight: $semi-bold;
  margin-bottom: 3px;
}

.m-paragraph--country__title {
  font-weight: $bold;
  font-size: px2em(26);
  line-height: px2em(39, 26);
  letter-spacing: normal;
  color: $blue-dark;
  margin: 0;
}

.m-paragraph--country__teaser {
  font-size: px2em(17);
  line-height: 1.55;
  margin-top: 20px;
}

.m-paragraph--country__links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 35px;

  @include bp('mobile') {
    margin-top: 25px;
  }
}

.m-paragraph--country__link {
  @extend %button;
}
