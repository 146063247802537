// Base
.m-block--languageswitch {
  position: relative;
  width: 70px;

  > .menu_icon {
    text-transform: uppercase;

    a {
      line-height: 28px;
    }

    @include bp('mobile') {
      color: $white;
    }
  }
}

%language_listitem {
  @extend %list_item;
  width: 100%;

  $fs: 17;

  a {
    padding: px2em(10, $fs) px2em(20, $fs);
    display: block;
    font-size: px2em($fs);
    text-transform: uppercase;
    color: $blue-dark;
  }

  &:hover {
    background-color: $blue-dark;

    a {
      color: $white;
    }
  }

  &.#{$isactive} {
    display: none;
  }

  @include bp('tabletmobile') {
    a {
      $fs: 20;
      font-size: px2em($fs);
      padding: px2em(14, $fs) px2em(20, $fs);
      color: $white;
    }
  }
}

.language-switcher-locale-url {
  display: none;
  position: absolute;
  padding: 0;
  top: 12px;
  left: 7px;
  width: 100%;
  z-index: 10;
  border: 1px solid $blue-dark;

  &.#{$isactive} {
    display: block;
  }

  @include bp('tabletmobile') {
    top: 184px;
    right: calc(6.5% - 2px);
    width: calc(18% + 14px + 20px);
  }

  @include bp('mobile') {
    top: 184px;
  }

  > li {
    @extend %language_listitem;
  }
}

//STATES
.m-block--languageswitch {
  > .menu_icon {
    &:hover {
      & + .language-switcher-locale-url {
        display: block;
      }
    }
  }
  .language-switcher-locale-url {
    &:hover {
      display: block;
    }
  }
}
