%headborder {
  &:before {
    content: '|';
    position: relative;
    top: -1px;
  }
}

.l-header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);

  @include bp('tablet') {
    height: $tablet-header-height;
  }

  @include bp('mobile') {
    height: $mobile-header-height;
  }

  @include bp('print') {
    position: static;
    top: auto;
    left: auto;
  }

  &.is-active {
    position: absolute;

    @include bp('tabletmobile') {
      position: fixed;
    }
  }

  > .innerWrapper {
    height: auto;
    padding-top: 25px;
    @extend %transitionAll;

    @include bp('print') {
      width: 100%;
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }

    > div.block {
      float: right;
      @extend %transitionAll;

      @include bp('tablet') {
        width: 64.5%;
      }

      @include bp('mobile') {
        width: auto;
      }
    }

    .claim-link {
      display: block;
    }

    @include bp('tablet') {
      width: 90%;
      margin: 0 auto;
      padding-top: 30px;
    }

    @include bp('mobile') {
      margin: 0;
      padding: 20px;
      width: calc(100% - 40px);
    }

    > * {
      @include bp('print') {
        display: none;
      }
    }
  }

  .logo {
    float: left;
    @extend %transitionAll;
    @include fullimage;
    margin-bottom: 31px;

    @include bp('tabletmobile') {
      margin-bottom: 0;
    }

    @include bp('print') {
      display: block;
    }

    img {
      display: block;
      height: 96px;
      width: auto;
      @extend %transitionAll;

      @include bp('print') {
        height: 50px;
      }
    }

    @include bp('tabletmobile') {
      height: 52px;
      width: auto;
    }
  }

  h1.claim-headline-element {
    margin: 0;
    padding: 0;
  }

  .claim {
    @include font1();
    color: $blue-dark;
    font-size: px2em(22);
    font-weight: $bold;
    line-height: 1.3em;
    float: left;
    margin-left: px2em(30);
    padding-top: px2em(12);
    width: 11em;
    @extend %transitionAll;

    @include bp('print') {
      padding-top: 0;
    }

    @include bp('smalldesktop') {
      margin-left: 20px;
      font-size: px2em(17);
      padding-top: px2em(22);
      // padding: 10px 0;
    }

    @include bp('tabletmobile') {
      margin-left: 20px;
      font-size: px2em(17);
      padding: 10px 0;
    }
  }

  .m-block--searchform {
    padding-top: 24px;
    margin-right: 20px;

    .form-item-search-block-form {
      float: left;
      display: block;

      @include bp('tabletmobile') {
        float: none;
      }
    }
  }

  .m-servicemenu__container {
    // padding-top: 20px;
  }

  .m-block--sociallinks {
    padding-top: 33px;
    @extend %headborder;

    &:before {
      top: -6px;
    }

    // .m-block--sociallinks__items {
    //   display: inline-block;
    // }
  }

  .m-mainmenu__container {
    float: right;
    width: 65%;
    @extend %transitionAll;

    @include bp('tablet') {

    }

    @include bp('tabletmobile') {
      margin-left: 0;

      .menu_icon {
        margin-top: 12px;
      }
    }

    @include bp('mobile') {
      width: auto;
    }
  }

  .m-block--languageswitch {
    padding-top: 30px;

    > .menu_icon {
      @extend %headborder;
    }
  }
}

body.admin-menu {
  .l-header {
    top: 28px;

    &.#{$isactive} {
      top: 0;

      @include bp('mobile') {
        top: 28px;
      }
    }
  }
}

// shrink-header on scroll
.l-header.shrink {

  @include bp('tablet') {
    height: auto;
  }

  > .innerWrapper {
    padding-top: 21px;

    @include bp('mobile') {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .logo {
      margin-bottom: 24px;

      @include bp('tablet') {
        margin-bottom: 20px;
      }

      @include bp('mobile') {
        margin-bottom: 0;
      }

      img {
        width: auto;
        height: 50px;
      }
    }

    .claim {
      float: left;
      padding-top: .3em;

      @include bp('desktop') {
        font-size: px2em(16);
      }

      @include bp('smalldesktop') {
        font-size: px2em(16);
      }

      @include bp('mobile') {
        font-size: px2em(12);
        padding-top: 10px;
      }
    }

    .menu_icon {
      @include bp('tabletmobile') {
        margin-top: 9px;
      }
    }

    .m-block--searchform {
      padding-top: 7px;
    }
    .m-servicemenu__container {
      margin-bottom: 3px;

      @include bp('tablet') {
        margin-bottom: 5px;
      }
    }
    .m-block--sociallinks {
      padding-top: 11px;
    }
    .m-mainmenu__container {
      margin-top: 3px;
    }
    .m-block--languageswitch {
      padding-top: 0;
      line-height: 1.3em;
      margin-left: 25px;
      margin-top: 9px;
    }
  }
}

// responsive
.l-header {
  @include bp('tabletmobile') {
    > .innerWrapper {
      // > * {
      //   display: none;
      // }

      .logo {
        display: block;

        img {
          height: 52px;
        }
      }

      .claim {
        display: block;
        font-size: px2em(15);
        width: 11em;

        @include bp('mobile') {
          font-size: px2em(12);
        }
      }

      .m-mainmenu__container {
        display: block;
        margin-top: 0;
      }
    }

    &.#{$isactive} {
      .m-block--searchform {
        float: none;
        margin-left: 0;
        padding-top: 0;
        position: absolute;
        top: calc(#{$mobile-header-height} + 20px);
        left: $mobile_diff;
        z-index: 100;
        width: $mobile_width;
        display: block;
      }

      .m-block--languageswitch {
        float: none;
        margin-left: 0;
        padding-top: 0;
        position: absolute;
        top: calc(#{$mobile-header-height} + 80px);
        left: 200px;
        display: block;
        z-index: 100;
      }

      .m-servicemenu__container {
        @include bp('mobile') {
          float: none;
          margin-left: 0;
          padding-top: 0;
          display: block;
          z-index: 100;
          left: 0;
          position: relative;
        }
      }

      .m-block--sociallinks {
        float: left;
        margin-left: 0;
        padding-top: 0;
        position: absolute;
        top: calc(#{$mobile-header-height} + 80px);
        right: 1.5em;
        display: block;
        z-index: 100;
      }
    }
  }

  @include bp('tablet') {
    &.#{$isactive} {
      .m-block--searchform {
        width: $tablet_width;
      }
    }
  }
}
