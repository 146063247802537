.m-paragraph--faq {

}

.m-paragraph--faq__questionanswer__trigger[data-open="1"] {
  .m-paragraph--faqquestionanwser__answer {
   // display: block;
  }
}

.m-paragraph--faq__title {
  @extend %paragraphs_title;
}

.m-paragraph--faq__text {
  @extend %paragraph_text;
  margin-bottom: 31px;
}

.m-paragraph--faq__questionanswer {
  padding: 18px 10px;
}

.m-paragraph--faq__questionanswer__loop {
  color: $grey3;
  font-weight: $light;
  float: left;
  font-size: px2em(24);
  line-height: 25px;
}

.m-paragraph--faq__questionanswer__trigger {
  width: 100%;
}

.m-paragraph--faq__questionanswer__trigger + .m-paragraph--faq__questionanswer__trigger {
  margin-top: 40px;
}

.paragraph--type--faq-question-anwser {
  display: block;
  width: calc(100% - 15px - 40px - 30px);
  padding-left: 15px;
  margin-left: 30px;
  padding-right: 40px;
  border-left: 1px solid $grey3;
  cursor: pointer;
}

.m-paragraph--faqquestionanwser__question {
  font-weight: $bold;
  font-size: 16px;
  line-height: 21px;

  @include icon('\EA03');

  &:before {
    left: auto;
    right: -35px;
    top: 6px;
    color: $grey3;
  }

  @include bp('mobile') {
    &:before {
      right: -40px;
      top: 0px;
    }
  }
}

.m-paragraph--faqquestionanwser__answer {
  display: none;
  font-size: px2em(16);
  line-height: px2em(21, 16);

  a {
    font-weight: inherit;
    font-size: 1em;
    color: $blue-light;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include bp('print') {
    display: block;
  }
}

//states
.m-paragraph--faqquestionanwser__question {
  &.#{$isactive} {
    & + .m-paragraph--faqquestionanwser__answer {
      display: block;
    }
  }
}

.m-paragraph--faqquestionanwser__question {
  &.#{$isactive} {
    &:before {
      content: '\EA04';
      color: $blue-light;
    }
  }
}

.m-paragraph--faqquestionanwser__question {
  &.#{$isopend} {
    color: $blue-light;
  }
}

.m-paragraph--faq__questionanswer {
  &.#{$isactive} {
    background-color: rgba($blue-light, 0.38);

    .m-paragraph--faq__questionanswer__loop {
      color: $blue-light;
    }

    .paragraph--type--faq-question-anwser {
      border-left-color: $blue-light;
    }
  }
}


//states by default
.m-paragraph--faq__questionanswer__trigger[data-open="1"] {
  &.#{$isopend} {
    .m-paragraph--faqquestionanwser__answer {
      display: block;
    }

    .m-paragraph--faqquestionanwser__question {
      &:before {
        content: '\EA04';
        color: $blue-light;
      }
    }
  }
}
