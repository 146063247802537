.l-page--frontpage {
  @extend %page;
  padding-top: calc(#{$header-height} - 1px);
  padding-bottom: 25px;

  @include bp('tablet') {
    padding-top: $tablet-header-height;
  }

  @include bp('mobile') {
    padding-top: $mobile-header-height;
  }
}
