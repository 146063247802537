.m-node--factsheet--topnews {
  position: relative;
}

.m-node--factsheet--topnews__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 26px;
  width: 185px;

  a:before,
  span:before {
    content: $icon-factsheet;
  }

  a {
    @include ci('factsheet');
  }

  @include bp('mobile') {
    margin-left: $mobile_diff;
  }
}

.m-node--factsheet--topnews__inner {
  position: relative;
  z-index: 2;
  padding-top: 120px;
  padding-left: 75px;

  @include bp('smalldesktop') {
    padding-left: 0;
  }

  @include bp('tablet') {
    padding: 47px 20px 44px 0;
  }

  @include bp('mobile') {
    padding: 47px 20px 44px 0;
  }
}

.m-node--factsheet--topnews__title {
  font-size: 1em;
  margin: 0 0 0.6em;

  a {
    @extend %node_title;
    text-align: left;
    color: $white;
    margin: 0;
    font-size: px2em(30);
    line-height: 1.4em;

    &:hover {
      color: rgba($white, $bg_opacity);
    }

    @include bp('tablet') {
      margin-top: 10px;
    }
  }
}

.m-node--factsheet--topnews__date {
  @extend %node_date;
  text-align: left;
  margin: 0 0 0.6em;

  > div {
    display: inline-block;
    color: $white;

    a {
      color: $white;
    }

    & + div {
      &:before {
        content: ' | ';
        display: inline-block;
      }
      > div {
        display: inline-block;
      }
    }
  }
}

.m-node--factsheet--topnews__subtitle {
  @extend %node_subtitle;
  color: $white;
  text-align: left;
}

.m-node--factsheet--topnews__tags {
  @extend %node_tags;
  text-align: left;
  margin-bottom: 0;

  a {
    color: $white;
  }
}

.m-node--factsheet--topnews__teaser {
  @extend %node_teaser;
  text-align: left;
  color: $white;
}
