.m-searchresults {
  @extend %ul;
  @extend %listgrid3;
  margin-bottom: 30px;
}

.m-searchresults__trigger {
  @extend %list_item;
  @extend %listgrid3__trigger;
  margin-bottom: 69px;

  @include bp('mobile') {
    margin-bottom: 35px;
  }
}

@include bp('mobile') {
  .page-search {
    .m-page--system__title {
      padding-top: 80px;
    }

    .region-system-page-teaser {
      font-size: 15px;
      width: 100%;
      margin-top: 15px;
    }

    .l-page--system__bgimage {
      height: auto;
      min-height: auto;
      padding-bottom: 30px;
    }
  }
}
