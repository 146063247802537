.m-side_toolbar {
  position: fixed;
  top: calc(#{$header-height} + 60px);
  width: 285px;
  right: -218px;
  z-index: 99;

  // increase hover area
  &:before {
    content: '';
    position: absolute;
    left: -20px;
    top: -20px;
    bottom: -20px;
    right: -20px;
    z-index: -1;
  }

  @include bp("tabletmobile") {
    display: none;
  }

  @include bp("print") {
    display: none;
  }
}

.m-side_toolbar__item_icon {
  float: left;
  width: 40px;
  height: 100%;
  margin: 0 15px 0 15px;
}

.m-side_toolbar__item_text {
  display: flex;
  float: left;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-right: 23px;

  color: $white;
  @include font1();
  font-size: px2em(15);
  font-weight: $bold;
  line-height: px2em(20, 15);
}

@mixin side_toolbar_item($icon_code, $font_size, $top_offset) {
  cursor: pointer;
  display: block;
  height: 60px;
  @include transition(150ms);

  background-color: $red-light;
  &:hover {
    background-color: $red-dark;
  }

  &:not(:last-child) {
    .m-side_toolbar__item_icon {
      border-bottom: 1px solid $white;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
  }

  .m-side_toolbar__item_icon {
    @include icon($icon_code);
    &:before {
      font-size: $font_size;
      top: $top_offset;
      right: 0;
    }
  }
}

.m-side_toolbar__newsletter {
  @include side_toolbar_item('\EA0F', 30px, 8px);
}

.m-side_toolbar__network {
  @include side_toolbar_item('\EA0D', 34px, 6px);
}

.m-side_toolbar__interview {
  @include side_toolbar_item('\EA0B', 34px, 6px);
}
