%teaserlistgrid {
  display: flex;
  flex-direction: row;

  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  &:after {
    content: "";
    flex: 1 1 100%;
    max-width: 31%;
  }
}

%teaserlistgrid__trigger {
  width: 31%;
  margin: 0 0 40px 0;
  background-color: $grey2;
  position: relative;
  align-items: flex-end;
  display: flex;
  min-height: px2em(388);

  &:nth-child(3n-2) {
    margin-left: 0;
  }

  @include bp('tablet') {
    width: 47%;
    min-height: px2em(358);
    margin-left: 6%;

    &:nth-child(3n-2) {
      margin-left: 6%;
    }

    &:nth-child(odd) {
      margin-left: 0;
    }
  }

  @include bp('mobile') {
    width: 100%;
    min-height: px2em(280);
    margin-left: 0;
    margin-bottom: 23px;

    &:nth-child(3n-2) {
      margin-left: 0;
    }
  }
}

%teaserlistgrid__dark_overlay {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: .8;
    background-color: #122f4d;
    @extend %transitionAll;
  }
}

%teaserlistgrid__content {
  padding: 0 45px 45px 45px;
  position: relative;

  @include bp('mobile') {
    padding: 0 23px 23px 23px;
  }
}

%teaserlistgrid__inner {
  padding-top: px2em(115);

  @include bp('mobile') {
    padding-top: px2em(100);
  }
}

%teaserlistgrid__type {
  @include headline_flag(false);
  position: absolute;
  top: 41px;
  left: 0;
  padding-left: 97px;

  @include bp('tablet') {
    padding-left: 49px;
  }

  @include bp('mobile') {
    top: 27px;
  }
}

%teaserlistgrid__type_link {
  @include headline_flag(true);
  position: absolute;
  top: 41px;
  left: 0;
  padding-left: 45px;
}

%teaserlistgrid__stamp {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 0 0;
  position: absolute;
  right: 40px;
  top: -65px;

  @include bp('mobile') {
    right: 23px;
  }

  &[data-stamp="update"] {
    background-image: url(../../img/stamp_update.png);
    width: 80px;
    height: 38px;

    @include bp('mobile') {
      width: 61px;
      height: 29px;
    }
  }

  &[data-stamp="evergreen"] {
    background-image: url(../../img/stamp_evergreen.png);
    width: 110px;
    height: 40px;

    @include bp('mobile') {
      width: 90px;
      height: 33px;
    }
  }

  &[data-stamp="correction"] {
    background-image: url(../../img/stamp_correction.png);
    width: 122px;
    height: 41px;

    @include bp('mobile') {
      width: 95px;
      height: 32px;
    }
  }
}

%teaserlistgrid__stamp_big {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 0 0;
  position: absolute;
  right: 40px;
  top: -65px;

  @include bp('mobile') {
    right: 23px;
  }

  &[data-stamp="update"] {
    background-image: url(../../img/stamp_update.png);
    width: 95px;
    height: 45px;

    @include bp('mobile') {
      width: 61px;
      height: 29px;
    }
  }

  &[data-stamp="evergreen"] {
    background-image: url(../../img/stamp_evergreen.png);
    width: 130px;
    height: 47px;

    @include bp('mobile') {
      width: 90px;
      height: 33px;
    }
  }

  &[data-stamp="correction"] {
    background-image: url(../../img/stamp_correction.png);
    width: 143px;
    height: 48px;

    @include bp('mobile') {
      width: 95px;
      height: 32px;
    }
  }
}

%teaserlistgrid__title {
  margin: px2em(20, 20) 0 0 0;

  a {
    @include font2();
    color: $text1;
    font-size: px2em(26, 20);
    line-height: px2em(33, 26);
    font-weight: $semi-bold;

    @include bp('mobile') {
      font-size: px2em(22, 18);
      line-height: px2em(26, 22);
    }
  }
}

%teaserlistgrid__tags {
  font-size: px2em(16);
  line-height: px2em(22, 16);
  margin-top: px2em(35);

  @include bp('mobile') {
    font-size: px2em(13);
    line-height: px2em(18, 13);
  }

  div {
    display: inline;
  }

  a {
    display: inline-block;
    padding-right: px2em(10);
    font-size: px2em(16);
    line-height: px2em(22, 16);
    font-style: italic;
    color: $text2;
  }
}

%teaserlistgrid__date {
  font-size: px2em(15);
  line-height: px2em(20, 15);

  @include bp('mobile') {
    font-size: px2em(12);
  }

  a {
    font-size: px2em(15, 15);
    line-height: px2em(20, 15);

    @include bp('mobile') {
      font-size: px2em(12, 12);
    }
  }
}
