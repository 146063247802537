.m-node--member--full {
  overflow: hidden;
  padding-top: 100px;
}

.m-node--member--full__content_main {
  @extend %content__main;

  header {
    padding-bottom: 110px;
  }
}

.m-node--member--full__overview {
  padding-bottom: 52px;
}

.m-node--member--full__content_sidebar {
  @extend %content__sidebar;
}

.m-node--member--full__type {
  @extend %node_icon;
  top: 0;
  a:before {
    content: '\EA0D';
  }
}

.m-node--member--full__printbar {
  @extend %node_printbar;
  top: 10px;
  right: 0;

}

.m-node--member--full__photo {
  display: inline-block;
  float: left;
  margin-top: 6px;
  width: 100px;
  height: 100px;

  img {
    border-radius: 50%;
    border-color: $white;
  }
}

.m-node--member--full__photo_nophoto {
  @include icon('\EA0D');
  &:before {
    color: $blue-light;
    font-size: px2em(100);
    line-height: 100%;
  }
  width: 100%;
  height: 100%;
}

.m-node--member--full__name,
.m-node--member--full__location,
.m-node--member--full__tags,
.m-node--member--full__twitter {
  margin-left: 140px;
}

.m-node--member--full__name {
  h2 {
    @extend %node_title;
    text-align: left;
    margin-top: 0;
  }
}

.m-node--member--full__location {
  @include font1;
  font-weight: $regular;;
  @include font_size_line_height(17, 23);
  padding-bottom: 8px;
  margin-bottom: 6px;
  border-bottom: 1px solid $grey3;
}

.m-node--member--full__tags {
  @include font_size_line_height(17, 23);
  padding-bottom: 8px;
  margin-bottom: 6px;
  border-bottom: 1px solid $grey3;
  @extend %node_tags;
  .m-globaltags {
    display: inline-block;
  }
  text-align: left;
}

.m-node--member--full__twitter {
  a {
    @extend %twitter_link;
    @include font_size_line_height(17, 23);
  }
}

.m-node--member--full__bio {
  @include content_formater();
}

.m-node--member--full__back_link {
  @extend %node_browsing;
}
