.m-member__row {
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px $grey3 solid;
  }

  &[data-anonymous='1'] {
    display: none;
  }

  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .m-member__names,
  .m-member__country,
  .m-member__topics,
  .m-member__twitter,
  .m-research-map__list__item__location,
  .m-research-map__list__item__title,
  .m-research-map__list__item__tags {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 10px;
    @include bp('mobile') {
      padding: 0;
    }
  }
  .m-member__type {
    flex-grow: 1;
    flex-basis: 0;
  }

  @include bp('mobile') {
    height: auto;
    padding: 20px 0 8px;
    > div:not(.m-member__photo), .m-member__photo {
      flex: 1 0 100%;
      flex-basis: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
  }

  &:hover {
    background-color: $blue-maplist-hover;
    .m-member__photo_nophoto:before {
      color: $blue-light;
    }
  }
}

.m-member__photo {
  padding: 0 5px 0 20px;
  width: 64px;
  height: 100%;
  img {
    border-radius: 50%;
    border-color: $white;
  }
  img,
  div {
    float: left;
    display: inline-block;
  }
  @include bp('mobile') {
    padding: 0 0 15px;
  }
}

.m-member__names > a {
  @include font(15, 20);
  font-weight: $bold;
  @include bp('mobile') {
    flex: 1 0 100%;
    flex-basis: 100%;
    @include font(14, 19);
  }
}

.m-member__topics {
  @include font(15, 20);
  color: $text2;
  @include bp('mobile') {
    flex: 1 0 100%;
    flex-basis: 100%;
    @include font(14, 19);
  }
}

.m-member__type {
  @include font(15, 20);
  font-weight: $bold;
  padding-left: 30px !important;
  position: relative;
  float: left;
  width: 100%;

  @include bp('mobile') {
    flex: 1 0 100%;
    flex-basis: 100%;
    @include font(14, 19);
  }

  &::before {
    content: '';
    @include member_icon;
    position: absolute;
    left: 0;
  }

  &.member:before {
    background-color: $member_icon_color_member;
  }
  &.ambassador:before {
    background-color: $member_icon_color_ambassador;
  }
  &.event:before {
    background-color: $yellow;
  }
  &.interest_point:before {
    background-color: $green;
  }
}

.m-member__country {
  @include font1;
  color: $text2;
  font-size: px2em(15);
  font-weight: $regular;
  font-style: normal;
  line-height: px2em(20);
  @include bp('mobile') {
    flex: 1 0 100%;
    flex-basis: 100%;
    @include font(14, 19);
  }
}

.m-member__twitter {
  @include bp('mobile') {
    flex: 1 0 100%;
    flex-basis: 100%;
    @include font(14, 19);
  }
  a {
    @extend %twitter_link;
  }
}

.m-member__photo_nophoto {
  @include icon('\EA0D');
  &:before {
    color: $grey5;
    font-size: px2em(64);
    line-height: 100%;
  }
  width: 100%;
  height: 64px;
}
