.m-sidebars {

}

%sidebars_lightblue {
  background-color: $blue-light;
  color: $white;
  padding: 32px 9% 26px 9%;
}

%sidebars_item_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

%sidebars_item_list_item {
  border-top: 2px solid $grey3;
  padding-top: 11px;
  margin-top: 11px;

  &:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
  }
}

%sidebars_item_list_title {
  font-size: 1em;
  margin: 12px 0 0 0;
  letter-spacing: 0;

  a {
    @include font2();
    font-size: px2em(16, 14);
    line-height: px2em(23, 16);
    font-weight: $semi-bold;
  }
}

%sidebars-list__block {
  position: relative;
  padding-top: 70px;
  margin-bottom: 60px;
}

%stamp_dossiers {
  @extend %teaserlistgrid__type;
  padding-left: 65px;
  top: 0;
  background-color: $blue-light;

  &:before {
    content: $icon-dossiers;
    top: 4px;
    left: 23px;
    color: $white;
    font-size: 33px;

    @include bp('tablet') {
      font-size: 22px;
      top: 2px;
    }
  }

  &:after {
    background-color: $blue-light;;
  }

  span {
    @include font_size_line_height(16,16);
    font-weight: $bold;
    color: $white;

    @include bp('tablet') {
      font-weight: $regular;
    }
  }
}

%sidebars-list__title {
  @extend %teaserlistgrid__type;
  background-color: $grey2;
  font-size: 1em;
  padding-left: 65px;
  top: 0;

  &:before {
    top: 4px;
    left: 27px;
    color: $blue-light;
    font-size: 33px;

    @include bp('tablet') {
      font-size: 22px;
      top: 2px;
    }
  }

  &:after {
    background-color: $grey2;
  }

  span {
    font-size: px2em(15, 16);
    color: $text2;
  }
}

%sidebars-list__content {
  font-size: px2em(14);
  line-height: px2em(19, 14);
  padding: 0 0 0 7%;

  @include bp('tablet') {
    padding-left: 0;
  }
}

@import "_m-sidebars--tabs";
@import "_m-sidebars--contact";
@import "_m-sidebars--news";
@import "_m-sidebars--further-reading";
@import "_m-sidebars--attachments";
@import "_m-sidebars--events";
@import "_m-sidebars--experts";
@import "_m-sidebars--blogposts";
