%content__main {
  width: 775px;
  display: inline-block;
  float: left;
  position: relative;
  margin-right: 130px;

  @include bp('tablet') {
    width: 100%;
    margin-right: 0;
    float: none;
    display: block;
  }

  @include bp('print') {
    width: 100%;
    margin: 0;
  }

  &:after {
    position: absolute;
    right: -130px;
    top: 0;
    content: '';
    height: 100%;
    width: 1px;
    background-color: $grey3;

    @include bp('print') {
      display: none;
    }
  }

  @include bp('mobile') {
    width: 100%;
    float: none;
    display: block;
    &:after {
      display: none;
    }
  }

  @media print{
    a:after{
      content:" (" attr(href) ") ";
      font-size: 1em;
      font-weight: normal;
      color: #aaa;
    }
  }
}

%content__sidebar {
  width: 335px;
  display: inline-block;
  float: left;
  position: relative;

  &:before {
    position: absolute;
    left: -1px;
    top: 0;
    content: '';
    height: 100%;
    width: 1px;
    background-color: $grey3;
  }

  @include bp('tablet') {
    width: 100%;
    float: none;
    display: block;
    padding-top: 100px;

    &:before {
      display: none;
    }
  }

  @include bp('print') {
    display: none;
  }
}
