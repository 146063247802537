.l-page--list {
  @extend %page;

  &[data-list="blog_posts"] {
    .m-node--list__header {
      background-color: $purple1;

      &:before {
        background-color: $purple1;
      }
    }
  }
}
