.m-paragraph--live-blog {
  @extend %paragraph;
  padding-top: 30px;
  border-top: 1px $grey3 solid;
  margin-top: 30px;
}

.m-paragraph--live-blog__header {
  overflow: hidden;
  margin-bottom: 18px;
}

.m-paragraph--live-blog__author_portrait {
  display: inline-block;
  float: left;
  width: 64px;
  height: 64px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-color: $white;
    background-color: $grey2;
  }
}

.m-paragraph--live-blog__date,
.m-paragraph--live-blog__author_name {
  margin-left: 80px;
  span {
    @include font_size_line_height(14, 14);
  }
}

.m-paragraph--live-blog__author_name {
  padding-top: 7px;
  span {
    font-weight: $bold;
  }
}

.m-paragraph--live-blog__text {
  @extend %paragraph_text;
}

.m-paragraph--live-blog__image {
  @extend %m-paragraph--textimage__image;
  float: left;
  margin-right: 40px;
  margin-bottom: 1em;

  @include bp('mobile') {
    float: none;
    margin-right: 0;
  }

  &.image_size_small {
    width: 25%;

    @include bp('mobile') {
      width: 100%;
    }
  }

  &.image_size_default {
    width: 30%;

    @include bp('mobile') {
      width: 100%;
    }
  }

  &.image_size_big {
    width: 50%;

    @include bp('mobile') {
      width: 100%;
    }
  }

  &.image_size_full {
    width: 100%;
    margin-right: 0;
  }

  img {
    width: 100%;
  }

  @extend %image_caption;
}
