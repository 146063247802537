.m-paragraph--guides {
  margin-top: 35px !important;
  height: 560px;
  background-repeat: no-repeat;
  background-position: right 20px top;
  background-size: contain;

  @include bp('tablet') {
    height: auto;
    background-position: right -63px bottom;
  }
}

.m-paragraph--guides__inner {
  padding: 154px 0 0 119px;

  @include bp('smalldesktop') {
    padding-top: 80px;
  }

  @include bp('tablet') {
    padding: 0 0 220px 0;
  }
}

.m-paragraph--guides__content {
  width: 50%;

  @include bp('bigdesktop') {
    width: 62%;
  }

  @include bp('mobile') {
    width: 100%;
  }
}

.m-paragraph--guides__title {
  font-weight: $bold;
  font-size: px2em(35);
  line-height: 1.25;
  letter-spacing: normal;
  color: $blue-dark;
  margin: 0;

  @include bp('mobile') {
    font-size: px2em(26);
    line-height: px2em(39, 26);
  }
}

.m-paragraph--guides__teaser {
  font-size: px2em(20);
  line-height: 1.55;
  margin-top: 30px;

  @include bp('mobile') {
    font-size: px2em(17);
    line-height: 1.55;
    margin-top: 20px;
  }
}

.m-paragraph--guides__link {
  margin-top: 35px;

  @include bp('mobile') {
    margin-top: 30px;
  }

  a {
    @extend %button;
  }
}

.m-paragraph--guides__image {
  margin-top: 30px;

  img {
    width: 200px;
    height: auto;
  }
}
