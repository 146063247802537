%blueline {
  display: inline-block;
  content: '';
  width: 137px;
  height: 4px;
  background-color: $blue-light;
  margin-bottom: 8px;
}

.m-footerclaim {
  margin-bottom: 42px;
  margin-top: 105px;

  @include bp('tablet') {
    margin-top: 40px;
  }

  @include bp('print') {
    display: none;
  }

  > .innerWrapper {
    text-align: center;
  }

  p {
    color: $grey4;
    @include font1();
    font-size: px2em(30);
    line-height: px2em(41, 30);
    position: relative;
    margin-top: 0;

    @include bp('print') {
      color: $text1;
    }

    @include bp('tablet') {
      @include font(26, 36);
      padding-top: 20px;
    }

    @include bp('mobile') {
      @include font(20, 27);
      padding-top: 20px;
    }

    &:before {
      @extend %blueline;
      margin-right: 30px;

      @include bp('tablet') {
        position: absolute;
        width: 175px;
        height: 4px;
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;
      }
    }

    &:after {
      @extend %blueline;
      margin-left: 30px;

      @include bp('tablet') {
        display: none;
      }
    }
  }
}

.m-footer__contacts {
  width: 22%;
  float: left;
  margin-right: 5%;
  position: relative;
  padding: 0 15px 90px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include bp('print') {
    padding: 0;
  }

  &::after {
    content: '';
    height: 100%;
    width: 1px;
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;

    @include bp('tablet') {
      display: none;
    }
  }

  @include bp('tablet') {
    width: 100%;
    float: none;
    margin-right: 0;
    padding-bottom: 70px;
    padding-right: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid $white;
  }

  @include bp('mobile') {
    padding-bottom: 60px;
  }
}

.m-footer__contacts__address {
  margin-bottom: 22px;
  font-weight: $regular;
  @include font(15, 25);

  @include bp('mobile') {
    @include font(13, 19);
  }
}

.m-footer__contacts__phone {
  font-size: px2em(20);
  line-height: px2em(27, 20);
  margin-bottom: 2px;
  font-weight: $regular;

  a {
    color: #fff;
  }

  @include bp('mobile') {
    @include font(17, 23);
  }

  @include bp('print') {
    font-size: 1em;
  }
}

.m-footer__contacts__email {
  color: $blue-light;
  font-weight: $regular;
  display: inline-block;
  @include font(15, 25);

  @include bp('mobile') {
    @include font(13, 16);
  }
}

%social__trigger {
  @include icon('\EA1A');
  height: 21px;
  width: 26px;
  float: left;
  display: block;

  &:before {
    color: $blue-dark;
    height: 15px;
    width: 19px;
  }

  &:hover {
    &:before {
      @include effect(slowpulse, 0.8s);
    }
  }
}

.m-footer__socials {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 30px;

  @include bp('tablet') {
    margin-bottom: 25px;
  }

  @include bp('mobile') {
    margin-bottom: 18px;
  }

  @include bp('print') {
    display: none;
  }

  > div a {
    margin-right: 8px;

    @include bp('mobile') {
      margin-right: 6px;
    }
  }
}

.m-footer__socials_twitter {
  a {
    @extend %social__trigger;

    &:before {
      content: $icon-twitter;
      color: $white;
      transition: color 0.5s ease;
      font-size: 20px;
      top: 8px;

      @include bp('mobile') {
        font-size: 16px;
      }
    }
  }

  &:hover {
    a {
      &:before {
        color: $white;
      }
    }
  }
}

// .m-footer__socials_facebook {
//   a {
//     @extend %social__trigger;

//     &:before {
//       content: '\EA1A';
//       color: $facebook;
//       transition: color 0.5s ease;
//       font-size: 22px;
//       top: 5px;

//       @include bp('mobile') {
//         font-size: 18px;
//       }
//     }
//   }

//   &:hover {
//     a {
//       &:before {
//         color: $white;
//       }
//     }
//   }
// }

.m-footer__socials_bluesky {
  a {
    @extend %social__trigger;

    &:before {
      content: $icon-bluesky;
      color: $bluesky;
      transition: color 0.5s ease;
      font-size: 21px;
      top: 8px;

      @include bp('mobile') {
        font-size: 18px;
      }
    }
  }

  &:hover {
    a {
      &:before {
        color: $white;
      }
    }
  }
}

.m-footer__socials_linkedin {
  a {
    @extend %social__trigger;

    &:before {
      content: $icon-linkedin;
      color: $linkedin;
      transition: color 0.5s ease;
      font-size: 20px;
      top: 7px;

      @include bp('mobile') {
        font-size: 16px;
      }
    }
  }

  &:hover {
    a {
      &:before {
        color: $white;
      }
    }
  }
}

.l-footer {
  .innerWrapper {
    position: relative;
    padding-bottom: 20px;

    @include bp('print') {
      width: 100%;
      padding: 30px 0 0;
    }
  }
}

.m-footer__back-to-top {
  position: absolute;
  right: 0;
  bottom: 100%;
  text-transform: uppercase;
  background: $blue-dark;

  @include bp('print') {
    display: none;
  }

  a {
    @include icon($icon-arrow-up, $blue-light);
    font-size: px2em(14);
    line-height: px2em(14, 14);
    display: block;
    color: $blue-light;
    text-decoration: none;
    padding: px2em(14, 14) px2em(18, 14) 0 px2em(40, 14);
    font-weight: 600;

    @include bp('mobile') {
      padding: px2em(10, 14) px2em(10, 14) 0 px2em(35, 14);
    }

    &:before {
      top: px2em(14, 21);
      left: px2em(16, 21);
      font-size: px2em(21, 15);
      line-height: px2em(21, 21);

      @include bp('mobile') {
        top: px2em(10, 21);
        left: px2em(11, 21);
      }
    }

    &:hover {
      @include icon($icon-arrow-up, $white);

      color: $white;

      &:before {
        top: px2em(14, 21);
        left: px2em(16, 21);
        font-size: px2em(21, 15);
        line-height: px2em(21, 21);

        @include bp('mobile') {
          top: px2em(10, 21);
          left: px2em(11, 21);
        }
      }
    }
  }
}

.m-footer__links {

  @include bp('mobile') {
    margin-bottom: 10px;
  }
}

.m-footer__newsletter {
  @extend %button;
}

.m-footer__about {
  font-size: px2em(15, 16);
  font-weight: $semi-bold;
  line-height: 1.55;
  color: $white;
  margin-left: 25px;
}
