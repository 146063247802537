.m-form--webform--results {
    @extend %table;

    table + table {
        margin-top: 40px;
    }

    table {
        font-size: px2em(12);

        thead {
            tr {
                th {
                    width: auto;
                    font-size: 1em;

                    &:first-child {
                        width: 40px;
                    }
                }
            }
        }

        tr {
            td {
                width: auto;

                &:first-child {
                    width: 40px;
                }
            }
        }
    }
}
