@function px2em($font_size, $base_font_size: 16) {
  @return ($font_size / $base_font_size) + em;
}

@mixin font($font-size, $line-height:$base-line-height, $base:$base_font) {
  font-size: px2em($font-size, $base);
  line-height: px2em($line-height, $font-size);
}

@mixin bp($point) {
  @if $point == desktop {
    @media #{$bp-desktop} {
      @content;
    }
  } @else if $point == bigdesktop {
    @media #{$bp-bigdesktop} {
      @content;
    }
  } @else if $point == smalldesktop {
    @media #{$bp-smalldesktop} {
      @content;
    }
  } @else if $point == tablet {
    @media #{$bp-tablet} {
      @content;
    }
  } @else if $point == mobile {
    @media #{$bp-mobile}  {
      @content;
    }
  } @else if $point == tabletmobile {
    @media #{$bp-mobile} , screen and #{$bp-tablet} {
      @content;
    }
  } @else if $point == print {
    @media print {
      @content;
    }
  }
}

@mixin icon($content, $color:$white) {
  position: relative;

  &:before {
    content: $content;
    font-family: "icons";
    font-size: 1em;
    line-height: 140%;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    text-align: center;
    color: $color;
    text-indent: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
}

@mixin clearfix {
  zoom: 1;
  &:before, &:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  &:after {
    clear: both;
  }
}

%ul {
  padding: 0;
  margin: 0;
}

%list_item {
  list-style-type: none;
  list-style-image: none;
  padding: 0;
  margin: 0;
}

@mixin fullimage {
  img {
    width: 100%;
    height: auto;
  }
}

@mixin shadow($color, $opacity) {
  -webkit-box-shadow: 0px 5px 7px 0px rgba($color, $opacity);
  -moz-box-shadow: 0px 5px 7px 0px rgba($color, $opacity);
  box-shadow: 0px 5px 7px 0px rgba($color, $opacity);
}

@mixin textshadow($color, $opacity) {
  -webkit-text-shadow: 0px 5px 7px rgba($color, $opacity);
  -moz-text-shadow: 0px 5px 7px rgba($color, $opacity);
  text-shadow: 0px 5px 7px rgba($color, $opacity);
}

@mixin shadow_mobile($color, $opacity) {
  -webkit-box-shadow: 0px 5px 7px 0px rgba($color, $opacity);
  -moz-box-shadow: 0px 5px 7px 0px rgba($color, $opacity);
  box-shadow: 0px 5px 7px 0px rgba($color, $opacity);
}

%listen_styled_item {
  @extend %list_item;
  list-style-position: inside;
  margin-bottom: px2em(14);
  line-height: px2em(21);
  position: relative;

  &:before {
    position: absolute;
    left: -1em;
    top: 0.42em;
    display: block;
    background-color: $blue-light;
    line-height: 200%;
    content: '';
    border-radius: 50%;
    width: 0.35em;
    height: 0.35em;
  }
}

%listen_styled {
  ul, ol {
    @extend %ul;
    margin-left: px2em(28);
  }

  li {
    @extend %listen_styled_item;
  }
}

%td {
  padding: 12px 10px;
  text-align: left;
  font-size: px2em(16);
  line-height: px2em(21, 16);
  border-spacing: 0;
  border-collapse: collapse;
  vertical-align: top;
  border: 0;

  * {
    @include font1();

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-size: px2em(17, 17);
    text-decoration: underline;
    color: $blue-light;
  }

  &:nth-child(2) {
    border-left: 1px solid $grey3;
  }

  @include bp('tabletmobile') {
    font-size: px2em(15);
    line-height: px2em(18, 15);
    padding: px2em(4) px2em(10);

    &:first-of-type {
      padding-left: 5px;
    }

    &:last-of-type {
      padding-bottom: px2em(6);
    }
  }

  ul {
    li:last-child {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

%table {
  table {
    margin-bottom: px2em(20);
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;

    caption {
      font-size: px2em(17);
      text-transform: uppercase;
      text-align: left;
    }

    thead {
      background-color: transparent;
      border: none;

      tr {
        background-color: transparent;
      }

      th {
        @extend %td;
        color: $text2;
        font-weight: $bold;
        font-size: px2em(15);
        line-height: px2em(20, 15);
      }
    }

    tbody {
      tr {
        &:nth-child(2n+1) {
          background-color: rgba($blue-light, 0.11);
        }
      }
    }

    td {
      @extend %td;
    }

    tr + tr {
      margin-top: px2em(20);
    }
  }

  table + table {
    margin-top: 60px;
  }
}

@mixin content_formater {

  ul, ol, p {
    @include font2();
    font-size: px2em(16);
    line-height: px2em(25, 16);
    margin: 0 0 px2em(20);
  }

  > :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  ul, ol {
    padding-left: 1.1em;

    li {
      margin: 0 0 1em;
    }

    a {
      font-size: px2em(16);
      line-height: px2em(25, 16);
    }
  }

  a {
    @include font2();
    font-size: 1em;
    font-weight: inherit;

    &.lexicon-term {
      color: $text1;
      border-bottom: 1px dotted $text1;

      &:hover {
        color: $text1;
      }
    }
  }

  ul, ol, p {
    a {
      color: $blue-light;

      &:hover {
        color: $blue-dark;
      }
    }
  }

  em {
    font-style: italic;
  }

  > ul, > ol {
    @extend %ul;
    margin-left: px2em(30);
  }

  > ul, > ol {
    padding-left: 31px;

    > li {
      @extend %listen_styled_item;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @include font2();
    font-size: px2em(20);
    line-height: px2em(26, 20);
    font-weight: $bold;
    margin: 0 0 1em;
  }

  h3 {
    margin-top: 2em;
  }

  iframe {
    width: 100%;
    height: px2em(400);
  }

  img {
    margin-bottom: 12px;

    &[data-align="left"] {
      float: left;
      width: 300px;
      margin-right: 12px;

      @include bp('mobile') {
        width: 100%;
        margin-right: 0;
      }
    }

    &[data-align="right"] {
      float: right;
      width: 300px;
      margin-left: 12px;

      @include bp('mobile') {
        width: 100%;
        margin-left: 0;
      }
    }

    &[data-caption]:not([data-caption=""]) {
      & + .m-pgimage__description {
        margin-top: 0;
        margin-bottom: 12px;
      }
    }
  }

  @extend %table;
}

@mixin member_icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 10px;

  @include bp('mobile') {
    width: 15px;
    height: 15px;
    left: 10px;
  }
}

@mixin headline_flag($link) {
  margin: 0 auto;
  background-color: $blue-light;
  text-transform: uppercase;
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -13px;
    background: $blue-light;
    padding-right: 23px;
    clip-path: rect(0, 0, 23px, 100%);
    transform: skewX(-11deg);
  }

  @if $link == true {
    a {
      @include icon('\EA01');
      padding-left: 52px;

      &:before {
        font-size: 38px;
        line-height: 120%;
        top: 5px;

        @include bp('tablet') {
          font-size: 20px;
        }
      }
    }
    @extend %data_icons_with_link;
  }

  @if $link != true {
    @include icon('\EA01');
    @extend %data_icons;

    &:before {
      font-size: 38px;
      line-height: 120%;
      top: 5px;
      left: 45px;

      @include bp('tablet') {
        top: 3px;
        left: 23px;
        font-size: 20px;
      }
    }
  }

  a, span {
    @include font1();
    padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 16px;
    color: $white;
    font-size: px2em(18, 30);
    line-height: px2em(24, 18);
    font-weight: $semi-bold;
    display: block;

    @include bp('tablet') {
      font-size: px2em(15, 30);
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

%transitionAll {
  transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out;
}

%twitter_link {
  color: $blue-light;
  @include font1;
  font-size: px2em(15);
  line-height: px2em(20);
  font-weight: $regular;
}

%tinymce-styles {
  span.highlight,
  p.highlight {
    color: #DA3535;
    font-style: italic;
  }

  img.image-large {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 1.5em auto 1em;
    border: 4px solid #EEEFF2;
  }

  img.image-left,
  a.image-left,
  p.image-left img {
    float: left;
    margin: .4em 1.5em .4em 0;
  }

  figure {
    .image-left {
      margin: 0;
    }
    a,
    img,
    span {
      display: block;
    }
    a {
      display: inline-block;
    }
    img {
      width: 100%;
      height: auto;
    }
    span {
      float: left;
      width: 100%;
      padding: .5em 0;
      color: #aeaeae;
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      font-size: .85em;
      line-height: 1.5em;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
