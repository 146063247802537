.m-webform__userlogin {
  display: flex;

  > div {
    margin: 100px auto 50px;
  }

  .form-item {
    margin-bottom: 1em;
  }

  label {
    display: block;
    font-weight: bold;
  }

  .description {
    display: none;
  }

  input[type="submit"] {
    @extend %form_submit;

    padding: .5em 2em;
  }

  input,
  input[type="submit"] {
    border-radius: 5px;
  }
}
