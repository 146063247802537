.m-globalauthor {
  display: contents;

  a {
    display: inline-block;
    font-size: inherit;
    line-height: inherit;

    &:hover {
      color: $blue-light;
    }
  }
}

.m-globalauthor--name + .m-globalauthor--name {
  &:before {
    content: ',';
    padding-right: 4px;
  }
}