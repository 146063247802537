.m-paragraph__textimage--big {
  @extend %m-paragraph__textimage;
}

.m-paragraph--textimage--big__title {
  @extend %m-paragraph--textimage__title;
}

.m-paragraph--textimage--big__inner {
  @extend %m-paragraph--textimage__inner;
}

.m-paragraph--textimage--big__text {
  @extend %m-paragraph--textimage__text;
  display: block;
}

.m-paragraph--textimage--big__image {
  @extend %m-paragraph--textimage__image;
  width: 50%;
  margin-bottom: 1em;

  @include bp('mobile') {
    width: 100%;
  }

  a {
    font-size: inherit;
    line-height: inherit;
  }
}
