.m-webform__simplenewsconfirmaddform,
.m-webform__simplenewsconfirmmultiform {
  > div {
    padding: 40px;
  }
  > div,
  p {
    @include font1;
    text-align: center;
  }
  .item-list {
    ul {
      display: inline-block;
      text-align: left;
      margin-top: 0;
    }
  }
  .form-actions {
    display: block;
    margin-top: 1em;
    input[type="submit"] {
      @extend %form_submit;
      background-color: $red-dark;
    }
    a {
      margin-left: 30px;
      color: $blue-light;
    }
  }
}
