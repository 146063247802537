%grid3 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items: stretch;

	@include bp('tablet') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

%grid3__trigger {
	margin-bottom: px2em(30);
	width: 30%;

	@include bp('tablet') {
		width: 48%;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		width: 100%;
		align-self: left;
	}
}

.l-grid--3__trigger__col3 {
	@include bp('tablet') {
		width: 100%;
	}
}

.l-grid--3__content {

}