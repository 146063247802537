.m-paragraph--newsroom-slider {
  margin-top: 150px !important;

  @include bp('tablet') {
    margin-top: 60px !important;
  }

  @include bp('mobile') {
    margin-top: 40px !important;
  }
}

.m-paragraph--newsroom-slider__inner {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  column-gap: 17px;
  row-gap: 50px;

  @include bp('mobile') {
    row-gap: 30px;
  }
}

.m-paragraph--newsroom-slider__content {
  width: 24%;
  padding-top: 65px;

  @include bp('bigdesktop') {
    width: 19%;
  }

  @include bp('tablet') {
    width: $tablet_width;
    margin: 0 auto;
    padding: 0;
  }

  @include bp('mobile') {
    width: $mobile_width;
  }
}

.m-paragraph--newsroom-slider__title {
  font-weight: $bold;
  font-size: px2em(26);
  line-height: px2em(39, 26);
  letter-spacing: normal;
  color: $blue-dark;
  margin: 0;
}

.m-paragraph--newsroom-slider__teaser {
  font-size: px2em(17);
  line-height: 1.55;
  margin-top: 20px;
}

.m-paragraph--newsroom-slider__links {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 35px;

  @include bp('mobile') {
    margin-top: 30px;
  }
}

.m-paragraph--newsroom-slider__link {
  @extend %button;
}

.m-paragraph--newsroom-slider__view {
  position: relative;
  width: 67%;

  @include bp('bigdesktop') {
    width: 72%;
  }

  @include bp('tablet') {
    width: 100%;
  }
}
