%grid_infoboxes {

	@include bp('tablet') {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		justify-content: space-between;
		align-items: stretch;
	}

	@include bp('mobile') {
		display: block;
	}
}

%grid_infoboxes__trigger {
	margin-bottom: px2em(30);
	width: 100%;
	flex-shrink: 1;

	@include bp('tablet') {
		width: 48%;
	}

	@include bp('mobile') {
		width: 100%;
	}
}