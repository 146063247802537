.m-expertfacts {
  position: relative;

  @include clearfix;
}

.m-expertfacts__trigger {
  float: left;
  width: 50%;
  background-color: $blue-dark;
  &:last-child {
    background-color: $blue-light;
    .m-expertfact__content {
      left: -100%;
      @include bp('mobile') {
        left: inherit;
      }
    }
  }
  @include bp('mobile') {
    width: 100%;
  }
}

.m-expertfact__title {
  $height: 43px;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  padding: 41px 30px 42px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include bp('tablet') {
    padding: 31px 20px 32px;
  }

  a {
    color: rgba($grey2, 0.75);
    @include font1();
    font-size: px2em(30);
    font-weight: $semi-bold;
    line-height: px2em(43, 30);
    text-align: center;
    @include icon($icon-arrow-down);
    margin: 0 auto;
    height: 100%;
    padding: 0 53px 0 0;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;

    &:before {
      left: auto;
      right: 0;
      top: calc(50% - 21px);
      line-height: 43px;
      color: rgba($grey2, 0.75);
      font-size: 35px;

      @include bp('mobile') {
        font-size: 25px;
      }
    }

    @include bp('tablet') {
      font-size: px2em(25);
      line-height: px2em(30, 25);
    }

    @include bp('mobile') {
      font-size: px2em(20, 14);
      line-height: px2em(25, 20);
      display: block;
      text-align: left;
      padding-right: 35%;
    }
  }

  @include bp('mobile') {
    padding: 16px 25px 15px;
    width: 100%;
    position: relative;
    float: none;
    text-align: left;
  }
}

.m-expertfact__content {
  display: none;
  width: 200%;
  background-color: $blue-dark;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 25px;

  @include bp('tablet') {
    padding: 40px 20px 10px;
  }

  @include bp('mobile') {
    width: 100%;
    padding-top: 10px;
  }
}

.m-expertfacts__trigger:last-of-type {
  .m-expertfact__title, .m-expertfact__content {
    background-color: $blue-light;
  }
}

//STATES
.m-expertfacts {
  &.#{$isopend} {
    .m-expertfacts__trigger {
      &.#{$isactive} {
        .m-expertfact__title {
          a {
            &:before {
              content: '\EA04';
            }
          }
        }

        .m-expertfact__content {
          display: block;
        }
      }
    }
  }
}
