.m-paragraph--table {
  @extend %paragraph;
}

.m-paragraph--table__title {
  @extend %paragraphs_title;
}

.m-paragraph--table__rows {
  @extend %table;
}
