.m-node--page {
  @extend %node;
  margin-top: 40px;
}

.m-node--page__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 48px;

  @include node_icon_icon(56px);
  @extend %data_icons;
}

.m-node--page__printbar {
  @extend %node_printbar;
  right: 0;
  top: -30px;
}

.m-node--page__socials {
  position: absolute;
  right: 80px;
  top: -37px;
  padding-right: 10px;

  &:after {
    position: absolute;
    right: 0;
    top: 20%;
    bottom: 10%;
    content: '';
    border-right: 1px solid $grey3;
  }

  .m-socialmedia-share {
    text-align: right;
  }
}

.m-node--page__title {
  @extend %node_title;
  text-align: left;
  margin-bottom: 23px;
}

.m-node--page__cover {
  @extend %node_cover;
  @extend %image_caption;
}

.m-node--page__topline {
  margin-bottom: 54px;

  > div {
    display: inline-block;
    color: $text1;
    font-size: px2em(17);
    line-height: px2em(23, 17);

    a {
      color: $text1;
    }

    & + div {
      &:before {
        content: ' | ';
        display: inline-block;
      }
      > div {
        display: inline-block;
      }
    }
  }
}

.m-node--page__teaser {
  @extend %node_teaser;
  text-align: left;
}

.m-node--page__paragraphs {
  margin-bottom: 80px;

  @include node_ctypes {

  }

  > .paragraphs-items > .field > .field-items > .field-item > div {
    width: auto;
  }
}

.m-node--page__contactform__links {
  @extend .m-node--event__application-form__links;
}

.m-node--page__sidebarelement {
  @extend %node_sidebarelement;
}
