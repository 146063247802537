$padding: 45px;

.m-node--page--teaserbox {
  position: relative;
  height: 100%;
}

.m-node--page--teaserbox__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}


.m-node--page--teaserbox__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 26px;
  margin-left: $teaser_box_padding * -1;
  font-size: 1em;

  a {
    @include ci('page');
  }

  @include bp('mobile') {
    margin-left: $mobile_diff;
  }
}

.m-node--page--teaserbox__inner {
  @extend %node_teaser_box_inner;
  background-color: rgba($blue-dark, $bg_opacity);
}

.m-node--page--teaserbox__title {
  font-size: 1em;

  a {
    @extend %node_title;
    text-align: left;
    margin-top: 0;
    font-size: px2em(19);
    font-weight: $bold;
    line-height: px2em(27, 19);
    color: $white;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

.m-node--page--teaserbox__date {
  @extend %node_date;
  text-align: left;

  @include bp('tablet') {
    margin-bottom: 0;
  }

  > div {
    display: inline-block;
    color: $white;

    a {
      color: $white;
    }

    & + div {
      &:before {
        content: ' | ';
        display: inline-block;
      }
      > div {
        display: inline-block;
      }
    }
  }
}

.m-node--page--teaserbox__subtitle {
  @extend %node_subtitle;
  color: $white;
  text-align: left;
}



.field-name-field-manual-reference {
  @extend %node_teaser_box_inner;
  background-color: rgba($blue-dark, $bg_opacity);

  box-sizing: border-box;
  width: 311px;
  height: 311px;
  float: left;
  margin-top: -23px;
  margin-bottom: -23px;

  a {
    @extend %node_title;
    text-align: left;
    margin-top: 0;
    font-size: px2em(19);
    font-weight: $bold;
    line-height: px2em(27, 19);
    color: $white;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}
