.m-paragraph--newsletter {
  margin-top: 74px !important;
  position: relative;

  @include bp('tablet') {
    margin-top: 44px !important;
  }
}

.m-paragraph--newsletter__inner {
  @include icon('\EA0F');

  width: 90%;
  max-width: 1577px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 70px 0;
  background-color: $blue-dark;
  background-image: url('../../img/newsletter_airplane.svg');
  background-repeat: no-repeat;
  background-size: auto 780px;
  background-position: top -2px right -29px;

  @include bp('mobile') {
    padding: 50px 0;
  }

  .m-paragraph--newsletter.is-top & {
    background-attachment: fixed;
  }

  &:before {
    color: rgba($blue-light, 0.6);
    font-size: 205px;
    line-height: 165px;
    top: 18px;
    left: 93px;

    @include bp('smalldesktop') {
      left: 22px;
    }

    @include bp('tablet') {
      font-size: 155px;
      line-height: 131px;
      left: 22px;
    }

    @include bp('mobile') {
      font-size: 105px;
      line-height: 88px;
      left: 18px;
    }
  }

  @include bp('mobile') {
    width: $mobile_width;
  }
}

.m-paragraph--newsletter__content {
  position: relative;
  z-index: 2;
  width: 50%;
  margin: 0 auto;
  text-align: center;

  @include bp('mobile') {
    width: 80%;
  }
}

.m-paragraph--newsletter__title {
  @include font2();
  font-size: px2em(26);
  line-height: px2em(39, 26);
  font-weight: $bold;
  letter-spacing: normal;
  margin: 0;
  color: $white;
}

.m-paragraph--newsletter__teaser {
  font-size: px2em(17);
  line-height: 1.55;
  margin-top: 20px;
  color: $white;
}

.m-paragraph--newsletter__link {
  margin-top: 35px;

  a {
    @extend %button--white;
  }
}
