.region-overlay {
  &.is-initial {
    position: initial;
  }
}

.m-clew-survey-overlay__wrapper {
	position: fixed;
	left: 50%;
	top: 50%;
	z-index: 1100;
  display: none;

  @include bp('mobile') {
    top: 220px;
  }
}

.m-clew-survey-overlay {
	margin-left: -294px;
	margin-top: -294px;
	background-color: $green-medium;
	color: $white;
	width: 588px;
	height: 588px;
	border-radius: 294px 294px 294px 294px;
	-moz-border-radius: 294px 294px 294px 294px;
	-webkit-border-radius: 294px 294px 294px 294px;
	border: 0px solid $white;
	z-index: 1000;
	position: relative;
	padding: 70px 0;
  overflow: hidden;
  box-sizing: border-box;

  @include bp('mobile') {
		margin-top: -70px;
		width: 330px;
		height: 330px;
		border-radius: 165px 165px 165px 165px;
		-moz-border-radius: 165px 165px 165px 165px;
		-webkit-border-radius: 165px 165px 165px 165px;
		margin-left: -165px;
		padding: 28px 0 30px 0;
  }
}

.m-clew-survey-overlay__title {
  @include icon($icon-news);
  padding: 26px 10px 0 198px;
  background-color: $green-dark;
  display: inline-block;
  position: relative;
  font-size: 2em;
  font-weight: $light;
  margin: 0;
  height: 60px;
  color: $white;

  @include bp('mobile') {
    font-size: px2em(16);
    padding-left: 94px;
    padding-top: 15px;
    height: 35px;
  }

  &:before {
    font-size: 1.7em;
    line-height: 1em;
    position: absolute;
    top: 15px;
    left: 127px;

    @include bp('mobile') {
      font-size: 1.4em;
      left: 60px;
      top: 14px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    border-left: 0.5em solid $green-dark;
    border-bottom: 2.7em solid transparent;

    @include bp('mobile') {
      border-bottom: 3.1em solid transparent;
    }
  }
}

.m-clew-survey-overlay__content {
  padding: 34px 127px;
  font-size: px2em(20);
  line-height: px2em(30, 20);

  @include bp('mobile') {
    padding: 11px 32px 24px 60px;
    font-size: px2em(14);
    line-height: px2em(20, 14);
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }

    @include bp('mobile') {
      margin-bottom: 0.6em;
    }
  }

  a {
    @include icon($icon-arrow-up);
    color: $white;
    font-weight: $semi-bold;
    position: relative;
    display: inline-block;
    padding-right: 26px;
    font-size: px2em(20, 20);

    &:hover {
      color: $white;

      &:before {
        color: $white;
      }
    }

    @include bp('mobile') {
      padding-right: 20px;
    }

    &:before {
      transform: rotate(90deg);
      position: absolute;
      right: 0;
      top: 5px;
      left: auto;
      color: $white;
      line-height: 1.2em;

      @include bp('mobile') {
        top: 3px;
      }
    }
  }
}

.m-clew-survey-overlay__close {
	width: 50px;
	height: 50px;
	background-color: $green-dark;
	background-image: url('../../img/big-close.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto 24px;
	position: absolute;
	top: -224px;
  right: -45px;
	cursor: pointer;
  z-index: 1001;

  @include bp('mobile') {
		top: -67px;
		right: 0px;
		width: 35px;
		height: 35px;
		background-size: auto 17px;
  }
}

.m-clew-survey-overlay__darken {
	position: fixed;
	background-color: #000;
	top: -100px;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
	filter: alpha(opacity=60);
	-moz-opacity: 0.6;
	-khtml-opacity: 0.6;
	opacity: 0.6;
	display: none;
}
