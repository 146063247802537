.m-node--landingpageguide {

}

.m-node--landingpageguide__inner {
  padding-top: 101px;
  position: relative;

  @include bp('tablet') {
    padding-top: 80px;
  }

  @include bp('mobile') {
    padding-top: 50px;
  }

  @include bp('print') {
    padding-top: 0;
  }
}

.m-node--landingpageguide__type {
  @extend .m-node--dossier__type;

  a::before,
  span::before {
    content: $icon-pin;
  }
}

.m-node--landingpageguide__printbar {
  @extend %node_printbar;

  top: 35px;

  @include bp('tablet') {
    top: 25px;
  }
}

.m-node--landingpageguide__title {
  @extend %node_title;

  font-size: px2em(75);
  line-height: 1.25;
  color: $blue-light;
  margin: 0 auto 50px;
  width: 83%;

  @include bp('tablet') {
    font-size: px2em(45);
    margin-bottom: 30px;
    width: 90%;
  }

  @include bp('mobile') {
    font-size: px2em(30);
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
  }

  @include bp('print') {
    width: 100%;
  }
}

.m-node--landingpageguide__teaser {
  @extend %node_teaser;

  font-size: px2em(16);
  line-height: 1.55;
  margin: 0 auto 60px;
  width: 74%;

  @include bp('tablet') {
    margin-bottom: 50px;
    width: 90%;
  }

  @include bp('mobile') {
    font-size: px2em(15);
    line-height: px2em(21, 15);
    text-align: center;
    width: 100%;
  }

  @include bp('print') {
    width: 100%;
  }
}

.m-node--landingpageguide__ctypes {
  @include node_ctypes {
    &.m-paragraph--country,
    &.m-paragraph--newsletter {
      @extend %page_full_field;
    }
  }

  > .paragraphs-items {
    padding-top: 20px;

    > .field > .field-items > .field-item + .field-item {
      margin-top: 120px;

      @include bp('tablet') {
        margin-top: 100px;
      }

      @include bp('mobile') {
        margin-top: 70px;
      }
    }
  }

  @include bp('mobile') {
    font-size: px2em(14);
    line-height: px2em(21, 14);
  }
}

.m-node--landingpageguide__content {

  .m-paragraph--referencedcontent {
    flex-direction: column;

    > div {
      padding-top: 56px;

      @include bp('mobile') {
        padding-top: 30px;
      }
    }
  }
}

.m-node--landingpageguide__bgimage_title {
  @extend .m-node--dossier__bgimage_title;
}
