%grid13_23 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items: stretch;

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	@include bp('tablet') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

%grid13_23__trigger {
	flex-shrink: 1;

	&:first-of-type {
		width: 31%;
	}

	&:last-of-type {
		width: 68.9%;
	}
}
