.m-sidebars--contact {
  @extend %sidebars_lightblue;
  position: relative;
  font-size: px2em(14);
  line-height: px2em(21, 14);
  margin-bottom: 60px;

  a {
    color: $white;
    font-size: 1em;
    line-height: px2em(19, 14);
  }
}

.m-sidebars--contact__title {
  @extend %teaserlistgrid__type;
  background-color: $blue-medium;
  font-size: 1em;
  padding-right: 15px;
  padding-left: 75px;
  top: 32px;

  &:before {
    top: 3px;
    left: 27px;
    content: $icon-ask-clew;
  }

  span {
    font-size: px2em(15, 14);
    line-height: px2em(20, 15);
  }
}

.m-sidebars--contact__content {
  padding-top: 66px;
}

.m-sidebars--contact__text {
  &:after {
    margin-top: 19px;
    margin-bottom: 9px;
    display: block;
    content: '';
    width: 47px;
    height: 1px;
    background-color: $white;
  }
}

.m-sidebars--contact__address {
  p {
    margin: 0;
  }
}

.m-sidebars--contact__email {
  margin-top: 1em;

  a {
    font-weight: $semi-bold;
  }
}

.m-sidebars--contact__phone {
  font-weight: $semi-bold;
}
