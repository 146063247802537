.m-paragraph__textonly {
  @extend %paragraph;

  @media print {
    a:after{
      content:" (" attr(href) ") ";
      font-size: 1em;
      font-weight: normal;
      color: #aaa;
    }
  }
}

.m-paragraph--textonly__title {
  @extend %paragraphs_title;
}

.m-paragraph--textonly__text {
  @extend %paragraph_text;
}
