$border_color: $white;

%grid23_13 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items: stretch;

	@include bp('tablet') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

}

%grid23_13__trigger {
	flex-shrink: 1;

	&:first-of-type {
		width: 71.5%;
	}

	&:last-of-type {
		width: 28.5%;
	}

	@include bp('tablet') {
		&:first-of-type {
			width: 100%;
			padding-bottom: px2em(20);
			border-bottom: 1px solid $border_color;
		}

		&:last-of-type {
			width: 31%;
			margin-top: px2em(20);
		}
	}

	@include bp('mobile') {

	}
}