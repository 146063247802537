%paragraph {
  margin-bottom: 0;

  @include bp('print') {
    width: 100%;

    a:after{
      content:" (" attr(href) ") ";
      font-size: 1em;
      font-weight: normal;
      color: #aaa;
    }
  }
}

%paragraphs_title {
  @include font2();
  font-size: px2em(20);
  font-weight: $bold;
  line-height: px2em(25, 20);
  margin-bottom: 23px;

  a {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    &:hover {
      color: $blue-light;
    }
  }

  @include bp('mobile') {
    font-size: px2em(16, 14);
    line-height: px2em(21, 16);
    margin-bottom: 15px;
  }
}

%paragraph_text {
  @include content_formater();

  @include bp('print') {
    a:after{
      content:" (" attr(href) ") ";
      font-size: 1em;
      font-weight: normal;
      color: #aaa;
    }
  }
}

@import "_m-paragraph--image";
@import "m-paragraph--textimage/_m-paragraph--textimage";
@import "_m-paragraph--textonly";
@import "_m-paragraph--table";
@import "_m-paragraph--table--blogpost";
@import "_m-paragraph--quote";
@import "_m-paragraph--quote--blogpost";
@import "_m-paragraph--faq";
@import "_m-paragraph--infobox";
@import "_m-paragraph--referencedcontent";
@import "_m-paragraph--teaserbox";
@import "_m-paragraph--morebox";
@import "_m-paragraph--featuredreference";
@import "_m-paragraph--live-blog";
@import "_m-paragraph--embeded-html";
@import "_m-paragraph--newsletter";
@import "_m-paragraph--guides";
@import "_m-paragraph--toolbox";
@import "_m-paragraph--newsroom-slider";
@import "_m-paragraph--network";
@import "_m-paragraph--events-trainings";
@import "_m-paragraph--country";
