@charset "utf-8";

// Resources
@import "_resources/variables";
// Base
@import "_base/normalize";
@import "_base/fonts";
@import "_base/breakpoints";
@import "_base/effects";
@import "_base/images";
@import "_base/presets";
@import "_base/presets_layout";
@import "_base/heading";
@import "_base/admin";
@import "_base/select2";
@import "_base/ctypes";
// Resources
@import "_resources/_atomic/_atomics";
// Layout
@import "_layout/_layout";
@import "_layout/_l-header";
@import "_layout/_l-page";
@import "_layout/_l-footer";
@import "_layout/_l-content";
@import "_layout/grid/_l-grids";
@import "_layout/page/_l-pages";
//modules
@import "_modules/_m-global";
@import "_modules/colorbox";
@import "_modules/form/_m-form";
@import "_modules/menu/_m-mainmenu";
@import "_modules/menu/_m-servicemenu";
@import "_modules/menu/_m-menuitem";
@import "_modules/_m-search_block";
@import "_modules/_m-languageswitch";
// @import "_modules/_m-sociallinks";
@import "_modules/nodes/_m-node";
@import "_modules/paragraphs/m-paragraphs";
@import "_modules/_m-toc";
@import "_modules/views/_m-views";
@import "_modules/_m-printbar";
@import "_modules/_m-socialmedia-share";
@import "_modules/_m-expertfacts";
@import "_modules/_m-experts";
@import "_modules/_m-factsheets";
@import "_modules/_m-loadmore";
@import "_modules/_m-lexicon";
@import "_modules/_m-side_toolbar";
@import "_modules/search/_search";
@import "_modules/pg_topic_pages/pg_topic_pages";
@import "_modules/_m-footer";
@import "_modules/research-map/_m-research-map";
@import "_modules/_m-footer__footermenu";
@import "_modules/_m-globalauthor";
@import "_modules/sidebars/_m-sidebars";
@import "_modules/_m-colorbox__newsletter";
@import "_modules/_m-authorfull";
@import "_modules/_m-travel-log";
@import "_legacy/_tooltips";
@import "_modules/_m-pager";
@import "_modules/_m-ajax-background";
@import "_modules/_m-newsletter_webform_menu";
@import "_modules/_m-webform";
@import "_modules/_m-twitter_sharelines";
@import "_modules/_m-clew-survey";
@import "_legacy/_ask_clew";
@import "_modules/_m-pdfgen-experts";

@import "_modules/cleverreach/_cr";
@import "_legacy/cookiebot";
