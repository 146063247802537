%page {
  padding-top: $header-height;

  @include bp('tablet') {
    padding-top: $tablet-header-height;
  }

  @include bp('mobile') {
    padding-top: $mobile-header-height;
  }

  @include bp('print') {
    padding-top: 0;
  }

  > .innerWrapper {
    width: $page_width;

    @include bp('smalldesktop') {
      width: $smalldesktop_width;
    }

    @include bp('tablet') {
      width: $tablet_width;
    }

    @include bp('mobile') {
      width: $tablet_width;
    }

    @include bp('print') {
      width: 100%;
    }
  }
}

%page_center_field {
  width: 828px;
  margin: 0 auto;

  @include bp('tablet') {
    width: 90%;
  }

  @include bp('mobile') {
    width: 100%;
  }

  @include bp('print') {
    width: 100%;
  }
}

%page_center_field_small {
  @extend %page_center_field;
  width: 510px;

  @include bp('tablet') {
    width: 90%;
  }

  @include bp('mobile') {
    width: 100%;
  }

  @include bp('print') {
    width: 100%;
  }
}

%page_full_field {
  width: 100%;
  margin: 0 auto;
}

.l-page {
  @extend %page;
}
