.m-paragraph__textimage--default {
  @extend %m-paragraph__textimage;
}

.m-paragraph--textimage--default__title {
  @extend %m-paragraph--textimage__title;
}

.m-paragraph--textimage--default__inner {
  @extend %m-paragraph--textimage__inner;
}

.m-paragraph--textimage--default__text {
  @extend %m-paragraph--textimage__text;
}

.m-paragraph--textimage--default__image {
  @extend %m-paragraph--textimage__image;
  width: 30%;
}
