.m-paragraph--quote {
  &[data-type="blogpost"] {
    border-top-color: $green;
    border-bottom-color: $green;

    &:before {
      color: $green;
    }

    .m-paragraph--quote__quote {
      color: rgba($green, 0.38);
    }

    .m-paragraph--quote__quote--noimage {
      color: rgba($green, 0.38);
    }
  }
}
