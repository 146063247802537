$webform-label-width: 22%;
$webform-item-padding: 15px;

.webform-client-form {
    color: $text2;

    a {
        color: $blue-light;
    }

    .description {
        color: $text2;
        font-size: px2em(13);
        line-height: px2em(18, 13);
    }

    label {
        display: inline-block;
        font-style: italic;
        font-size: px2em(17);
        line-height: px2em(23, 17);
    }

    input[type=text],
    input[type=email],
    input[type=number] {
        @extend %form_input;

        padding: 10px 3%;
        background: $white;
        border: 1px solid $grey3;
        border-radius: 0;
        height: auto;
        font-size: px2em(17);
        line-height: px2em(23, 17);

        @include bp('mobile') {
            font-size: px2em(14);
            line-height: px2em(16, 14);
            padding: 8px 3%;
        }

        &:focus {
            border-color: $blue-light;
        }

        &.error {
            border-color: $red-light;
        }
    }

    input[type="checkbox"]:checked + label {
        &::before {
            right: auto;
            left: 0;
        }
    }

    input[type="checkbox"] + label {
        padding-right: 0;
        padding-left: 25px;

        &::after {
            right: auto;
            left: 0;
            top: 2px;
        }
    }

    textarea {
        @extend %form_input;

        background: $white;
        border: 1px solid $grey3;
        border-radius: 0;
        font-size: px2em(17);
        line-height: px2em(23, 17);
        height: auto;

        @include bp('mobile') {
            font-size: 1em;
            line-height: px2em(16, 14);
        }

        &:focus {
            border-color: $blue-light;
        }

        &.error {
            border-color: $red-light;
        }
    }

    .webform-component-textarea {
        label {
            color: $text2;
        }
    }

    .webform-component-textfield,
    .webform-component-select,
    .webform-component-email {
        position: relative;
        padding-bottom: $webform-item-padding;
        display: flex;
        align-items: center;

        @include bp('mobile') {
            display: block;
        }

        label {
            width: 20%;
            margin-right: 2%;
            color: $text2;

            @include bp('mobile') {
                width: 93%;
                margin-right: 0;
                font-size: px2em(14);
                line-height: px2em(18, 14);
            }
        }
        input {
            width: 71%;
            align-self: flex-start;

            @include bp('mobile') {
                width: 93%;
            }
        }
        select,
        .select2-container {
            width: 77%;
            align-self: flex-start;

            @include bp('mobile') {
                width: 93%;
            }
        }
    }

    .select2-container {
        &.error {
            background-color: transparent;
            background-image: none;
            border: 1px solid $red-light;
        }

        &.select2-container-multi {
            ul {
                border-color: $grey3;
                background-image: none;
                min-height: 0;
            }

            input {
                font-size: px2em(17, 30);
                line-height: px2em(23, 17);
            }

            .select2-choices {
                padding: 5px 5px 0 2px;

                .select2-search-choice {
                    font-size: px2em(15, 30);
                    line-height: px2em(18, 15);
                }
                .select2-search-field input {
                }
            }
        }
    }

    .webform-component-radios,
    .webform-component-textarea,
    .webform-component-checkboxes {
        padding-bottom: $webform-item-padding;
    }

    .webform-component-radios {
        .form-radios {
            display: flex;
            align-items: center;

            label {
                font-style: normal;
                font-size: px2em(16);
                line-height: px2em(26, 16);

                @include bp('mobile') {
                    font-size: px2em(14);
                    line-height: px2em(20, 14);
                }
            }

            .form-type-radio {
                padding-right: 15px;
            }

            input[type="radio"] {
                display: none;
            }

            input[type="radio"]:checked + label {
                &::before {
                    content: '\EA1F';
                    font-family: "icons";
                    line-height: 140%;
                    speak: none;
                    display: inline-block;
                    text-decoration: inherit;
                    font-variant: normal;
                    text-transform: none;
                    text-align: center;
                    color: $blue-light;
                    text-indent: 0;
                    font-size: 16px;
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: -3px;
                    z-index: 2;
                }
            }

            input[type="radio"] + label {
                position: relative;
                padding-left: 25px;

                &::after {
                    content: '';
                    background-color: white;
                    border: 1px solid $grey4;
                    border-top: 1px solid $grey4;
                    border-radius: 3px;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 2px;

                    -moz-box-shadow: inset 0 0 2px rgba($black, 0.4);
                    -webkit-box-shadow: inset 0 0 2px rgba($black, 0.4);
                    box-shadow: inset 0 0 2px rgba($black, 0.4);
                }
            }
        }
    }

    .webform-component-checkboxes {
        label {
            font-style: normal;
            font-size: 1em;
            line-height: px2em(23, 16);

            @include bp('mobile') {
                font-size: px2em(14);
                line-height: px2em(20, 14);
            }
        }

        div.error {
            background-image: none;
            background-color: transparent;
            border: 0;
            color: $red-light;
        }
    }

    // .webform-component-checkboxes .description,
    // .webform-component-markup p,
    // .webform-component-markup {
    //     font-size: px2em(17, 16);
    //     line-height: px2em(23, 17);
    //     font-weight: $light;
    // }

    .webform-component .description {
        font-weight: $light;
    }

    .webform-component-markup h3 {
        margin-bottom: 0.1em;
        font-size: px2em(16);
        line-height: px2em(26, 16);
        font-weight: $semi-bold;
    }

    .webform-component-managed_file {
        @include clearfix;
        padding-bottom: $webform-item-padding;

        label {
            width: 20%;
            margin-right: 2%;
            float: left;
            color: $text2;

            @include bp('mobile') {
                width: 100%;
                margin-right: 0;
                float: none;
                font-size: px2em(14);
                line-height: px2em(18, 14);
            }
        }

        .form-managed-file {
            width: 71%;
            float: left;

            @include bp('mobile') {
                width: 93%;
                float: none;
            }

            .file {
                padding-right: 10px;
            }
            .form-file {
                float: left;

                @include bp('mobile') {
                    font-size: px2em(14);
                    line-height: px2em(18, 14);
                }
            }
            input[type=submit] {
                padding: 0.3em 1em;
                background-color: $red-light;

                &:hover {
                    background-color: $red-dark;
                }
            }
        }
        .description {
            clear: both;
            padding-left: $webform-label-width;

            @include bp('mobile') {
                padding-left: 0;
                padding-top: 7px;
            }
        }
    }

    .webform-container-inline {
        .select2-display-none {
            display: none;
        }
    }

    #webform-component-informed {
        .form-type-checkbox {
            padding-bottom: 10px;
        }
     }

    input[type=submit] {
        @extend %form_submit;
        padding-left: 20px;
        padding-right: 20px;

        @include bp('mobile') {
            font-size: px2em(14);
            line-height: px2em(18, 14);
            height: 29px;
        }
    }
}

.m-paragraph--contactform .messages,
.m-node--webform .messages,
.m-node--webform__messages {
    clear: both;
    padding: 0 0 10px 0;
    border: 0;

    &.status {
        background-image: none;
        background-color: transparent;
        color: green;
    }
    &.error {
        background-image: none;
        background-color: transparent;
        color: $red-light;
    }

    .messages  {
        background-color: transparent;
        background-image: none;
        padding: 0;
        margin: 0;
        border: 0;

        &.error {
            color: $red-light;
        }

        ul {
            list-style: none;
            margin: 0;
        }
    }
}

.m-node--webform .messages {
    margin-bottom: 0;
    padding-bottom: 0;
}
