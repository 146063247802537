.l-page--webform {
  @extend %page;
}

.l-page--webform__bgimage {
  height: 802px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include bp('tablet') {
    height: 500px;
  }

  @include bp('mobile') {
    height: 180px;
  }
}
