.m-block--searchform {

  input[type="text"] {
    &.form-autocomplete {
      background-image: none;
    }
  }

  .form-item-search-block-form {
    position: relative;

    @include bp('smalldesktop') {
      width: 53px;
    }

    @include bp('tabletmobile') {
      float: none;
    }
  }

  input[type="submit"] {
    display: none;
  }
}

input.m-block--searchform__input {
  @extend %search_input;
  padding-top: 0;
  padding-bottom: 0;
  height: 36px;
  box-sizing: border-box;
  width: 100%;

  &:focus + .icon {

    &:before {
      color: $grey4;
      top: 2px;
    }
  }

  & + .icon {
    pointer-events: none;

    &:before {
      top: 2px;
    }
  }

  @include bp('mobile') {
    background-color: $white;
  }
}
