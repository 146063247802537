.m-node--blogpost--teaser {
  height: 100%;
}

.m-node--blogpost--teaser__date {
  color: $text2;
  @include font1();
  font-size: px2em(15);
  line-height: px2em(20, 15);
  margin-bottom: 10px;

  > .field {
    display: inline-block;
  }

  .m-globalauthor {
    &:before {
      content: ' | ';
    }
  }
}

.m-node--blogpost--teaser__title {
  font-size: 1em;
  margin-top: 0;

  a {
    color: $text2;
    @include font2();
    font-size: px2em(26);
    line-height: px2em(33, 26);
    font-weight: $bold;
  }
}

.m-node--blogpost--teaser__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.m-node--blogpost--teaser__inner {
  position: relative;
  z-index: 2;
  height: calc(100% - 83px - 60px);
  padding: 83px 48px 60px 43px;
}

.m-node--blogpost--teaser__tags {
  @extend %node_tags;
  text-align: left;
  margin-bottom: 0;

  a {
    color: $text2;
  }
}

.m-node--blogpost--teaser__background + .m-node--blogpost--teaser__inner {
  background-color: rgba($blue-dark, $bg_opacity);

  .m-node--blogpost--teaser__title {
    a {
      color: $white;
    }
  }

  .m-node--blogpost--teaser__date {
    color: $white;

    a {
      color: $white;
    }
  }

  .m-node--blogpost--teaser__tags {
    a {
      color: $white;
    }
  }
}
