.m-content--network {
  margin-top: -164px;
  background-color: white;
  @include bp('tablet') {
    margin-top: 0;
  }
}

.m-network__filter_country {
  min-width: 345px;
  margin-right: 8px;
  @include bp('tablet') {
    min-width: initial;
    width: 100%;
    margin-right: 0;
  }
}

.m-network__filter_topic {
  min-width: 265px;
  margin-right: 22px;
  @include bp('tablet') {
    min-width: initial;
    width: 100%;
    margin-right: 0;
  }
}

.m-network__map {
  #map {
    background-image: url('../../img/network_fallback_map.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    background-color: lightgrey;
    position: relative;
    width: 100%;
    height: 802px;
    overflow: hidden;
    clear: both;

    @include bp('tablet') {
      height: 550px;
    }

    @include bp('mobile') {
      height: 350px;
    }

    canvas {
      &:focus {
        outline: none;
      }
    }

    .mapboxgl-ctrl-top-left {
      @include bp('desktop') {
        top: auto;
        bottom: 103px;
        left: 50%;
        margin-left: calc( (#{$page_width} / 2 + 50px) * -1);

        .mapboxgl-ctrl {
          float: right;
        }
      }

      @include bp('tablet') {
        top: 13px;
      }

      @include bp('mobile') {
        top: 23px;
      }
    }

    .m-network__map_marker {
      cursor: pointer;
      @include member_icon;
      &.member {
        background-color: $member_icon_color_member;
      }
      &.ambassador {
        background-color: $member_icon_color_ambassador;
      }
    }
    .mapboxgl-popup-content {
      padding: 14px;
      border-radius: 0;
      > button {
        display: none;
      }
    }
  }

  &.is-loaded {
    #map {
      background-image: none;
    }

    .m-network__consent {
      display: none;
    }
  }
}

.m-network__consent {
  width: 372px;
  padding: 25px;
  border-radius: 20px;
  background-color: $white;
  margin: 134px auto 0;
  text-align: center;
  color: $text1;

  @include bp('mobile') {
    width: 70%;
    max-width: 360px;
    padding: 15px 20px 20px;
    margin-top: 22px;
  }

  h3 {
    font-size: px2em(17);
    line-height: 1.55;
    font-weight: 600;
    font-style: normal;
    margin: 0;

    @include bp('mobile') {
      font-size: px2em(16);
    }
  }

  p {
    font-size: 14px;
    line-height: 1.55;
    margin-top: 15px;

    @include bp('mobile') {
      font-size: px2em(13);
      margin-top: 10px;
    }

    a {
      font-size: 14px;
      line-height: 1.55;
      color: $blue-light;

      @include bp('mobile') {
        font-size: px2em(13, 13);
      }

      &:hover {
        color: $blue-dark;
      }
    }
  }
}

.m-network__consentbutton {
  margin-top: 15px;

  a {
    @extend %button;

    @include bp('mobile') {
      font-size: px2em(14);
    }
  }
}

.m-network__link {
  position: absolute;
  left: 0;
  top: -66px;

  @include bp('tablet') {
    top: -95px;
  }

  @include bp('mobile') {
    top: -83px;
  }

  a {
    @include icon($icon-network);
    display: block;
    background-color: $red-light;
    color: $white;
    padding: 13px 15px 12px 58px;
    font-weight: $semi-bold;
    outline: none;
    text-transform: uppercase;
    font-size: px2em(15);
    line-height: px2em(19, 15);

    @include bp('mobile') {
      padding: 10px 10px 9px 42px;
      font-size: px2em(13);
      line-height: px2em(17, 13);
    }

    &:before {
      font-size: 34px;
      left: 14px;
      top: 6px;

      @include bp('mobile') {
        font-size: 24px;
        left: 10px;
        top: 7px;
      }
    }

    &:hover {
      background-color: $red-dark;
    }
  }
}

.m-network__link_topline {
  display: block;
  font-size: px2em(13);
  line-height: px2em(15, 13);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;

  @include bp('mobile') {
    font-size: px2em(12);
  }
}

.m-network__link_subline {
  @include icon($icon-arrow-up);
  display: block;
  padding-right: 26px;

  @include bp('mobile') {
    padding-right: 18px;
  }

  &:before {
    transform: rotate(90deg);
    font-size: 22px;
    left: auto;
    top: auto;
    right: -5px;
    bottom: 2px;

    @include bp('mobile') {
      font-size: 17px;
    }
  }
}


.m-network__list_download {
  padding: 37px 44px 0;

  @include bp('mobile') {
    padding: 30px 0;
  }

  a {
    @extend %form_submit;
    display: inline-block;
    font-size: px2em(14, 16);
    height: 40px;
    line-height: 40px;
    width: 170px;
    padding: 0;
    margin-right: 10px;
  }
}

.m-network__filter {
  padding: 37px 44px 39px;

  @include bp('mobile') {
    padding: 30px 0 15px 0;
  }
}

.m-network__filter_inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  > div {
    display: inline-block;
    margin-bottom: 20px;

    @include bp('mobile') {
      margin-right: 0;
      width: 100%;
      margin-bottom: 7px;
      &.m-network__filter_members-checkboxes {
        margin: 10px 0 6px 24px;
      }
    }
  }
}

.m-network__filter_search {
  position: relative;
  display: inline !important;
  margin-right: 28px;

  @include bp('tablet') {
    margin-right: 0;
    width: 100%;
  }

  input {
    @extend %search_input;

    @include bp('tablet') {
      width: 100%;
      background-color: $grey2 !important;
      padding-right: 50px;
    }
    width: 265px;
  }
  .icon::before {
    top: 7px !important;

    @include bp('tablet') {
      right: 19px !important;
    }
  }
}

.m-network__list_heading {
  text-align: center;
  overflow: hidden;
  h1 {
    @extend %node_title;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 50px;
    padding: 0 70px 25px 70px;
    border-bottom: 3px $blue-light solid;

    @include bp('mobile') {
      margin-bottom: 19px;
      padding: 0 0 16px 0;
    }
  }

  @include bp('mobile') {
    text-align: left;
  }
}

%network_list {
  min-height: 500px;
  padding-left: 0;
  margin-top: 0;
}

.m-network__list {
  ul.list {
    @extend %network_list;
  }
}

.m-network__filter_members-checkboxes {
  display: inline;
  max-width: 155px;

  @include bp('tablet') {
    margin: 0 auto;
  }

  label {
    font-weight: $bold;
    @include font(15, 20);
  }
}

%networ__filter_type {
  position: relative;
  float: left;
  width: 100%;
  padding-left: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  label {
    position: initial !important;
  }
  &:before {
    content: '';
    @include member_icon;
    position: absolute;
    left: 0;
    top: 4px;
  }

  input[type="checkbox"] + label {
    padding-right: 0;
  }

  input[type="checkbox"] + label::after {
    top: 7px;
  }
  input[type="checkbox"]:checked + label::before {
    right: 1px;
    top: 3px;
  }
}

.m-network__filter_members {
  @extend %networ__filter_type;
  &::before {
    background-color: $member_icon_color_member;
  }
  &:hover {
    label {
      color: $member_icon_color_member;
    }
    .m-network__filter_members_tooltip {
      display: block;
    }
  }
}

.m-network__filter_ambassadors {
  @extend %networ__filter_type;
  &::before {
    background-color: $member_icon_color_ambassador;
  }
  &:hover {
    label {
      color: $member_icon_color_ambassador;
    }
    .m-network__filter_ambassadors_tooltip {
      display: block;
    }
  }
}

%network_filter_type_tooltip {
  z-index: 9999;
  display: none;
  position: absolute;
  bottom: 30px;
  left: -120px;
  width: 320px;
  background-color: $white;

  > div {
    padding: 11px 12px 18px 18px;
    @include font1;
    color: $text2;
    font-weight: $regular;
    font-size: px2em(13);
    line-height: px2em(19, 13);

  }

  margin: 20px;
  box-sizing: border-box;
  box-shadow: 0 3px 3px 0 $grey3;

  @include bp('mobile') {
    left: -62px;
    width: 320px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -5px;
    bottom: -20px;
    left: 50%;
    box-sizing: border-box;

    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent $white $white;

    transform-origin: 0 0;
    transform: rotate(-45deg);

    box-shadow: -3px 3px 3px 0 $grey3;
  }
}

.m-network__filter_ambassadors_tooltip {
  @extend %network_filter_type_tooltip;
}

.m-network__filter_members_tooltip {
  @extend %network_filter_type_tooltip;
  bottom: 55px;
}

.m-network__list_teaser {
  width: 100%;
  padding-bottom: 22px;
}

.m-network__list_teaser_inner {
  max-width: 720px;
  margin: 0 auto;
  text-align: center;

  color: $text1;
  @include font1;
  font-size: 19px;
  line-height: 26px;
  font-weight: $regular;

  @include bp('mobile') {
    width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
  }
}

.m-network__list_join {
  width: 100%;
  margin-bottom: 60px;

  @include bp('mobile') {
    margin-bottom: 30px;
  }

  a {
    @extend %form_submit;
    display: block;
    font-size: px2em(14, 16);
    height: 40px;
    line-height: 40px;
    width: 112px;
    margin: 0 auto;
    padding: 0;

    @include bp('mobile') {
      margin: 0;
    }
  }
}

.m-network__map_popup {
  width: auto;
}

.m-network__map_popup_name {
  margin-bottom: 6px;
  a {
    @include font1;
    color: $text2;
    font-size: 15px;
    font-weight: $bold;
    line-height: 20px;
  }
}

.m-network__map_popup_name_anonymous {
  max-width: 235px;
}

%network__map_popup_line {
  div,
  a {
    display: inline-block;
    @include font1;
    font-size: 13px;
    font-weight: $regular;
    line-height: 18px;
  }
  a {
    color: $blue-light;
  }
  div {
    color: $text2;
  }
}

.m-network__map_popup_twitter_line,
.m-network__map_popup_contact_line {
  @extend %network__map_popup_line;
}

.m-network__list_noresults {
  width: 100%;
  text-align: center;
}
