
.m-pdfgen-experts {
  .content > .m-node--expert--full__text + .m-node--expert--full__field,
  .m-node--expert--full__header > h4,
  .m-node--expert--full__printbar,
  .m-node--expert--full__type {
    display: none;
  }

  .m-node--expert--full__content_main {
    margin: 0 !important;
  }

  .m-sidebars--contact,
  .m-node--expert--full__content_sidebar {
    padding-top: 20px !important;
    margin: 0 !important;
    page-break-inside: avoid;
    break-inside: avoid;
    &[data-is-askclew="1"] {
      display: none;
    }
  }

  .m-node--expert--full__header {
    margin-bottom: 30px;
  }

  .m-node--expert--full__title {
    line-height: 130%;
  }
}

.m-pdfgen-experts__top_logo {
  overflow: hidden;
  margin-bottom: 60px;
}

.m-pdfgen-experts__top_logo_img {
  float: left;
  width: 100px;
}

.m-pdfgen-experts__top_logo_claim {
  @include font1();
  color: $blue-dark;
  font-size: px2em(22);
  font-weight: $bold;
  line-height: 1.3em;
  float: left;
  margin-left: px2em(30);
  padding-top: px2em(12);
  width: 11em;
  @extend %transitionAll;
}

.m-pdfgen-experts__top_heading {
  @include font1();
  font-size: 44px;
  font-weight: $bold;
  line-height: 105%;
  margin-bottom: 22px;
  text-align: left;
}

.m-pdfgen-experts__top_type {
  @extend %node_icon;
  position: relative;
  margin-bottom: 10px;
  display: inline-block;
  padding-left: 60px;

  span {
    &:before {
      content: $icon-experts;
    }
  }
}

.m-pdfgen-experts__top_filters_label {
  font-weight: bold;
}

