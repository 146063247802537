.m-form--newsletter-subscription {
  #webform-component-sign-up-for-our-newsletter {
    display: none;
  }

  .webform-component-newsletter_email {
    label {
      width: 13%;
      margin-right: 0;
      display: block;
      float: left;

      @include bp('mobile') {
        margin-left: 0;
        float: none;
        width: 100%;
      }
    }

    input {
      width: 50%;
      height: 32px;
      color: $grey4;
      font-size: px2em(14);
      line-height: px2em(19, 14);
      float: left;

      @include bp('mobile') {
        float: none;
        width: calc(100% - 48px);
        max-width: 350px;
        padding: 4px 24px;
        margin-top: 3px;
      }
    }
  }

  .form-checkboxes {
    @include clearfix;
    clear: both;
    display: block;
    padding-top: 20px;
    margin-left: 13%;

    @include bp('mobile') {
      margin-left: 0;
    }
  }

  .form-item.form-type-checkbox {
    display: inline;
    text-align: left;
    padding: 0;

    @include bp('mobile') {
      display: block;
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .form-type-checkbox {
    label {
      line-height: 1.2em;
      padding-left: 30px;

      &:after {
        right: auto;
        left: 0;
        border-radius: 0;
      }

      &:before {
        right: auto;
        left: 0;
        border-radius: 0;
      }
    }
  }

  input[type="checkbox"] + label::after {
    border: 0;
  }

  .form-actions {
    padding-top: 20px;
    margin-left: 13%;

    @include bp('mobile') {
      margin-left: 0;
    }
  }

  input[type="submit"] {
    @extend %form_submit;
    text-transform: uppercase;
    padding: 0.4em 1.5em;
  }

  .captcha {
    margin-left: 13%;
    margin-top: 20px;
    padding: 0;
    border: 0;

    @include bp('mobile') {
      margin-left: 0;
    }

    legend,
    .fieldset-description {
      display: none;
    }
  }
}
