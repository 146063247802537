.m-node--landingpage {

}

.m-node--landingpage__header {
  @extend %node_header;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    background: $blue-dark;
    left: -100%;
    right: -100%;
    top: 0;
    bottom: 0;
  }
}

.m-node--landingpage__title {
  @extend %node_header_title;

  &[data-icon] {
    @include node_header_icon_title('');
    @extend %data_icons;
  }
}

.m-node--landingpage__description {
  @extend %node_header_description;

  @include bp('mobile') {
    width: 100%;
    font-size: px2em(17);
  }
}

.m-node--landingpage__content {

}

.m-node--landingpage__teaser__button {
  margin-top: 20px;

  a {
    text-decoration: none;
    display: inline-block;
    padding: 0.7em 1.3em;
    background: $red-light;
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 2em;
    font-weight: 600;
    @extend %transitionAll;

    &:hover {
      background: $red-dark;
      transform: rotate(-3deg) scale(1.1);
    }
  }
}
