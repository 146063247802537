.m-research-map__details,
.m-research-map__loading {
  &.hidden {
    display: none;
  }
}

.m-research-map__loading {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: rgba($white, $bg_opacity);
  box-shadow: 0 1px 2px rgba($black, 0.2);
  color: #333;

  span.text {
    display: block;
    padding: .6em 1.5em .5em;
    font-weight: bold;
  }
}

.m-research-map__panel {
  position: absolute;
  top: 60px;
  right: 20%;
  height: 310px;
  width: 460px;
}

.m-research-map__details {
  padding: 14px;
  border-radius: 0;
  background: #fff;
  box-shadow: 0 1px 2px rgba($black, 0.2);
  width: 100%;

  h3 {
    font-size: px2em(15);
    font-weight: bold;
  }

  .tag {
    display: inline-block;
    margin: 0 10px 0 0;
    font-style: italic;
  }

  .teaser {
    font-size: px2em(13);
    line-height: 1.4em;
  }

  a.open {
    display: inline-block;
    font-size: px2em(13);
    font-weight: bold;
    background: $red-light;
    padding: .3em 1em .4em;
    margin-top: 1em;
    color: #fff;
    border-radius: 2em;
    text-transform: uppercase;
    clear: both;

    &:hover {
      cursor: pointer;
      background: $red-dark;
    }

    &:active {
      outline: none;
    }
  }

  button.close {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5em;
    background: none;
    padding: .4em .7em;
    font-weight: bold;
    color: #ccc;
    border: none;

    &:hover {
      cursor: pointer;
      color: $text1;
    }

    &:active {
      outline: none;
    }
  }
}
