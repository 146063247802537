.m-paragraph--image {
  @extend %paragraph;
  margin-bottom: 20px;
}

.m-paragraph--image--portrait {
  .m-paragraph--image__image {
    margin: 0 20%;

    @include bp('mobile') {
      margin: 0;
    }
  }
}

.m-paragraph--textimage__title {
  @extend %paragraphs_title;
}

.m-paragraph--image__image {
  @include fullimage;
  @extend %image_caption;

  @include bp('mobile') {

  }
}
