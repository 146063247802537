#pg-cleverreach-unsubscribe-form,
#pg-cleverreach-form {
  &.webform-client-form .webform-component-textfield,
  &.webform-client-form .webform-component-select,
  &.webform-client-form .webform-component-email {
    label {
      font-style: normal;
    }

    &.form-item-sector {
      label {
        font-style: italic;
      }
    }
  }

  .captcha {
    margin-bottom: 20px;
  }

  .form-item-options {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    label {
      width: 20%;
      margin-right: 2%;
      white-space: nowrap;
      font-style: normal;
    }

    .form-checkboxes {
      display: flex;

      > div {
        margin-right: 20px;
      }
    }
  }
}
