%listgrid2 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items: stretch;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

%listgrid2__trigger {
	margin-bottom: px2em(30);
	width: 48%;
	flex-shrink: 1;

	@include bp('smalldesktop') {
		-ms-flex-wrap: wrap;
		width: 100%;
		align-self: left;
	}

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		width: 100%;
		align-self: left;
	}
}

.l-listgrid--3__content {

}

