.m-searchfilter {
  margin: 0 auto 60px auto;
  width: $inner_head_width;

  display: flex;
  flex-direction: row;

  > div {
    flex-basis: 25%;
    padding: 0 10px;
  }

  @include bp('tablet') {
    width: $tablet_width;
    flex-direction: column;

    > div {
      padding: 0;
    }
  }

  @include bp('mobile') {
    margin-bottom: 40px;
    width: 100%;
  }
}

%filter {
  .select2-container {
    width: 100% !important;
  }

  @include bp('tablet') {
    width: 30%;
  }

  @include bp('mobile') {
    margin-bottom: 20px;
    width: 100%;
  }
}

.m-searchfilter__filter--date {
  @extend %filter;
}

.m-searchfilter__filter--tags {
  @extend %filter;
}

.m-searchfilter__filter--relevance {
  @extend %filter;
}

.m-searchbase {
  @extend %listgrid3;
  margin-bottom: 30px;
}

.m-searchbase__keys {
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 20%;

  @include bp('tablet') {
    width: 55%;
  }

  @include bp('mobile') {
    margin-bottom: 10px;
    width: 100%;
    padding: 0;
  }

  .form-type-textfield {
    position: relative;
    width: 100%;

    @include bp('mobile') {
      width: 70%;
    }
  }

  input {
    box-sizing: border-box;

    &.form-text {
      position: relative;
      width: 100%;
    }
    &.form-submit {
      width: calc(25% - 20px);
      margin-left: 20px;

      @include bp('mobile') {
        width: calc(37% - 10px);
        margin-left: 10px;
      }

      &:hover {
        cursor: pointer;
        background-color: lighten($blue-light, 10);
      }
    }

    &:focus {
      width: 100%;
    }
  }
}

.m-searchbase__presets {

  a {
    @extend %form_submit;
    display: block;
    height: 34px;
    line-height: 210%;
    width: 225px;
    background-color: $red-light;

    @include bp('mobile') {
      width: auto;
    }
  }

  @include bp('mobile') {
    text-align: center;
  }
}

.m-searchbase__help {
  @extend %listgrid3__trigger;

  @include bp('mobile') {
    display: none;
  }

  p {
    font-size: px2em(15);
    line-height: px2em(20, 15);
    margin-top: 0;
  }

  strong {
    display: block;
    margin-bottom: 2px;
  }
}
