.m-node--specialpage {
  @extend %node;
  background-color: $white;
  margin-top: -494px;

  @include bp('tablet') {
    margin-top: -320px;
  }

  @include bp('mobile') {
    margin-top: 0;
  }

  @include bp('print') {
    margin-top: 0;
  }
}

.m-node--specialpage__type {
  @extend %node_icon;

  @include node_icon_icon(56px);
  @extend %data_icons;
  position: absolute;
}

.m-node--specialpage__printbar {
  @extend %node_printbar;
}

.m-node--specialpage__socialshare {
  @extend %node_socialshare;
  @extend %page_center_field_small;
}

.m-node--specialpage__title {
  @extend %node_title;
  @extend %page_center_field;
}

.m-node--specialpage__teaser {
  @extend %node_teaser;
  @extend %page_center_field;
}

.m-node--specialpage__subtitle {
  @extend %node_subtitle;
  @extend %page_center_field;
}

.m-node--specialpage__date {
  @extend %node_date;
  @extend %page_center_field;
}

.m-node--specialpage__tags {
  @extend %node_tags;
}

.m-node--specialpage__ctypes {
  @include clearfix;
  margin-bottom: 100px;

  > .paragraphs-items {
    margin-top: 76px;
  }

  @include node_ctypes {
    &.m-paragraph--quote {
      @extend %page_full_field;
    }

    &.m-paragraph--morebox {
      @extend %page_full_field;
    }
  }

  > .paragraphs-items > .field > .field-items > .field-item {
    &:nth-child(n) {

    }

    &:nth-child(2n) {
      .m-paragraph--featuredreference__content {
        float: right;
        margin-right: 0;
        margin-left: 2.5%;

        @include bp('mobile') {
          float: none;
          margin-left: 0;
        }
      }
    }
  }
}

.m-node--specialpage__expertfacts {
  margin-bottom: 56px;
}

.m-node--specialpage__block1 {
  margin-top: 66px;
  padding-top: 56px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: $blue-light;
    position: absolute;
    top: 0;
  }
}

.m-node--specialpage__block1__title {
  @extend %ccasetitled;
  text-align: center;
  margin-bottom: 50px;

  @include bp('mobile') {
    text-align: left;
  }
}

.m-node--specialpage__block1__list {
  @extend %grid3;
}

.m-node--specialpage__block1__trigger {
  @extend %grid3__trigger;
}
