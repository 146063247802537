.m-sidebars--attachments {
  position: relative;
  padding-top: 70px;
  margin-bottom: 60px;
}

.m-sidebars--attachments__title {
  @extend %teaserlistgrid__type;
  background-color: $grey2;
  font-size: 1em;
  padding-left: 65px;
  top: 0;

  &:before {
    top: 4px;
    left: 27px;
    content: $icon-attachment;
    color: $blue-light;
    font-size: 33px;
  }

  &:after {
    background-color: $grey2;
  }

  span {
    font-size: px2em(15, 16);
    color: $text2;
  }
}

.m-sidebars--attachments__content {
  font-size: px2em(14);
  line-height: px2em(19, 14);
  padding: 0 0 0 7%;
}

.m-sidebars--attachments__attachment--list {
  @extend %sidebars_item_list;
}

.m-sidebars--attachments__attachment--item {
  @extend %sidebars_item_list_item;
  @include clearfix;
}

.m-sidebars--attachments__attachment--link {
  @include font2();
  font-size: px2em(16, 14);
  line-height: px2em(23, 16);
  font-weight: $semi-bold;
}

.m-sidebars--attachments__attachment--meta {
  @include font1();
  float: left;
  width: 100%;
  clear: both;
  overflow: hidden;
  margin: 1em 0 .4em;
  position: relative;
  font-size: px2em(14);
  line-height: px2em(19, 14);
  font-weight: $regular;
  color: $text2;
}