%form_input {
  border: 0;
  border-radius: 36px;
  box-shadow: none;
  height: 42px;
  padding: 4px 24px;
  background-color: $grey2;
  background-image: none;
  outline-color: transparent;
  outline-style: none;

  @include bp('mobile') {
    padding: 8px;
  }
}

%form_submit {
  @extend %form_input;
  background-color: $red-light;
  color: $white;
  text-align: center;
  padding: 2px 12px 4px 12px;
  font-weight: $semi-bold;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;

  &:hover {
    background-color: $red-dark;
  }
}

input[type="text"],
input[type="password"] {
  @extend %form_input;
}

%search_input {
  @extend %form_input;
  @extend %transitionAll;
  float: right;
  width: 200px;

  & + .icon {
    @include icon($icon-search);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2em;

    &::before {
      left: auto;
      right: 14px;
      top: 7px;
      color: $grey4;
      font-size: 22px;
      transition: all 0.5s ease;

      @include bp('mobile') {
        transition: none;
      }
    }
  }

  &:focus {
    width: 300px;

    @include bp('tabletmobile') {
      width: 100%;
    }
  }

  &:focus + .icon {
    &:before {
      color: $grey2;
    }
  }

  @include bp('tabletmobile') {
    transition: none;
    width: calc(100% - 24px);
    float: none;
  }
}

input[type="checkbox"]:not([class^=Cybot]) {
  display: none;
}

input[type="checkbox"]:checked + label {
  &::before {
    content: '\EA1F';
    font-family: "icons";
    line-height: 140%;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    text-align: center;
    color: $blue-light;
    text-indent: 0;
    font-size: 16px;
    position: absolute;
    width: 14px;
    height: 14px;
    position: absolute;
    display: block;
    right: -1px;
    top: -3px;
    z-index: 2;
  }
}

input[type="checkbox"] + label {
  position: relative;
  padding-right: 25px;

  &::after {
    content: '';
    background-color: white;
    border: 1px solid $grey4;
    border-top: 1px solid $grey4;
    border-radius: 3px;
    width: 14px;
    height: 14px;
    position: absolute;
    display: block;
    right: 0;
    top: 2px;

    -moz-box-shadow: inset 0 0 2px rgba($black, 0.4);
    -webkit-box-shadow: inset 0 0 2px rgba($black, 0.4);
    box-shadow: inset 0 0 2px rgba($black, 0.4);
  }
}

@import "_m-form--webform";
@import "_m-form--webform--results";
@import "_m-form--user-login";
@import "_m-form--application--event";
@import "_m-form--application--team";
@import "_m-form--application--membership";
@import "_m-form--newsletter";
@import "_m-form--contact";
@import "m-cleverreach-form";
