.m-paragraph--infobox {
  @extend %paragraph;
  margin-bottom: px2em(20);
}

.m-paragraph--infobox__inner {
  padding: 27px 52px;

  @include bp('print') {
    padding: 20px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}

.m-paragraph--infobox__title {
  @extend %paragraphs_title;
  @include font1();
  font-size: px2em(15);
  font-weight: $bold;
  line-height: px2em(25, 15);
  margin: 0;
  padding-right: 20px;
  cursor: pointer;

  @include icon('\EA03');

  a {
    font-weight: $bold;
    font-size: inherit;
    line-height: inherit;
  }

  &:before {
    left: auto;
    right: -25px;
    top: -6px;
    color: $grey3;
    font-size: 26px;
    color: $text1;
  }

  @include bp('print') {
    &:before {
      display: none;
    }
  }
}

.m-paragraph--infobox__text {
  @extend %paragraph_text;
  @include font1();
  font-size: px2em(15);
  line-height: px2em(21, 15);
  padding: 16px 120px 0 0;
  display: none;

  @include bp('mobile') {
    padding-right: 0;
  }

  p {
    @include font1();
    font-size: px2em(15, 15);
    line-height: px2em(21, 15);

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    @include font1();
    font-size: px2em(15, 15);
    line-height: px2em(21, 15);
  }

  @include bp('print') {
    display: block;
  }
}

//STATES
.m-paragraph--infobox {
  &[data-layout="red"] {
    background-color: $red-light;

    @include bp('print') {
      background-color: transparent;

      * {
        color: #333!important;
      }
    }

    .m-paragraph--infobox__title {
      color: $white;

      &:before {
        color: $white;
      }
    }

    .m-paragraph--infobox__text {
      color: $white;

      a.lexicon-term,
      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  &[data-layout="blue"] {
    background-color: $blue-light;

    .m-paragraph--infobox__title {
      color: $white;

      &:before {
        color: $white;
      }

      a {
        color: $white;
      }

      div::selection {
        color: $blue-medium;
        background-color: $white;
      }
    }

    .m-paragraph--infobox__text {
      color: $white;

      a.lexicon-term,
      a {
        color: $white;
        text-decoration: underline;
      }
      a::selection,
      p::selection {
        color: $blue-medium;
        background-color: $white;
      }
    }
  }

  &[data-layout="grey"] {
    background-color: $grey3;
  }
}

.m-paragraph--infobox {
  &.#{$isactive} {
    .m-paragraph--infobox__title {
      &:before {
        content: '\EA04';
      }
    }

    .m-paragraph--infobox__text {
      display: block;
    }
  }
}

