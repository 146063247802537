.m-servicemenu__container {
  margin-bottom: 35px;

  @include bp('tablet') {
    margin-bottom: 20px;
  }

  @include bp('mobile') {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .innerWrapper {
    @include bp('desktop') {
      width: 100%;
    }

    @include bp('smalldesktop') {
      width: 100%;
    }

    @include bp('tablet') {
      width: 100%;
    }

    @include bp('mobile') {
      width: $mobile_width;
    }
  }

  ul {
    @extend %ul;
    @include clearfix;

    li {
      @extend %list_item;
      list-style-image: none;
      float: left;
      line-height: 33px;

      @include bp('mobile') {
        float: none;
        padding-top: 2px;
      }

      a {
        display: inline-block;
        font-size: 15px;
        line-height: 1.55;
        padding: 0 13px;
        font-weight: 400;
        transition: all 0.3s ease-in-out;

        @include bp('mobile') {
          padding: 0;
          color: $white;
        }

        &:hover {
          color: $blue-light;

          @include bp('mobile') {
            color: $blue-dark;
          }
        }
      }

      &.search {
        width: 45px;
        margin-left: 17px;

        @include bp('mobile') {
          display: none;
        }

        a {
          @include icon($icon-search);

          display: block;
          width: 45px;
          height: 33px;
          padding: 0;
          text-indent: -9999px;
          border-radius: 20px;
          background-color: $grey2;

          &::before {
            top: 7px;
            left: 11px;
            color: $grey1;
            font-size: 20px;
            line-height: 20px;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            &::before {
              color: $blue-light;
            }
          }
        }
      }
    }

    // li + li {
    //   &:before {
    //     content: '|';
    //   }

    //   @include bp('mobile') {
    //     &:before {
    //       content: '';
    //     }
    //   }
    // }
  }
}
