.m-sidebars--events {
  @extend %sidebars-list__block;
}

.m-sidebars--events__title {
  @extend %sidebars-list__title;
}

.m-sidebars--events__content {
  @extend %sidebars-list__content;
}

.m-sidebars--events--item {
  @extend %sidebars_item_list;
}

.m-sidebars--events--item {
  @extend %sidebars_item_list_item;
}

.m-sidebars--events--item__title {
  @extend %sidebars_item_list_title;
}
