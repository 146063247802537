.m-node--blogpost {
  @extend %node;
}

.m-node--blogpost__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 48px;

  a {
    @include ci('blogpost');
    display: inline-block;
    background-color: $purple1;

    &:after {
      border-left-color: $purple1;
    }
  }

  &:hover {
    a {
      background-color: $purple2;
      &:after {
        border-left-color: $purple2;
      }
    }
  }
}

.m-node--blogpost__printbar {
  @extend %node_printbar;
  right: 0;
  top: 20px;
}

.m-node--blogpost__title {
  @extend %node_title;
  text-align: left;
  margin-bottom: 23px;
}

.m-node--blogpost__tagline {
  position: relative;
  margin-bottom: 57px;
}

.m-node--blogpost__socials {
  position: absolute;
  right: 0;
  top: -4px;
}

.m-node--blogpost__cover {
  @extend %node_cover;
  @extend %image_caption;
}

.m-node--blogpost__topline {
  margin-bottom: 30px;

  > div {
    display: inline-block;
    color: $text1;
    font-size: px2em(17);
    line-height: px2em(23, 17);

    a {
      color: $text1;
    }

    & + div {
      &:before {
        content: ' | ';
        display: inline-block;
      }
      > div {
        display: inline-block;
      }
    }
  }
}

.m-node--blogpost__teaser {
  @extend %node_teaser;
  text-align: left;
}

.m-node--blogpost__paragraphs {
  margin-bottom: 80px;

  @include node_ctypes {

  }
}

.m-node--blogpost__related_events {

}

.m-node--blogpost__author_full {
  margin-bottom: 30px;
}
