$ml: 128px;

.m-authorfull {
    clear: both;
    overflow: hidden;
    padding-top: 5px;
    margin-top: -5px;

  .field-name-field-user-portrait {
    @include fullimage;
    width: 100px;
    height: 100px;
    float: left;
    margin-top: -3px;

    img {
      border-radius: 50%;
    }
  }

  .item-list > ul {
    @extend %ul;
  }

  .views-row {
    @extend %list_item;
    min-height: 120px;

    &:before {
      content: '';
      clear: both;
      overflow: hidden;
    }

    &.views-row-last {
      .field-name-field-teaser {
        &:after {
          display: none;
        }
      }
    }
  }

  .profile {
    position: relative;
    margin: 0;

    &:before {
      content: '';
      background: $grey5;
      position: absolute;
      left: 0;
      top: -3px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      z-index: -1;
    }
  }

  .group-name.field-group-div {
    margin-left: $ml;
    margin-bottom: 3px;

    > div {
      display: inline-block;
      margin-right: 6px;

      .field-item {
        @include font1();
        font-size: px2em(15);
        font-weight: $bold;
        line-height: px2em(23, 17);
      }
    }
  }

  .field-name-field-teaser {
    position: relative;
    margin-left: $ml;
    margin-bottom: 30px;

    .field-item {
      @include font1();
      font-size: px2em(15);
      line-height: px2em(21, 16);
    }

    &:after {
      content: '';
      position: absolute;
      top: calc(100% + 15px);
      left: 0;
      width: 5em;
      height: 1px;
      background: $grey5;
    }
  }
}
