%result_type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 26px;

  a {
    display: inline-block;
  }
}

%result_results {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;

  @include bp('mobile') {
    height: auto;
    overflow: auto;
  }
}

.m-searchresultsblock--dossier__type {
  @extend %result_type;

  a {
    @include ci('dossier');
  }
}

.m-searchresultsblock--dossier__results {
  @extend %result_results;
}

.m-searchresultsblock--others__type {
  @extend %result_type;
}

.m-searchresultsblock--others__results {
  @extend %result_results;
}

.m-searchresultsblock--factsheet__type {
  @extend %result_type;
  a {
    @include ci('factsheet');
  }
}

.m-searchresultsblock--factsheet__results {
  @extend %result_results;
}

.m-searchresultsblock--article__type {
  @extend %result_type;

  a {
    @include ci('article');
  }
}

.m-searchresultsblock--article__results {
  @extend %result_results;
}

.m-searchresultsblock--blog_post__type {
  @extend %result_type;

  a {
    @include ci('blogpost');
  }
}

.m-searchresultsblock--blog_post__results {
  @extend %result_results;
}

.m-searchresultsblock--expert__type {
  @extend %result_type;

  a {
    @include ci('expert');
  }
}

.m-searchresultsblock--expert__results {
  @extend %result_results;
}

.m-searchresultsblock--event__type {
  @extend %result_type;

  a {
    @include ci('event');
  }
}

.m-searchresultsblock--event__results {
  @extend %result_results;
}

.m-searchresultsblock--interest_point__type {
  @extend %result_type;

  a {
    @include ci('dossier');
  }
}

.m-searchresultsblock--interest_point__results {
  @extend %result_results;
}

%bluelineloadmore {
  display: inline-block;
  content: '';
  width: 35px;
  height: 2px;
  background-color: $blue-light;
}

.m-searchresultsblock__loadmore {
  text-transform: uppercase;
  text-align: center;
  color: $blue-light;
  display: block;

  &:before {
    @extend %bluelineloadmore;
    margin-right: 12px;

    @include bp('mobile') {
    }
  }

  &:after {
    @extend %bluelineloadmore;
    margin-left: 12px;

    @include bp('mobile') {

    }
  }
}
