.m-webform__webformclientform3660 {

    .webform-component-markup {
        p {
            margin-top: 0;
        }
    }

    div > .webform-component-fieldset {
        margin: 0 0 2em 0;
        padding: 0.5em 1.5em 0.5em 1.5em;

        legend {
            margin-bottom: 0;
            margin-top: 1.5em;

            .fieldset-legend {
                padding: 0 10px;
                font-weight: $bold;
            }
        }

        .webform-component-fieldset {
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;

            legend {
                font-size: 1em;
            }

            .fieldset-description {
                font-weight: 300;
            }
        }
    }

    #webform-component-personal-details--last-name {
        flex-wrap: wrap;

        .description {
            padding-left: $webform-label-width;

            @include bp('mobile') {
                padding-left: 0;
            }
        }
    }

    #webform-component-professional-profile--working-time {
        flex-wrap: wrap;

        .description {
            width: 100%;
        }
    }

    #webform-component-find-me-online--note,
    #webform-component-find-me-online--other-social-media,
    #webform-component-professional-profile--working-time,
    #webform-component-professional-profile--freelance-journalist {
        padding-left: $webform-label-width;

        @include bp('mobile') {
            padding-left: 0;
        }
    }

    #webform-component-find-me-online--other-social-media-url {
        .form-text {
            margin-left: $webform-label-width;

            @include bp('mobile') {
                margin-left: 0;
            }
        }
    }

    #webform-component-professional-profile--how-long-number {
        position: relative;

        select {
            width: 10em;
        }

        .select2-container {
            width: 8em;
        }

        #webform-component-professional-profile--how-long-type {
            margin: 0 0 0 1em;
            align-self: flex-start;

            @include bp('mobile') {
                margin: 1em 0 0 0;
            }
        }
    }

    #webform-component-professional-profile--full-time-journalist {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 0;

        label {
            width: 20%;
            margin-right: 2%;

            @include bp('mobile') {
                width: 93%;
                margin-right: 0;
            }
        }
        .form-radios {
            width: 77.2%;
            align-self: flex-start;

            @include bp('mobile') {
                width: 93%;
            }
        }
    }

    #webform-component-professional-profile--working-time {
        .form-text {
            width: 25px;
            margin: 0 7px;
            padding: 3px 1%;
        }
    }

    #webform-component-professional-profile--media-outlets,
    #webform-component-topics--other-topics-i-cover {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        @include bp('mobile') {
            display: block;
        }

        label {
            width: 20%;
            margin-right: 2%;

            @include bp('mobile') {
                width: 93%;
                margin-right: 0;
            }
        }
        .form-textarea-wrapper {
            width: 77.2%;
            align-self: flex-start;

            @include bp('mobile') {
                width: 93%;
            }
        }
        .description {
            padding-left: $webform-label-width;
            width: 78%;

            @include bp('mobile') {
                padding-left: 0;
                width: 93%;
            }
        }
    }

    #webform-component-stay-up-to-date--informed,
    #webform-component-data-protection--data-protection {
        .form-type-checkbox {
            padding-top: $webform-item-padding;

            &:first-child {
                padding-top: 0;
            }
        }
    }

    #webform-component-membership--membership-details {
        .form-radios {
            display: block;
        }
        .form-type-radio {
            padding-right: 0;
            padding-top: $webform-item-padding;

            &:first-child {
                padding-top: 0;
            }
        }
    }
}
