%node {
  padding-top: 56px;
  position: relative;

  @include bp('tablet') {
    padding-top: 38px;
  }

  @include bp('print') {
    padding-top: 20px;
  }
}

@mixin node_icon_icon($height) {
  @include icon('\EA01');
  color: $white;
  padding-left: 65px;
  padding-right: 20px;
  line-height: $height;
  display: inline-block;
  background-color: $blue-light;
  text-transform: uppercase;

  @include font1();
  font-size: px2em(18);
  font-weight: $semi-bold;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    border-left: 0.4em solid $blue-light;
    border-bottom: $height solid transparent;

    @include bp('mobile') {
      border-bottom: 28px solid transparent;
    }
  }

  &:before {
    color: $white;
    line-height: $height;
    font-size: 30px;
    left: 20px;

    @include bp('mobile') {
      font-size: 16px;
      top: 2px;
      line-height: 26px;
      left: 13px;
    }
  }

  @include bp('mobile') {
    font-size: px2em(12);
    line-height: 30px;
    padding-left: 35px;
    padding-right: 8px;
  }

  @include bp('print') {
    &:after {
      display: none;
    }
  }
}

%node_icon {
  $height: 56px;
  height: $height;
  position: absolute;
  top: ($height / 2 * -1);

  @include bp('print') {
    display: none;
  }

  > span,
  a {
    @include node_icon_icon($height);
  }

  > span {
    cursor: default;
  }

  &:hover {
    a {
      background-color: lighten($blue-light, 10);

      &:after {
        border-left-color: lighten($blue-light, 10);
      }
    }
  }

  @include bp('mobile') {
    left: ($mobile_diff * -1);
    height: 30px;
    top: -13px;
  }
}

%node_icon_small {
  $height: 45px;
  height: $height;
  position: absolute;
  top: ($height / 2 * -1);

  > span,
  a {
    @include node_icon_icon($height);
  }

  > span {
    cursor: default;
  }

  &:hover {
    a {
      background-color: lighten($blue-light, 10);

      &:after {
        border-left-color: lighten($blue-light, 10);
      }
    }
  }

  @include bp('mobile') {
    left: ($mobile_diff * -1);
    height: 26px;
    top: -13px;
  }
}

%node_printbar {
  position: absolute;
  right: 77px;
  top: 53px;

  @include bp('mobile') {
    display: none;
  }
}

%node_socialshare {
  text-align: center;
  border-bottom: 3px solid $blue-light;
  margin-bottom: 50px;
  padding-bottom: 30px;

  @include bp('mobile') {
    margin-bottom: 35px;
    padding-bottom: 21px;
  }
}

%node_teaser {
  @include font2();
  font-size: px2em(17);
  font-weight: $bold;
  line-height: px2em(25, 17);
  text-align: center;
  margin-bottom: 41px;

  @include bp('mobile') {
    text-align: left;
    margin-bottom: 21px;
    font-size: px2em(15);
    line-height: px2em(21, 15);
  }

  a {
    @include font2();
    font-weight: $bold;
    color: $blue-light;
    font-size: inherit;
    line-height: inherit;

    &:hover {
      color: $blue-dark;
    }

    &.lexicon-term {
      color: $text1;

      &:hover {
        color: $text1;
      }
    }
  }
}

%node_title {
  @include font2();
  font-size: px2em(35);
  font-weight: $bold;
  line-height: px2em(44, 35);
  margin-bottom: 22px;
  text-align: center;

  @include bp('tablet') {
    font-size: px2em(30);
    line-height: px2em(36, 30);
  }

  @include bp('mobile') {
    font-size: px2em(22);
    line-height: px2em(26, 22);
    text-align: left;
    margin-bottom: 15px;
  }
}

%node_subtitle {
  @include font2();
  font-size: px2em(17);
  font-weight: $bold;
  line-height: px2em(25, 17);
  text-align: center;
  margin-bottom: 12px;
  color: $text2;
  @include bp('mobile') {
    font-size: px2em(16);
    line-height: px2em(21, 17);
    text-align: left;
    margin-bottom: 10px;
  }
}

%node_date {
  font-weight: $regular;
  @include font1();
  text-align: center;
  margin-bottom: 31px;
  font-size: px2em(15);
  line-height: px2em(20, 15);
  color: $text2;
  @include bp('mobile') {
    font-size: px2em(12);
    line-height: px2em(17, 17);
    text-align: left;
    margin-bottom: 20px;
  }
}

%node_dateline {
  @extend %node_date;

  .field-name-field-global-date {
    display: inline-block;
  }

  .m-globalauthor {
    &:before {
      content: ' | ';
      display: inline-block;
    }
  }
}

%node_tags {
  margin-bottom: 41px;
  text-align: center;
  @include bp('mobile') {
    font-size: px2em(16);
    line-height: px2em(22, 17);
    text-align: left;
    margin-bottom: 21px;
  }
}

%global_tags {
  display: inline-block;

  div {
    display: inline-block;
  }
  a {
    @include font1();
    font-size: px2em(17);
    font-weight: $regular;
    font-style: italic;
    line-height: px2em(23, 17);
    text-align: center;

    @include bp('mobile') {
      font-size: px2em(16);
      line-height: px2em(22, 16);
    }

    &:before {
      content: '#';
    }
  }
}

.m-globaltags {
  @extend %global_tags;
}

@mixin node_ctypes {
  > .paragraphs-items > .field > .field-items > .field-item > div {
    @extend %page_center_field;

    @content;
  }

  > .paragraphs-items > .field > .field-items > .field-item + .field-item {
    margin-top: 56px;

    @include bp('mobile') {
      margin-top: 30px;
    }
  }
}

@mixin node_ctypes_with_sidebar {

  > .paragraphs-items > .field > .field-items > .field-item + .field-item {
    margin-top: 56px;
  }
}

%node_header {
  background: $blue-dark;
  color: $white;
  text-align: center;
  margin-bottom: 90px;
  padding-bottom: 50px;

  @include bp('mobile') {
    padding-bottom: 25px;
  }

  &:first-letter {
    color: $blue-light;
  }

  a {
    font-size: 1em;
    line-height: 1.6em;
    color: $white;
    text-decoration: underline;
  }
}

%node_header_title {
  @include font1();
  color: $white;
  font-size: px2em(50);
  line-height: px2em(68, 50);
  font-weight: $bold;
  text-transform: uppercase;
  padding-top: 0;
  margin: 75px 0 0 0;
  display: inline-block;

  &:first-letter {
    color: $blue-light;
  }

  @include bp('tablet') {
    margin-top: 55px;
    font-size: px2em(40);
  }

  @include bp('mobile') {
    font-size: px2em(30);
    line-height: px2em(36, 30);
    margin-top: 1em;
  }
}

@mixin node_header_icon_title($icon, $color:$blue-light) {
  @include icon($icon);
  padding-left: 130px;

  @include bp('tablet') {
    padding-left: 105px;
    margin-top: 65px;
  }

  @include bp('mobile') {
    padding-left: 0;
    margin-top: 0.8em;
  }

  &:first-letter {
    color: $white;
  }

  &:before {
    font-size: 110px;
    line-height: 55px;
    color: $color;

    @include bp('tablet') {
      font-size: 95px;
      line-height: 20px;
    }

    @include bp('mobile') {
      width: 100%;
      position: relative;
      font-size: 80px;
      line-height: 85px;
      margin-bottom: 15px;
    }
  }
}

%node_header_description {
  font-size: px2em(20);
  line-height: px2em(27, 20);
  width: 67%;
  margin: 30px auto 0;

  @include bp('tablet') {
    font-size: px2em(18);
    line-height: px2em(23, 18);
  }

  @include bp('mobile') {
    width: 83%;
    margin-top: 20px;
    font-size: px2em(16);
    line-height: px2em(22, 16);
  }

  p:last-child {
    margin-bottom: 0;
  }
}

%node_cover {
  @include fullimage;
  margin-bottom: 50px;
}

%image_caption {
  .caption {
    font-size: px2em(15);
    line-height: px2em(17, 12);

    a {
      color: $blue-light;
      font-size: inherit;
      line-height: inherit;
    }
  }
}

%node_cc {
  div, a {
    @include font1;
    @include font_size_line_height(15, 22);
    font-style: italic;
    font-weight: $regular;
  }
  div {
    color: $text1;
  }
  a {
    color: $blue-light;
  }
}

.m-global_node_cc {
  @extend %node_cc;
}

%node_sidebarelement {
  margin-bottom: 60px;
}

$teaser_box_padding: 45px;

%node_teaser_box_inner {
  height: calc(100% - 80px);
  padding: 40px $teaser_box_padding;
  position: relative;
  z-index: 2;
}

%node_oldstyle_date {
  font-weight: $regular;
  display: inline-block;
  div {
    display: inline-block;
  }
}

%node_oldstyle_authors {
  font-weight: $regular;

  a {
    font-weight: $regular;
    color: $blue-light;
  }

  display: inline-block;

  div {
    display: inline-block;
  }
}

%node--oneline-tags-socials {
  overflow: hidden;
  margin-bottom: 30px;
}

%node--oneline-tags {
  float: left;
  width: calc(100% - 120px);
  display: inline-block;
  margin-top: 6px;

  @include bp('mobile') {
    margin-top: 3px;
  }
}

%node--oneline-socials {
  float: right;
  width: 120px;
  display: inline-block;
}

%node_browsing {
  margin: 50px auto 0 auto;
  text-align: center;

  @include bp('print') {
    display: none;
  }

  a {
    @extend %form_submit;
    display: inline-block;
    line-height: 225%;
    height: 36px;

    & + a {
      margin-left: 20px;
    }
  }
}
