.m-node--article--full {
  @extend %node;
  overflow: hidden;
}

.m-node--article--full__content_main {
  @extend %content__main;
}

.m-node--article--full__content_sidebar {
  @extend %content__sidebar;
}

.m-node--article--full__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 48px;
  display: inline-block;

  @include bp('mobile') {
    left: 0;
    margin-bottom: 20px;
  }

  @include bp('print') {
    display: none;
  }

  a {
    &:before {
      content: '\EA0E';
    }
  }
}

.m-node--article--full__printbar {
  @extend %node_printbar;
  right: 0;
  top: 20px;
}

.m-node--article--full__tags-socials {
  @extend %node--oneline-tags-socials;
}

.m-node--article--full__tags {
  @extend %node--oneline-tags;
}

.m-node--article--full__socials {
  @extend %node--oneline-socials;
}

.m-node--article--full__authors {
  @extend %node_oldstyle_authors;
}

.m-node--article--full__author + .m-node--article--full__author {
  &:before {
    content: ', ';
    color: $blue-light;
  }
}

.m-node--article--full__date-authors {
  @include bp('mobile') {
    font-size: px2em(12);
    line-height: px2em(17, 12);

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.m-node--article--full__date {
  @extend %node_oldstyle_date;
}

.m-node--article--full__location {
  display: inline-block;

  div {
    display: inline-block;
  }
}

.m-node--article--full__teaser_image {
  img {
    width: 100%;
    height: auto;
  }
}

.m-node--article--full__teaser_image_title {
  @include teaser_image_title;
}

.m-node--article--full__body {
  @extend %tinymce-styles;

  @include bp('mobile') {
    font-size: px2em(14);
    line-height: px2em(21, 14);
  }
}

.m-node--article--full__teaser_image {
  margin: 1.5em 0 1em;
  border: 4px solid #EEEFF2;

  @include bp('print') {
    border: none;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.m-node--article--full__teaser_image + .m-node--article--full__teaser_image_title {
  margin-top: -1em;
}

.m-node--article--full__browsing {
  @extend %node_browsing;
}

.m-node--article--full__title {
  @extend %node_title;
  text-align: left;
}

.m-node--article--full__teaser p,
.m-node--article--full__teaser {
  @extend %node_teaser;
  text-align: left;
}

.m-node--article--full__cc {
  margin-top: 20px;
}
