form.search-form {
  label {
    display: none;
  }

  input[type="submit"] {
    background-color: $blue-light;
    color: $white;
    border: none;
    border-radius: 2em;
    padding: .6em 1.5em;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
  }
}

.m-webform__apachesolrsearchcustompagesearchform {
  width: $inner_head_width;
  margin: 0 auto;
  padding-top: 44px;
  margin-bottom: 55px;

  &:after {
    content: '';
    display: block;
    width: 510px;
    height: 3px;
    background-color: $blue-light;
    margin: 45px auto 0 auto;
  }

  .m-searchbase__keys .icon {
    top: 2px;
  }

  @include bp('tablet') {
    width: $tablet_width;
  }

  @include bp('mobile') {
    width: 100%;
    margin-bottom: 20px;

    &:after {
      width: 50%;
      margin-top: 20px;
    }
  }
}
