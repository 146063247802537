
ul.primary,
.action-links {
  @extend %ul;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2f2d3a;
  width: 100%;
  border: 0;
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  li {
    @extend %list_item;
    display: inline-block;
    background-color: transparent;
    a {
      background-color: transparent;
      display: block;
      color: #fff;
      border: 0;
      margin: 0;
      padding: .3em 2em;

      &:hover {
        background-color: lighten(#2f2d3a, 10);
      }
    }

    &.active {
      a {
        background-color: $blue-light;
        color: #fff;
        border: none!important;
      }
    }
  }

  @include bp('print') {
    display: none;
  }
}

.action-links {
  left: auto;
  width: auto;
}

ul.primary li.active a {
  border: none;
  background-color: $blue-light;
}

#admin-menu {
  a {
    font-size: 1em;
  }

  %form_input {
    height: auto;
  }
}

.slicknav_menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  > .slicknav_btn {
    font-size: 13px;
    padding: 8px 34px;
    background-position: 8px 7px;
  }
}


.page-node-edit {
  .field-name-field-news-digest-entries {
    display: none;
  }
}
