$pheight: 36px;

.m-searchresults__pager {
  text-align: center;
  margin-bottom: 70px;

  @include bp('mobile') {
    margin-bottom: 35px;
  }

  .item-list {
    @include clearfix;
  }

  ul.pager {
    margin: 0 auto;
    height: $pheight;
    vertical-align: baseline;

    @include bp('mobile') {
      height: auto;
    }

    li {
      margin: 0 0 0 1px;
      padding: 0;
      display: inline-block;
      height: $pheight;

      @include bp('mobile') {
        margin-bottom: 10px;
      }

      a,
      &.pager-current {
        padding: 6px 15px;
        line-height: 1.5;
        color: #999;
        background-color: #f0f0f0;
        border: none;
        border-radius: 0;
      }

      &.pager-current,
      a:hover {
        background: #20b6e9;
        color: #fff;
      }

      a {
        height: 20px;
        vertical-align: bottom;
        padding-bottom: 9px;
      }

      &.pager-current {
        padding-top: 3px;
        padding-bottom: 3px;
        height: $pheight - 6px;
      }
    }
  }
}

.page-search123,
.page-s111 {
  .select2-container {
    .select2-choice {
      border-color: #20b6e9;
      padding-top: 0.2em;
      padding-bottom: 0.2em;
      height: auto;
      line-height: auto;
      padding-left: 20px;

      .select2-arrow {
        top: 2px;
        right: 7px;
      }
    }
    &.select2-dropdown-open .select2-choice {
      -webkit-box-shadow: 0 0 0 #fff inset;
      box-shadow: 0 0 0 #fff inset;
    }
  }

  .region-content {
    h2 {
      display: none;
    }
    #type-filter {
      margin: 1em 0;
      clear: both;
      overflow: hidden;
      ul {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
        li {
          margin: 0 0 1px 0;
          padding: 0;
          float: left;
          list-style: none;
          width: 25%;
          text-align: center;
          &[data-count="0"] {
            display: none;
          }
          &:nth-child(5n) {
            margin-right: 0;
          }
          a {
            display: block;
            background: #20b6e9;
            color: #fff;
            padding: .5em 0 .4em;
            margin: 0 1px 0 0;
            font-size: .9em;
            text-decoration: none;
          }
          &.active,
          &:hover {
            a {
              background: #122f4d;
            }
          }
        }
      }
    }
  }
  .search-form {
    position: relative;
    div {
      display: block;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 20%;
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      padding: .6em 0;
      background: #a30b2f;
      color: #fff;
      font-weight: 300;
      text-transform: uppercase;
      margin: 0;
      text-align: center;
      font-size: 1em;
    }

    input.form-text {
      display: inline-block;
      background: #f0f0f0;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
      border: none;
      padding: .85em 1em .8em;
      margin: 0 0 0 20%;
      font-size: 1em;
      width: 80%;
    }
    input[type=submit] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      border: none;
      background: url(../../img/icons/search.png) no-repeat center center;
      background-size: auto 50%;
      width: 3.5em;
      text-indent: -9999px;
    }
  }
  ol.search-results {
    margin: 0;
    padding: 0;
    li.search-result {
      margin: 0;
      padding: 1.5em 0;
      border-bottom: 1px solid #eaeaea;
      position: relative;
      &:last-child {
        border: none;
      }
      .snippet {
        display: none;
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        padding: 1.5em 2em;
      }
      &.active {
        .snippet {
          display: block;
        }
      }
      h3 {
        margin: 0 0 .2em;
      }
      p {

      }
      a {
        color: #333;
      }
      .meta {
        font-size: .9em;
      }
      .meta,
      .tags {
        display: inline-block;
        color: #888;
        * {
          display: inline-block;
          color: #888;
        }
      }
    }
  }

  .facetapi-bonus-select-facet {
    .select2-container {
      a {
        &.select2-default {
          color: #FFF !important;
        }
      }
      .select2-choice {
        color: #FFF;
        background: #20b6e9;
        .select2-arrow {
          background-color: transparent;
        }
      }
      .select2-search-choice-close {
        background-image: url(/sites/all/themes/clew/img/icons/select2-white.png);
        right: 30px;
      }
    }
  }
}

.page-search,
.page-s {
  .wrapper {
    position: relative;
    background: #fff;
    padding: 2.5em 0 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    margin-top: 2em;
    float: left;
    width: 100%;

    .region-content {
      float: left;
      width: 65%;
      box-sizing: border-box;
      padding: 4em 6em 3em 4em;
      border-right: 1px solid #f0f0f0;
    }
  }
}

.page-glossary,
.page-grid-type-articles-archive {
  .wrapper {
    position: relative;

    .region-content {
      float: left;
      width: 65%;
      box-sizing: border-box;
      padding: 0 6em 3em 0;
      border-right: 1px solid #f0f0f0;
    }
  }
}

.page-glossary,
.page-grid-type-articles-archive,
.page-search,
.page-s {
  .wrapper {
    .sidebar {
      float: right;
      box-sizing: border-box;
      padding: 5.5em 2em 2em 2em;
      width: 35%;
      .sidebar-block,
      .block {
        float: left;
        width: 100%;
        .content {
          overflow: hidden;
          clear: both;
        }
      }
      .block {
        margin-bottom: 2em;
        &#block-block-6 {
          margin-bottom: 0;
        }
      }
      .block-facetapi {
        &.content-type {

        }
      }

      ul {
        padding: 0;
        margin: 0;
        li {
          padding: .5em 0;
          margin: 0;
          border-bottom: 1px solid #eaeaea;
          list-style: none;
          &:last-child {
            border: none;
          }
        }
      }

    }
  }

  .contact-box {
    background: #20b6e9;
    padding: 1.5em 2em 2em;
    margin: 0 0 2em -2em !important;
    clear: both;
    overflow: hidden;
    p,
    span,
    strong,
    div {
      color: #fff;
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
    p:last-child {
      margin: 0;
    }
    h3 {
      position: relative;
      color: #fff;
      background: #1ea5d5;
      display: inline-block;
      font-weight: 100;
      width: auto;
      padding: .5em 1em .4em 3.4em;
      margin: 0 0 1em -1.52em;
      background-repeat: no-repeat;
      background-position: 1.6em center;
      background-size: auto 65%;
      background-image: url(../../img/icons/contact-white.png);
      &:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        border-left: 0.4em solid #1ea5d5;
        border-bottom: 2.4em solid transparent
      }
    }
  }

  #block-block-19 {
    margin-left: -2em;
    .content {
      margin-right: -2em;
      .row.row-fluid {
        font-size: 15px;
        margin-right: -1em;
        .inner.equalized {
          padding-left: 0;
          padding-right: 1em;
          .content {
            margin-left: 0;
            margin-right: 0;
            right: 1em;
          }
        }
      }
    }
  }

}

@media (max-width: 768px) {
  .page-glossary,
  .page-grid-type-articles-archive {
    .wrapper {
      .region-content {
        float: none;
        width: 100%;
        padding-right: 0;
      }
    }
  }
  .page-glossary,
  .page-grid-type-articles-archive,
  .page-search,
  .page-s {
    .wrapper {
      .sidebar {
        float: none;
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        clear: both;
        overflow: hidden;

        #block-block-19 {
          margin-left: 0;

          .content {
            margin-right: 0;
          }
        }

        .sidebar-block.light-blue.contact-box {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

.ac_results {
  padding: 0px;
  border: none;
  background-color: #fff;
  overflow: hidden;
  z-index: 99999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.ac_results ul {
  width: 100%;
  list-style-position: outside;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ac_results li {
  margin: 0px;
  padding: 0.4em 1.2em;
  cursor: default;
  display: block;
  font: menu;
  font-size: 13px;
  line-height: 1.5em;
  overflow: hidden;
}

.ac_loading {

}

.ac_odd {
  background-color: #f0f0f0;
}

.ac_over {
  background-color: #A30B2F;
  color: #fff;
  .message {
    color: #eee;
  }
}

span.src {
  color: #a30b2f;
}
