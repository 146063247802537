.m-paragraph__textimage--small {
  @extend %m-paragraph__textimage;
}

.m-paragraph--textimage--small__title {
  @extend %m-paragraph--textimage__title;
}

.m-paragraph--textimage--small__inner {
  @extend %m-paragraph--textimage__inner;
}

.m-paragraph--textimage--small__text {
  @extend %m-paragraph--textimage__text;
  display: block;
}

.m-paragraph--textimage--small__image {
  @extend %m-paragraph--textimage__image;
  width: 25%;
  margin-bottom: 1em;
}
