.m-node--factsheet--full {
  @extend %node;
  overflow: hidden;

  @include bp('print') {
    margin-top: 0;
  }
}

.m-node--factsheet--full__content_main {
  @extend %content__main;
}

.m-node--factsheet--full__content_sidebar {
  @extend %content__sidebar;
}

.m-node--factsheet--full__content_bottom {
  width: 100%;
  float: left;
}

.m-node--factsheet--full__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 48px;

  @include bp('mobile') {
    left: 0;
    margin-bottom: 20px;
  }

  a:before {
    content: $icon-factsheet;
  }
}

.m-node--factsheet--full__printbar {
  @extend %node_printbar;
  right: 0;
  top: 20px;
}

.m-node--factsheet--full__teaser {
  @extend %node_teaser;
  text-align: left;
}

.m-node--factsheet--full__paragraphs {
  @include node_ctypes_with_sidebar;

  @include bp('mobile') {
    font-size: px2em(14);
    line-height: px2em(21, 14);
  }
}

.m-node--factsheet--full__dossier_references {
  @extend %sidebars-list__block;
}

.m-node--factsheet--full__dossier_references_title {
  @extend %stamp_dossiers;
}

.m-node--factsheet--full__dossier_references_content {
  @extend %sidebars-list__content;
}

.m-node--factsheet--full__dossier_references_text {
  display: inline-block;
  font-weight: $bold;
  color: $text1;
  @include font-size-line_height(18, 25);
  padding-bottom: 20px;
}

.m-node--factsheet--full__dossier_references_list {
  @extend %sidebars_item_list;
}

.m-node--factsheet--full__dossier_references_list_item {
  display: block;
  padding-bottom: 10px;
  a {
    font-weight: $regular;
    color: $blue-light;
  }
}

.m-node--factsheet--full__authors {
  @extend %node_oldstyle_authors;
  &:before {
    content: ' | ';
  }
}

.m-node--factsheet--full__author {
  &:not(:last-child) {
    &:after {
      content: ', ';
      color: $blue-light;
    }
  }
}

.m-node--factsheet--full__date-authors {
  @include bp('mobile') {
    font-size: px2em(12);
    line-height: px2em(17, 12);

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.m-node--factsheet--full__date {
  @extend %node_oldstyle_date;
}

.m-node--factsheet--full__location {
  display: inline-block;

  div {
    display: inline-block;
  }
}

.m-node--factsheet--full__tags-socials {
  @extend %node--oneline-tags-socials;
}

.m-node--factsheet--full__tags {
  @extend %node--oneline-tags;

  @include bp('mobile') {
    font-size: px2em(16);
    line-height: px2em(26, 16);

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.m-node--factsheet--full__socials {
  @extend %node--oneline-socials;
}

.m-node--factsheet--full__title {
  @extend %node_title;
  text-align: left;
}
