.m-paragraph--featuredreference {
  @extend %paragraph;
}

.m-paragraph--featuredreference__title {
  @extend %paragraphs_title;
  margin-top: 0;
  margin-bottom: 14px;
}

.m-paragraph--featuredreference__teaser {
  @extend %paragraph_text;
  @include font2();
}

.m-paragraph--featuredreference__content {
  width: 492px;
  float: left;
  margin-right: 25px;
  border-top: 1px solid $grey3;
  padding-top: 38px;

  @include bp('tablet') {
    width: calc(100% - 300px);
  }

  @include bp('mobile') {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 22px;
  }
}

.m-paragraph--featuredreference__references {
  width: 311px;
  height: 311px;
  float: left;
  margin-top: -23px;
  margin-bottom: -23px;

  @include bp('print') {
    display: none;
  }

  > .field {
    height: 100%;
    > .field-items {
      height: 100%;
      > .field-item {
        height: 100%;
      }
    }
  }

  .m-node--article--teaserbox__date {
    display: none;
  }

  .m-node--dossier--teaserbox__date {
    display: none;
  }

  @include bp('tablet') {
    width: 260px;
    height: 260px;
  }

  @include bp('mobile') {
    width: 100%;
    float: none;
    margin-top: 0;
    margin-bottom: 0;
  }
}
