.m-news-frontpage {

}

.m-news-frontpage__listwrapper {
  position: relative;

  .tns-controls {
    position: absolute;
    right: 85px;
    top: -8px;
    z-index: 1;

    @include bp('tablet') {
      right: calc((100% - #{$tablet_width})/2);
    }

    @include bp('mobile') {
      right: calc((100% - #{$mobile_width})/2);
    }

    button {
      @include icon($icon-arrow-with-line);

      border: 0;
      background-color: transparent;
      font-size: 0;
      width: 24px;
      height: 24px;
      padding: 0;

      &:before {
        color: $black;
        font-size: 24px;
        top: auto;
        bottom: 0;
        line-height: 1em;
        transform: rotate(-180deg);
        transition: all 0.2s ease-in-out;
      }

      &[data-controls="next"] {
        margin-left: 10px;

        &:before {
          transform: rotate(0deg);
        }
      }

      &:hover {
        &:before {
          color: $blue-light;
        }
      }
    }
  }

  .tns-ovh {
    @include bp('tablet') {
      $news-frontpage-ovh-margin-tablet: calc(((100% - #{$tablet_width})/2) - 17px);

      width: calc(100% - 17px - #{$news-frontpage-ovh-margin-tablet});
      padding-left: 17px;
      margin-left: $news-frontpage-ovh-margin-tablet;
    }

    @include bp('mobile') {
      $news-frontpage-ovh-margin-mobile: calc(((100% - #{$mobile_width})/2) - 17px);

      width: calc(100% - 17px - #{$news-frontpage-ovh-margin-mobile});
      margin-left: $news-frontpage-ovh-margin-mobile;
    }
  }
}

.m-news-frontpage__list {
  @extend %ul;

  display: flex;

  @include bp('tablet') {
    margin-left: -17px;
  }

  @include bp('mobile') {
    padding-top: 35px;
  }
}

.m-news-frontpage--item {
  @extend %list_item;

  padding: 40px 0;

  &[data-type="highlight"] {
    padding: 0;
  }
}

.m-news-frontpage__inner {
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background-color: $white;
  height: calc(100% - 80px);
  margin: 0 17px;

  @include bp('tablet') {
    padding: 35px;
    height: calc(100% - 70px);
  }

  .m-news-frontpage--item[data-type="highlight"] & {
    padding: 50px;
    box-shadow: none;
    background-color: $blue-dark;
    color: $white;
    height: calc(100% - 100px);

    @include bp('tablet') {
      padding: 40px;
      height: calc(100% - 80px);
    }

    @include bp('mobile') {
      padding: 35px;
      height: calc(100% - 70px);
    }
  }
}

.m-news-frontpage__type {
  display: inline-block;
  font-size: px2em(15);
  line-height: 1.55;
  padding: 0 10px;
  border-radius: 2px;
  background-color: $blue-light;
  color: $white;
  margin-bottom: 29px;
}

.m-news-frontpage__date {
  font-size: px2em(15);
  line-height: 1.55;
  margin-bottom: 15px;
}

.m-news-frontpage__title {
  margin: 0 0 12px 0;

  @include bp('mobile') {
    margin-bottom: 0;
  }

  a {
    @include icon($icon-arrow-with-line);
    @include font2();

    display: inline-block;
    font-size: px2em(20, 20);
    line-height: 1.25;
    font-weight: $bold;
    letter-spacing: normal;
    transition: all 0.2s ease-in-out;
    position: relative;
    padding-bottom: 53px;

    &:before {
      color: $black;
      font-size: 24px;
      top: auto;
      bottom: 0;
      line-height: 1em;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      color: $blue-light;

      &:before {
        color: $blue-light;
        left: 20px;
      }
    }

    .m-news-frontpage--item[data-type="highlight"] & {
      color: $white;
      font-size: px2em(26, 20);
      line-height: 1.5;

      &:before {
        color: $white;
      }

      &:hover {
        color: $blue-light;

        &:before {
          color: $blue-light;
          left: 20px;
        }
      }
    }
  }
}
