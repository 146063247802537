.m-form--application__team,
.m-node--webform#node-2460 {
    padding-top: 40px;

    #webform-client-form-2460 {

        .form-required {
            color: #333;
        }

        .webform-component-markup {
            p {
                margin-top: 0;
            }
        }

        > div {

            .webform-component-email,
            .webform-component-select,
            .webform-component-textfield,
            .webform-component-checkboxes {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                @include bp('mobile') {
                    display: block;
                }

                label {
                    padding-top: 0.2em;
                    color: $text2;
                }
            }

            > .webform-component-textarea {
                textarea {
                    height: 200px;
                }
            }

            > .webform-component-textfield {
                label {
                    display: block;
                    width: 100%;
                }
                input {
                    width: 100%;
                }
            }

            > .webform-component-checkboxes {
                margin: 0;

                .element-invisible {
                    display: none;
                }

                .form-checkboxes {
                    .form-type-checkbox {
                        clear: both;
                        overflow: hidden;

                        input {
                            float: left;
                            width: 30px;
                        }
                        label {
                            float: left;
                            width: 85%;
                        }
                    }
                }
            }

            > .webform-component-fieldset {
                margin: 0 0 2em 0;
                padding: 0 1.5em 1.5em 1.5em;

                legend {
                    margin-bottom: 0;
                    margin-top: 1.5em;

                    .fieldset-legend {
                        padding: 0 10px;
                        font-weight: $bold;
                    }
                }

                .webform-component-fieldset {
                    margin: 15px 0 0 0;
                    padding: 0;
                    border: 0;
                    background-color: transparent;

                    legend {
                        font-size: 1em;
                        padding-bottom: 5px;

                        .fieldset-legend {
                            padding: 0 0 10px 0;
                            font-weight: $semi-bold;
                            color: $text2;

                            @include bp('mobile') {
                                font-size: px2em(14);
                                line-height: px2em(18, 14);
                            }
                        }
                    }

                    .fieldset-description {
                        font-weight: 300;
                    }
                }

                .webform-component-textfield {
                    .description {
                        padding-left: $webform-label-width;

                        @include bp('mobile') {
                            padding-left: 0;
                        }
                    }
                }

                .webform-component-textarea {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    @include bp('mobile') {
                        display: block;
                    }

                    label {
                        width: 20%;
                        margin-right: 2%;

                        @include bp('mobile') {
                            width: 93%;
                            margin-right: 0;
                        }
                    }
                    .form-textarea-wrapper {
                        width: 77.2%;
                        align-self: flex-start;

                        @include bp('mobile') {
                            width: 93%;
                        }
                    }
                    .description {
                        padding-left: $webform-label-width;
                        width: 78%;

                        @include bp('mobile') {
                            padding-left: 0;
                            width: 93%;
                        }
                    }

                    &#webform-component-team-member-1--media-outlets,
                    &#webform-component-team-member-2--media-outlets,
                    &#webform-component-team-member-3--media-outlets,
                    &#webform-component-team-member-4--media-outlets {
                        textarea {
                            height: 110px;
                        }
                    }
                    &#webform-component-team-member-1--topics,
                    &#webform-component-team-member-2--topics,
                    &#webform-component-team-member-3--topics,
                    &#webform-component-team-member-4--topics {
                        textarea {
                            height: 80px;
                        }
                    }
                }
            }

        }

        #webform-component-pitch,
        #webform-component-research-plan,
        #webform-component-budget-plan {
            @include bp('mobile') {
                label, textarea {
                    font-size: px2em(14);
                    line-height: px2em(18, 14);
                }
            }
        }

        #webform-component-team-member-3,
        #webform-component-team-member-4 {
            padding-bottom: 0.75em;

            > legend .fieldset-legend {
                @include icon($icon-arrow-down);
                padding-right: 37px;
                cursor: pointer;

                &:before {
                    color: $text1;
                    left: auto;
                    right: 10px;
                    font-size: 1.2em;
                    line-height: 1.2em;
                }
            }

            .fieldset-wrapper {
                display: none;
            }

            &.active {
                padding-bottom: 1.5em;

                > legend .fieldset-legend {
                    &:before {
                        content: $icon-arrow-up;
                    }
                }

                .fieldset-wrapper {
                    display: block;
                }
            }
        }

        #webform-component-team-member-1--social-media--note,
        #webform-component-team-member-2--social-media--note,
        #webform-component-team-member-3--social-media--note,
        #webform-component-team-member-4--social-media--note,
        #webform-component-team-member-1--social-media--other-social-media,
        #webform-component-team-member-2--social-media--other-social-media,
        #webform-component-team-member-3--social-media--other-social-media,
        #webform-component-team-member-4--social-media--other-social-media,
        #webform-component-team-member-1--clew-journalism-network--note,
        #webform-component-team-member-2--clew-journalism-network--note,
        #webform-component-team-member-3--clew-journalism-network--note,
        #webform-component-team-member-4--clew-journalism-network--note,
        #webform-component-team-member-1--professional-profile--freelance-journalist,
        #webform-component-team-member-2--professional-profile--freelance-journalist,
        #webform-component-team-member-3--professional-profile--freelance-journalist,
        #webform-component-team-member-4--professional-profile--freelance-journalist,
        #webform-component-team-member-1--professional-profile--working-time,
        #webform-component-team-member-2--professional-profile--working-time,
        #webform-component-team-member-3--professional-profile--working-time,
        #webform-component-team-member-4--professional-profile--working-time {
            padding-left: $webform-label-width;

            @include bp('mobile') {
                padding-left: 0;
                font-size: px2em(14);
                line-height: px2em(18, 14);

                a {
                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }

        #webform-component-the-story,
        #webform-component-on-data-protection,
        #webform-component-team-member-1--recent-work,
        #webform-component-team-member-2--recent-work,
        #webform-component-team-member-3--recent-work,
        #webform-component-team-member-4--recent-work {
            @include bp('mobile') {
                input,
                .webform-component-markup {
                    font-size: px2em(14);
                    line-height: px2em(18, 14);
                }
            }
        }

        #webform-component-team-member-1--clew-journalism-network--network-member,
        #webform-component-team-member-2--clew-journalism-network--network-member,
        #webform-component-team-member-3--clew-journalism-network--network-member,
        #webform-component-team-member-4--clew-journalism-network--network-member {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-bottom: 0;

            label {
                width: 20%;
                margin-right: 2%;
                color: $text2;

                @include bp('mobile') {
                    width: 93%;
                    margin-right: 0;
                    font-size: px2em(14);
                    line-height: px2em(18, 14);
                }
            }
            .form-radios {
                width: 77.2%;
                align-self: flex-start;

                @include bp('mobile') {
                    width: 93%;
                }
            }
        }

        #webform-component-team-member-1--professional-profile--working-time,
        #webform-component-team-member-2--professional-profile--working-time,
        #webform-component-team-member-3--professional-profile--working-time,
        #webform-component-team-member-4--professional-profile--working-time {
            .form-text {
                width: 25px;
                margin: 0 7px;
                padding: 3px 1%;
            }
        }

        #webform-component-team-member-1--professional-profile--topics,
        #webform-component-team-member-2--professional-profile--topics,
        #webform-component-team-member-3--professional-profile--topics,
        #webform-component-team-member-4--professional-profile--topics,
        #webform-component-team-member-1--professional-profile--media-outlets,
        #webform-component-team-member-2--professional-profile--media-outlets,
        #webform-component-team-member-3--professional-profile--media-outlets,
        #webform-component-team-member-4--professional-profile--media-outlets,
        #webform-component-submit-your-teams-application--flag-of-your-application,
        #webform-component-submit-your-teams-application--find-out-about-grant {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            @include bp('mobile') {
                display: block;
            }

            label {
                width: 20%;
                margin-right: 2%;
                color: $text2;

                @include bp('mobile') {
                    font-size: px2em(14);
                    line-height: px2em(18, 14);
                }

                @include bp('mobile') {
                    width: 93%;
                    margin-right: 0;
                }
            }
            .form-textarea-wrapper {
                width: 77.2%;
                align-self: flex-start;

                @include bp('mobile') {
                    width: 93%;
                    font-size: px2em(14);
                    line-height: px2em(18, 14);
                }
            }
            .description {
                padding-left: $webform-label-width;
                width: 78%;

                @include bp('mobile') {
                    padding-left: 0;
                    width: 93%;
                }
            }
        }

        #webform-component-team-member-1--professional-profile--working-time,
        #webform-component-team-member-2--professional-profile--working-time,
        #webform-component-team-member-3--professional-profile--working-time,
        #webform-component-team-member-4--professional-profile--working-time {
            flex-wrap: wrap;

            .description {
                width: 100%;
                padding-left: 0;
            }
        }

        #webform-component-team-member-1--professional-profile--how-long-number,
        #webform-component-team-member-2--professional-profile--how-long-number,
        #webform-component-team-member-3--professional-profile--how-long-number,
        #webform-component-team-member-4--professional-profile--how-long-number {
            position: relative;

            select {
                width: 10em;
            }

            .select2-container {
                width: 8em;
            }

            #webform-component-team-member-1--professional-profile--how-long-type,
            #webform-component-team-member-2--professional-profile--how-long-type,
            #webform-component-team-member-3--professional-profile--how-long-type,
            #webform-component-team-member-4--professional-profile--how-long-type {
                margin: 0 0 0 1em;
                align-self: flex-start;

                @include bp('mobile') {
                    margin: 1em 0 0 0;
                }
            }
        }

        #webform-component-your-details {
            margin-top: 2em;
        }

        #webform-component-data-protection {
            margin-top: 1.5em;
        }

        #webform-component-informed {
            .description {
                color: $text1;
            }
        }
    }

}
