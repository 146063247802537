%button {
  display: inline-block;
  background-color: $red-light;
  color: $white;
  text-align: center;
  font-size: px2em(15, 16);
  font-weight: $semi-bold;
  line-height: 1.55;
  text-transform: none;
  border-radius: 20px;
  padding: 9px 20px;
  height: 23px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $red-dark;
  }
}

%button--white {
  @extend %button;

  color: $blue-dark;
  background-color: $white;

  &:hover {
    color: $white;
    background-color: $red-light;
  }
}
