.m-ajax-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba($white, $bg_opacity);
  background-image: url("/sites/all/themes/cleanenergywire/img/loading.gif");
  background-position: center 50px;
  background-repeat: no-repeat;
  background-size: 50px;
}
