.m-content--digest-entry--full {
  overflow: hidden;
  margin-top: 50px;
}

.m-content--digest-entry--full__content_main {
  @extend %content__main;
}

.m-content--digest-entry--full__content_sidebar {
  @extend %content__sidebar;
}

.m-content--digest-entry--full__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 48px;
  display: inline-block;

  a {
    &:before {
      content: '\EA0E';
    }
  }
}

.m-content--digest-entry--full__printbar {
  @extend %node_printbar;
  right: 0;
  top: 20px;
}

.m-content--digest-entry--full__tags-socials {
  overflow: hidden;
  margin-bottom: 30px;
}

.m-content--digest-entry--full__tags {
  float: left;
  width: calc(100% - 120px);
  display: inline-block;
  margin-top: 6px;
}

.m-content--digest-entry--full__topline {
  font-weight: $bold;
  @include font_size_line_height(18, 24);
}

.m-content--digest-entry--full__socials {
  float: right;
  width: 120px;
  display: inline-block;
}

.m-content--digest-entry--full__date {
  font-weight: $regular;
  a {
    font-weight: $regular;
    color: $blue-light;
  }
  display: inline-block;
  div {
    display: inline-block;
  }
}

.m-content--digest-entry--full__text {
  @include content_formater;
  margin-bottom: 50px;
}

.m-content--digest-entry--full__sidebar_digest {
  @extend %sidebars-list__block;
}

.m-content--digest-entry--full__sidebar_digest_type {
  @extend %sidebars-list__title;
  &:before {
    content: $icon-news;
  }
}

.m-content--digest-entry--full__sidebar_digest_link {
  padding: 0 0 0 7%;
  font-weight: $regular;
  color: $blue-light;
}
