.lexicon-admin-links {
    ul.links {
        margin: 0 0 40px 0;
        padding: 0;
        list-style: none;
        clear: both;
        overflow: hidden;

        li {
            float: left;
            background-color: $red-light;
            border-left: 1px solid $white;

            a {
                display: inline-block;
                padding: 0.5em 1.5em;
                color: $white;
                font-weight: $semi-bold;

                &:hover {
                    text-decoration: none;
                    background-color: $red-dark;
                }
            }
        }
    }
}

.m-lexicon--alphabar {
    background-color: $blue-light;
    color: $blue-dark;
    text-transform: uppercase;
    font-size: px2em(18);
    line-height: px2em(20, 18);
    margin-bottom: 30px;
    padding: 12px 0 13px 0;
    font-weight: $semi-bold;
    text-align: center;

    a {
        display: inline-block;
        color: #fff;
        padding: 0;
        font-size: px2em(18, 18);
        line-height: px2em(20, 18);
        font-weight: $semi-bold;

        &.first {
            text-transform: none;
        }

        &.active {
            text-decoration: underline;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    span, a {
      @include bp('mobile') {
        padding: 8px 13px;
        margin-bottom: 10px;
        width: 1.4em;
        display: inline-block;
      }
    }
}

.m-lexicon--terms {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin-top: 0.6em;
            padding-top: 0.6em;
            padding-left: 16px;
            font-size: 1.05em;
            border-top: 2px solid $grey3;

            &.views-row-first {
                padding-top: 0;
                border-top: 0;
                margin-top: 0;
            }

            a {
                color: $blue-light;
                font-weight: $semi-bold;

                &:hover {
                    color: $blue-dark;
                }
            }
        }
    }
}

.m-lexicon--list {
    padding: 1em 0 2.5em;
}

.m-lexicon--items {
    margin: 0;
    padding: 0;
    list-style: none;

    .m-lexicon--item {
        margin: 23px 0 0 0;
        padding: 26px 0 0 16px;
        border-top: 2px solid $grey3;

        &:first-child {
            margin-top: 0;
            padding-top: 0;
            border-top: 0;
        }

        a {
            color: $blue-light;

            &:hover {
                color: $blue-dark;
            }
        }

        .m-lexicon--item-title {
            font-size: px2em(22);
            font-weight: $regular;
            margin: 0;
        }

        .m-lexicon--item-extralinks {
            float: right;

            a {
                background-color: transparent;
                font-size: 0.7em;
            }
        }

        .m-lexicon--item-description {
            clear: both;

            p:last-child {
                margin-bottom: 0;
            }
        }
    }
}
