.m-node--interestpoint--full {
  overflow: hidden;
  padding-top: 100px;
}

.m-node--interestpoint--full__content_main {
  @extend %content__main;

  header {
    padding-bottom: 80px;
  }
}

.m-node--interestpoint--full__content_sidebar {
  @extend %content__sidebar;
}

.m-node--interestpoint--full__type {
  @extend %node_icon;
  top: 0;
  a:before {
    content: $icon-infobox;
  }
}

.m-node--interestpoint--full__printbar {
  @extend %node_printbar;
  top: 10px;
  right: 0;
}

.m-node--interestpoint--full__body {
  @include content_formater();
}

.m-node--interestpoint--full__title {
  @extend %node_title;
  text-align: left;
}

.m-node--interestpoint--full__address {
  margin-bottom: 50px;
  > div {
    @include font1;
    font-weight: $regular;
  }
}

.m-node--interestpoint--full__city,
.m-node--interestpoint--full__zip {
  display: inline-block;
}

.m-node--interestpoint--full__tags-socials {
  margin-bottom: 20px;
}
