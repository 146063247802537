/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  //overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

#colorbox {
  border-radius: 0;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxBottomRight, #cboxBottomCenter, #cboxBottomLeft {
  display: none;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background-color: rgba($grey3, 0.7);
}

#colorbox {
  outline: 0;
}

#cboxTopLeft {
  width: 0;
  height: 0;
}

#cboxTopRight {
  width: 0;
  height: 0;
}

#cboxBottomLeft {
  width: 0;
  height: 0;
}

#cboxBottomRight {
  width: 0;
  height: 0;
}

#cboxMiddleLeft {
  width: 0;
}

#cboxMiddleRight {
  width: 0;
}

#cboxTopCenter {
  height: 0;
}

#cboxBottomCenter {
  height: 0;
}

#cboxContent {
  background: #fff;
  //overflow: hidden;
  margin-bottom: 0;
}

.cboxIframe {
  background: #fff;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxLoadedContent {
  margin-bottom: 38px !important;
}

#cboxTitle {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  width: 85%;
  color: #949494;
  padding-left: 130px;
  padding-top: 5px;
  margin-bottom: 2px;
  height: 31px;
  overflow: hidden !important;

  @include bp('tablet') {
    display: none !important;
  }
}

#cboxCurrent {
  position: absolute;
  bottom: 6px;
  left: 58px;
  color: #949494;
  width: 70px;
}

#cboxLoadingOverlay {

}

#cboxLoadingGraphic {

}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  bottom: 4px;
  right: 30px;
  color: #0092ef;
}

#cboxPrevious {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 25px;
  height: 15px;
  margin-bottom: 10px;
  text-indent: -9999px;
}

#cboxNext {
  position: absolute;
  bottom: 2px;
  left: 27px;
  width: 25px;
  height: 15px;
  margin-bottom: 10px;
  text-indent: -9999px;
}

#cboxClose {
  background: none;
  @include icon('\EA06');

  position: absolute;
  top: -20px;
  right: -20px;
  background-color: $red-light;
  color: $white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid $white;
  text-indent: -9999px;

  -moz-box-shadow: 2px 2px 10px #333;
  -webkit-box-shadow: 2px 2px 10px #333;
  box-shadow: 2px 2px 10px #333;

  &:before {
    color: $white;
    font-size: 22px;
    left: 7px;
    top: 2px;
  }
}

#cboxClose:hover {
  background-position: -25px -25px;
}

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
}
