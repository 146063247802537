#cboxLoadedContent {
  margin-bottom: 0;

  form {
    background-color: $red-light;
    padding: 30px 50px;
  }

  .webform-confirmation {
    background-color: $red-light;
    padding: 12px 30px;
    color: $white;

    p {
      margin: 0;
    }
  }
}
