$font_color: $text1;

$mail: $text2;
$mail_hover: $text1;

%share_icon {
  width: px2em(25);
  height: 2em;
  display: inline-block;
  margin-right: 8px;

  @include bp('mobile') {
    margin-right: 0px;
  }

  a {
    @include icon('\EA0C');
    font-size: px2em(26);
    display: block;

    &::before {
      color: $font_color;
      font-size: 26px;

      @include bp('mobile') {
        font-size: 20px;
        top: 0 !important;
      }
    }

  }
}

.m-socialmedia-share {
  @include clearfix;
  text-align: center;

  @include bp('mobile') {
    text-align: right;
  }

  @include bp('print') {
    display: none;
  }
}

.m-socialmedia-share__facebook {
  @extend %share_icon;
  width: px2em(16);

  a {
    &:before {
      content: $icon-facebook;
      color: $facebook;
      transition: color 0.5s ease;
      font-size: 21px;
      top: 5px;

      @include bp('mobile') {
        font-size: 17px;
      }
    }
  }

  &:hover {
    a {
      &:before {
        color: $facebook_hover;
      }
    }
  }
}

.m-socialmedia-share__twitter {
  @extend %share_icon;
  width: px2em(20);

  a {
    &:before {
      content: $icon-twitter;
      color: $twitter;
      font-size: 20px;
      transition: color 0.5s ease;
      top: 6px;

      @include bp('mobile') {
        font-size: 18px;
      }
    }
  }

  &:hover {
    a {
      &:before {
        color: $twitter_hover;
      }
    }
  }
}

.m-socialmedia-share__linkedin {
  @extend %share_icon;
  margin-right: 9px;

  a {
    &:before {
      content: $icon-linkedin;
      color: $linkedin;
      font-size: 20px;
      transition: color 0.5s ease;
      top: 5px;

      @include bp('mobile') {
        font-size: 18px;
      }
    }
  }

  &:hover {
    a {
      &:before {
        color: $linkedin_hover;
      }
    }
  }
}

.m-socialmedia-share__mail {
  @extend %share_icon;

  a {
    &:before {
      content: '\EA0D';
      color: $mail;
      transition: color 0.5s ease;
      font-size: 29px;
      top: 1px;
    }
  }

  &:hover {
    a {
      &:before {
        color: $mail_hover;
      }
    }
  }
}

.m-socialmedia-share__printer {
  display: none;
}
