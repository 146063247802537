ul.m-sidebars--tabs {
    margin: 0 0 24px 0;
    padding: 0;
    float: left;
    width: 100%;
    overflow: hidden;

    li {
        list-style: none;
        float: left;
        margin-right: .7em;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            border-right: 0.4em solid $grey2;
            border-top: 3.4em solid transparent;
        }

        a {
            display: block;
            color: $text1;
            text-decoration: none;
            font-weight: 400;
            position: relative;
            background: $grey2;
            display: inline-block;
            width: auto;
            padding: 15px 10px 16px 43px;
            background-repeat: no-repeat;
            background-position: 2em center;
            text-transform: uppercase;
            font-weight: $semi-bold;
            font-size: px2em(15, 14);
            line-height: px2em(20, 15);

            @include icon($icon-factsheet);

            @include bp('tablet') {
                padding-top: 8px;
                padding-bottom: 8px;
            }

            &:before {
                top: 6px;
                left: 10px;
                font-size: 28px;
                color: $blue-light;

                @include bp('tablet') {
                    top: 2px;
                    font-size: 22px;
                }
            }

            &:hover {
                color: $text1;
            }

            &:after {
                content: '';
                position: absolute;
                left: 100%;
                top: 0;
                bottom: 0;
                border-left: 0.4em solid $grey2;
                border-bottom: 3.4em solid transparent;
            }
        }

        &.active {
            &:before {
                border-right-color: $blue-light;
            }

            a {
                background: $blue-light;
                color: $white;

                &:before {
                    color: $white;
                }

                &:after {
                    border-left-color: $blue-light;
                }
            }
        }

        &:first-child {
            a {
                 padding-left: 61px;

                &:before {
                    content: $icon-dossiers;
                    left: 27px;
                }
            }
        }
    }
}

.m-sidebars--tabs-content {
  float: left;
  display: none;

  @include bp('tablet') {
    float: none;
  }

  &.active {
      display: block;
  }
}
