.l-page--blogpost {
  @extend %page;
}

.l-page--blogpost__main {
  @extend %content__main;
}

.l-page--blogpost__sidebar {
  @extend %content__sidebar;
}
