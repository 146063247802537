.m-paragraph--events-trainings {
  margin-top: 30px;
}

.m-paragraph--events-trainings__inner {
  @include icon($icon-experts);
  @extend %data_icons;

  background-color: $white;
  padding: 52px 53px 36px 119px;
  border-radius: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  height: 100%;

  @include bp('tablet') {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 105px;
  }

  &:before {
    color: $blue-dark;
    font-size: 55px;
    line-height: 100%;
    top: 44px;
    left: 45px;

    @include bp('tablet') {
      top: 35px;
      left: 40px;
    }
  }
}

.m-paragraph--events-trainings__title {
  font-weight: $bold;
  font-size: px2em(26);
  line-height: px2em(39, 26);
  letter-spacing: normal;
  color: $blue-dark;
  margin: 0;
}

.m-paragraph--events-trainings__teaser {
  font-size: px2em(17);
  line-height: 1.55;
  margin-top: 24px;
}

.m-paragraph--events-trainings__links {
  margin-top: 25px;

  .field-items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  a {
    @extend %button;
  }
}

.m-paragraph--events-trainings__event {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 2px solid $grey2;

  @include bp('tablet') {
    padding-top: 35px;
  }

  @include bp('mobile') {
    margin-top: 35px;
    padding-top: 30px;
  }
}

.m-paragraph--events-trainings__manual-row {
  display: flex;
  gap: 20px;

  @include bp('smalldesktop') {
    flex-direction: column-reverse;
    gap: 10px;
    padding-top: 5px;
  }

  @include bp('tablet') {
    flex-direction: column-reverse;
    gap: 10px;
    padding-top: 5px;
  }
}

.m-paragraph--events-trainings__manual-content {
  width: calc(68% - 20px);

  @include bp('smalldesktop') {
    width: 100%;
  }

  @include bp('tablet') {
    width: 100%;
  }
}

.m-paragraph--events-trainings__manual-meta {
  @extend .m-event-latest__meta;
}

.m-paragraph--events-trainings__manual-type {
  @extend .m-event-latest__type;
}

.m-paragraph--events-trainings__manual-title {
  @extend .m-event-latest__title;
}

.m-paragraph--events-trainings__manual-image {
  width: 32%;

  @include bp('smalldesktop') {
    width: 100%;
  }

  @include bp('tablet') {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;

    @include bp('smalldesktop') {
      width: 60%;
    }

    @include bp('tablet') {
      width: 60%;
    }
  }
}
