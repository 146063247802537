%ccasetitled {
  @include font1();
  font-size: px2em(30);
  text-transform: uppercase;
  font-weight: $bold;
  margin: 0 0 1.4em;

  &:first-letter {
    color: $blue-light;
  }

  @include bp('mobile') {
    @include font(19, 27);
    margin-bottom: px2em(13);
  }
}
