.m-printbar {
  @include bp('print') {
    display: none;
  }
}

%icon {
  width: 23px;
  height: 20px;
  display: inline-block;
  text-indent: -9999px;
  @include icon($icon-mail);

  &:before {
    color: $grey4;
    font-size: 24px;
  }

  &:hover {
    &:before {
      color: $grey3;
    }
  }
}

.m-printbar__print {
  @extend %icon;

  @include bp('tablet') {
    display: none;
  }

  &:before {
    content: $icon-print;
  }
}

.m-printbar__mail {
  @extend %icon;
  margin-right: 10px;

  @include bp('tablet') {
    margin-right: 0;
  }
}
