$ml: 100px;

.m-paragraph--morebox {
  @extend %paragraph;
  border-top: 1px solid $grey3;
  border-bottom: 1px solid $grey3;
  padding: 33px 0;
  margin: 30px 0;

  @include bp('print') {
    display: none;
  }
}

.m-paragraph--morebox__inner {

}

.m-paragraph--morebox__title {
  @extend %paragraphs_title;
  @include font1();
  text-transform: uppercase;
  text-align: center;
}

.m-paragraph--morebox__elements {
  > .field > .field-items {
    @extend %grid3;

    .m-paragraph--morebox[data-layout="cols-2"] & {
      @extend %grid2;
    }
    .m-paragraph--morebox[data-layout="cols-1"] & {
      width: 100%;
    }

    > .field-item {
      @extend %grid3__trigger;
      margin-bottom: 0;
      position: relative;

      .m-paragraph--morebox[data-layout="cols-2"] & {
        @extend %grid2__trigger;
      }
      .m-paragraph--morebox[data-layout="cols-1"] & {
        width: 100%;
      }
    }

    > .field-item + .field-item {
      @include bp('tablet') {
        margin-top: 45px;
      }

      @include bp('mobile') {
        margin-top: 25px;
      }

      &:nth-child(2) {
        margin-top: 0;

        .m-paragraph--morebox[data-layout="cols-2"] & {
          @include bp('tablet') {
            margin-top: 45px;
          }

          @include bp('mobile') {
            margin-top: 25px;
          }
        }

        @include bp('mobile') {
          margin-top: 25px;
        }
      }

      &:before {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -30px;
        background-color: $grey3;

        .m-paragraph--morebox[data-layout="cols-2"] & {
          left: -16px;
        }

        @include bp('tablet') {
          display: none;
        }
      }
    }
  }

  .m-node--article--toppicks__date {
    display: none;
  }

  .m-node--factsheet--toppicks__date {
    display: none;
  }

  .m-node--dossier--toppicks__date {
    display: none;
  }
}
