.l-footer {
  background-color: $blue-dark;
  color: $white;

  > .innerWrapper {
    padding: 54px 0;
    @include clearfix;

    @include bp('tablet') {
      padding: 30px 0 25px;
    }

    @include bp('mobile') {
      padding: 20px 0 25px;
    }
  }

  @include bp('print') {
    display: none;
  }
}
