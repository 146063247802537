.m-sidebars--blogposts {
  @extend %sidebars-list__block;
}

.m-sidebars--blogposts__title {
  @extend %sidebars-list__title;
  &:before {
    left: 27px;
    top: 3px;
    content: '\EA10';
  }
}

.m-sidebars--blogposts__content {
  @extend %sidebars-list__content;
}

.m-sidebars--blogposts__content .view-content {
  @extend %sidebars_item_list;
}

.m-node--blogpost--sidebar {
  @extend %sidebars_item_list_item;
}

.m-node--blogpost--sidebar__title {
  @extend %sidebars_item_list_title;
}
