.m-travel-log {
  padding-bottom: 60px;
}

.m-travel-log__title {
  @include font2();
  font-weight: $semi-bold;
}

.m-travel-log__list {
  margin: 0;
  padding: 0;
}

.m-travel-log__item {
  position: relative;
  padding: 0 3% 0 3%;
  font-size: px2em(15);
  line-height: px2em(21, 15);
  list-style: none;
  border-bottom: 1px solid $grey3;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $blue-light;
    opacity: 0.11;
    display: none;
  }

  &.active {
    &:before,
    .m-travel-log__item--description {
      display: block;
    }

    .m-travel-log__item--inner {
      &:before {
        content: $icon-arrow-up;
      }
    }
  }

  @include bp('print') {
    &:before,
    .m-travel-log__item--description {
      display: block;
    }

    .m-travel-log__item--inner {
      &:before {
        content: $icon-arrow-up;
      }
    }
  }
}

.m-travel-log__item--inner {
  @include icon($icon-arrow-down);
  padding-top: 22px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;

  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  place-content: flex-end flex-start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-content: flex-start;

  &:before {
    left: auto;
    right: 0;
    top: 26px;
    color: $grey4;
    font-size: 28px;
    line-height: 0.9em;
  }
}

.m-travel-log__item--number {
  width: 6%;
  position: relative;
}

.m-travel-log__item--cicle {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: $yellow;
  display: block;
  font-weight: $semi-bold;
  text-align: center;
  line-height: px2em(28, 15);
}

.m-travel-log__item--location {
  width: 14%;
  padding-top: 4px;
}

.m-travel-log__item--date {
  width: 15%;
  padding-top: 4px;
}

.m-travel-log__item--text {
  width: 57%;
  padding-top: 4px;
}

.m-travel-log__item--title {
  font-size: px2em(15, 15);
  line-height: px2em(21, 15);
  margin: 0;
  font-weight: $semi-bold;
}

.m-travel-log__item--description {
  padding-top: 12px;
  display: none;
}

#travel-log-map {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
  clear: both;
  margin: 20px 0;
  page-break-inside: avoid;

  canvas {
    &:focus {
      outline: none;
    }
  }
}
