.m-paragraph--network {
  @extend .m-paragraph--events-trainings;
}

.m-paragraph--network__inner {
  @extend .m-paragraph--events-trainings__inner;
}

.m-paragraph--network__title {
  @extend .m-paragraph--events-trainings__title;
}

.m-paragraph--network__teaser {
  @extend .m-paragraph--events-trainings__teaser;
}

.m-paragraph--network__links {
  @extend .m-paragraph--events-trainings__links;
}

.m-paragraph--network__view {
  @extend .m-paragraph--events-trainings__event;
}
