.m-node--event--network-latest__type_date {
  div {
    display: inline;
  }
}
.m-node--event--network-latest__date {
  color: $text2;
  @include font1();
  font-size: px2em(15);
  line-height: px2em(20, 15);
  margin-bottom: 8px;
}

.m-node--event--network-latest__type {
  color: $text2;
  @include font1();
  font-size: px2em(15);
  line-height: px2em(20, 15);
  margin-bottom: 8px;
}

.m-node--event--network-latest__title {
  font-size: 1em;
  margin-top: 0;

  a {
    color: $text1;
    @include font2();
    font-size: px2em(19);
    line-height: px2em(27, 19);
    font-weight: $bold;

    @include bp('mobile') {
      font-size: px2em(16);
      line-height: px2em(21, 16);
    }

    &:hover {
      color: $blue-light;
    }
  }
}
