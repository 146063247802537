.l-page--landingpageguide {
  @extend %page;

  padding-bottom: 25px;
}

.l-page--landingpageguide__bgimage {
  position: relative;
  height: 450px;
  width: 100%;
  background-size: 130%;
  background-position: center 50px;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .admin-menu & {
    background-position: center 79px;
  }

  @media (min-width: 1800px) {
    height: 600px;
    background-size: 100%;
  }

  @include bp('tablet') {
    height: 354px;
    background-size: 145%;
    background-position: center 0px;

    .admin-menu & {
      background-position: center 29px;
    }
  }

  @include bp('mobile') {
    height: 220px;
    background-size: 200% auto;
    background-position: top +72px center;
  }

  @include bp('print') {
    display: none;
  }
}
