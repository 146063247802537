.cookieconsent-optout-marketing {
  background-color: $blue-dark;
  color: $white;
  font-weight: $light;
  min-height: 300px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 300px;
  }

  > div > div {
    width: 70%;
    text-align: center;

    @include bp('mobile') {
      width: 90%;
    }
  }

  strong {
    @include font2();

    font-size: px2em(20);
    line-height: 1.3;
    font-weight: 700;
  }

  p {
    @include font1();

    font-size: px2em(14);
    line-height: 1.55;
    font-weight: 400;
    padding-left: 50px;
    padding-right: 50px;

    @include bp('mobile') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  a {
    @include font1();

    color: $blue-light;
    font-size: 100%;
    line-height: 1.55;
    font-weight: 400;
    text-decoration: underline;
  }

  a.button {
    @extend %form_submit;

    line-height: 255%;
    height: 36px;
    text-decoration: none;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .m-paragraph--embeded-html.video-container & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > div {
      min-height: 100%;

      @include bp('mobile') {
        align-items: flex-start;
        padding-top: 5px;
      }
    }
  }

  &[data-type="script"] {

  }
}

.m-paragraph--embeded-html .twitter-tweet {
  display: none;
}

.m-paragraph--embeded-html .instagram-media + script + .cookieconsent-optout-marketing {
  display: none !important;
}
