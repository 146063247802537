.m-sidebars--further-reading {
  overflow: hidden;
  margin-bottom: 60px;
  font-size: px2em(14);
  line-height: px2em(19, 14);

  .m-sidebars--tabs-content {
    padding: 0 0 0 7%;

    @include bp('tablet') {
      padding-left: 0;
    }
  }

  a {
    font-size: 1em;
  }
}

.m-sidebars--further-reading--title {
  color: $grey4;
  font-size: px2em(15, 14);
  line-height: px2em(20, 15);
  text-transform: uppercase;
  font-weight: $semi-bold;
  margin: 0 0 14px 0;
  padding-left: 7%;

  @include bp('tablet') {
    padding-left: 0;
  }
}

.m-sidebars--further-reading .item-list ul {
  @extend %sidebars_item_list;
}

.m-sidebars--further-reading .item-list ul li {
  @extend %sidebars_item_list_item;
}

.m-sidebars--further-reading .item-list ul li .views-field-title {
  @extend %sidebars_item_list_title;
  margin: 0;
}

.m-sidebars--further-reading .item-list ul li .views-field-field-global-tags {
  @extend %global_tags;
  font-size: 1em;
  padding-top: 7px;
  text-align: left;
  margin-bottom: 0;

  a {
      font-weight: $light;
  }
}
