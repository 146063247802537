%m-paragraph__textimage {
  @extend %paragraph;
}

%m-paragraph--textimage__title {
  @extend %paragraphs_title;
}

%m-paragraph--textimage__inner {
  display: block;
  &[data-layout="left"] {

  }

  &[data-layout="right"] {

  }
}

%m-paragraph--textimage__text {
  @extend %paragraph_text;
}

%m-paragraph--textimage__image {
  @include fullimage;
  @extend %image_caption;

  margin-bottom: 1em;

  &[data-layout="left"] {
    margin-right: 40px;
    float: left;

    @include bp('mobile') {
      margin-right: 0;
      float: none;
    }
  }

  &[data-layout="right"] {
    margin-left: 40px;
    float: right;

    @include bp('mobile') {
      margin-right: 0;
      float: none;
    }
  }

  @include bp('tablet') {
    width: calc(50% - 20px);
  }

  @include bp('mobile') {
    width: 100%;
    float: none;
  }
}

@import "m-paragraph--textimage--default";
@import "m-paragraph--textimage--small";
@import "m-paragraph--textimage--big";
