.m-node--webform {
  @extend %node;
  background-color: $white;
  margin-top: -494px;

  @include bp('tablet') {
    margin-top: -320px;
  }

  @include bp('mobile') {
    margin-top: 0;
  }
}

.m-node--webform__type {
  @extend %node_icon;

  @include node_icon_icon(56px);
  @extend %data_icons;
  position: absolute;

  /* TODO: add icon in field_icon selection in backend */
  #node-139 & {
    &:before {
      content: '\EA0F' !important;
    }
  }
}

.m-node--webform__printbar {
  @extend %node_printbar;
}

.m-node--webform__title {
  @extend %node_title;
  @extend %page_center_field;
}

.m-node--webform__body {
  @extend %page_center_field;
  @include content_formater;
}

.m-node--webform__messages {
  @extend %page_center_field;
}

.m-node--webform__form {
  @extend %page_center_field;
  min-height: 375px;

  .links {
    a.ajax-processed {
      color: $blue-light;

      &:hover {
        color: $blue-dark;
      }
    }
  }
}

.m-node--webform__newsletter_text {
  padding-bottom: 30px;

  h4 {
    @include font2();
    font-weight: $bold;
    margin-bottom: 23px;
    padding-top: 13px;
  }
}

.m-node--webform__paragraphs {
  @extend %page_center_field;

  margin-top: 40px;

  .paragraphs-items > .field > .field-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > .field-item {
      width: 100%;
    }
  }

  @include node_ctypes {

  }
}
