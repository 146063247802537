.m-newsletter_webform_menu_submit,
.m-newsletter_webform_menu_middle,
.m-newsletter_webform_menu_label {
  display: inline-block;
  float: left;
}

.m-newsletter_webform_menu_label {
  width: 230px;
  margin-right: 20px;

  span {
    color: $white;
    @include font1();
    @include font_size_line_height(25, 34);
    font-weight: $bold;
  }

  @include bp('tabletmobile') {
    margin-right: 0;
    margin-bottom: 13px;
    display: block;

    span {
      text-align: center;
    }
  }
}

.m-newsletter_webform_menu_middle {
  width: 770px;
}

.m-newsletter_webform_menu_submit {
  width: 100px;
  display: inline-block;

  .form-actions {
    margin-top: 4px;
  }
  input[type="submit"] {
    @extend %form_submit;
    background-color: $red-dark;
  }
}

.m-newsletter_webform_menu_inputs {

  .webform-component {
    > label {
      display: none;
    }

    .form-type-textfield {
      display: inline-block;
      margin-right: 40px;
      input {
        width: 465px;
      }
    }

    .form-checkboxes {
      display: inline-block;
      vertical-align: bottom;

      .form-type-checkbox {
        display: block;

        label {

          padding-left: 30px;
          color: $white;

          &:after {
            right: auto;
            left: 0;
            border-radius: 0;
          }

          &:before {
            right: auto;
            left: 0;
            border-radius: 0;
          }
        }
      }
    }
  }
}

.m-newsletter_webform_menu_text {
  display: inline-block;
  width: 520px; // m-newsletter_webform_menu_inputs ...textfield ...input + some paddings
  margin-top: 20px;

  color: $white;
  @include font_size_line_height(14, 19);
}
