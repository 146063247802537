.m-node--expert--full {
  overflow: hidden;
  margin-top: 50px;
}

.m-node--expert--full__content_main {
  @extend %content__main;
}

.m-node--expert--full__content_sidebar {
  @extend %content__sidebar;
}

.m-node--expert--full__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 48px;
  display: inline-block;

  a {
    &:before {
      content: $icon-experts;
    }
  }
}

.m-node--expert--full__header_line {
  font-weight: $regular;
  color: $text1;
}

.m-node--expert--full__title {
  @extend %node_title;
  text-align: left;
  overflow: hidden;
}

.m-node--expert--full__text {
  @include content_formater;
  margin-bottom: 30px;
}

.m-node--expert--full__field {
  margin-bottom: 4px;
}

.m-node--expert--full__field_label {
  font-weight: $bold;
  padding-right: 10px;
}

.m-node--expert--full__field_value {
  font-weight: $regular;
}

a.m-node--expert--full__field_value {
  color: $blue-light;
}

.m-node--expert--full__header {
  margin-bottom: 50px;
}

.m-node--expert--full__sidebar_contact {
  .m-sidebars--contact__title {
    &:before {
      //content: $icon-TODO needs a diffrent icon?
    }
  }
  .m-sidebars--contact__text {
    &:after {
      display: none;
    }
    p {
      @include font_size_line_height(18, 30);
      font-weight: $regular;
    }
    a {
      font-size: inherit;
      font-weight: $regular;
      text-decoration: underline;
    }
  }
}

.m-node--expert--full__printbar {
  @extend %node_printbar;
  top: 10px;
  right: 0;
}
