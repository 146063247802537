.m-node--list--experts__turnbutton {
  text-decoration: none;
  display: inline-block;
  padding: 8px 1.3em;
  line-height: 26px;
  background: $red-light;
  color: $white;
  font-size: 14px;
  border-radius: 2em;
  font-weight: 600;
  padding-left: 60px;
  min-width: 160px;

  position: relative;
  @include icon('\EAB6');
  &:before {
    font-size: 26px;
    line-height: 120%;
    top: 4px;
    left: 25px;
  }
}
