.m-node--event {
  padding-top: 100px;
  display: flex;
  flex-direction: row;

  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: space-between;
  place-content: flex-end space-between;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-content: flex-start;

  @include bp('tablet') {
    padding-top: 38px;
  }

  .column {
    flex-direction: column;
    display: flex;
  }

  .node-content {
    width: 63%;
    position: relative;
    padding-top: 103px;

    @include bp('tablet') {
      width: 100%;
    }

    @include bp('mobile') {
      padding-top: 50px;
    }

    .teaser-image {
      img {
        width: 100%;
        height: auto;
      }
    }
    @include bp('print') {
      all: unset !important;
    }
  }

  .sidebar {
    width: 27%;
    flex-shrink: 0;
    border-left: 1px solid $grey3;

    @include bp('tablet') {
      margin-top: 100px;
      border-left: 0;
      width: 100%;
    }

    @include bp('mobile') {
      margin-top: 60px;
    }
    @include bp('print') {
      display: none;
    }
  }
  @include bp('print') {
    all: unset !important;
  }
}


.m-node--event__type {
  @extend %node_icon;
  top: 0;
  text-transform: uppercase;

  @include bp('mobile') {
    left: 0;
  }

  a {
      background-color: $red-light;

      &:before {
          content: $icon-calendar;
      }

      &:after {
          border-left-color: $red-light;
      }

      &:hover {
          background-color: $red-dark;

          &:after {
              border-left-color: $red-dark;
          }
      }
  }

  &:hover {
      a {
          background-color: $red-dark;

          &:after {
              border-left-color: $red-dark;
          }
      }
  }
}

.m-node--event__printbar {
  position: absolute;
  right: 0;
  top: 20px;

  @include bp('mobile') {
    top: 0;
  }
}

.m-node--event__application-form__links {
  ul {
    @include clearfix;
    margin: 0 0 40px 0;
    padding: 0;

    li {
      display: block;
      float: left;
      border-right: 1px solid $white;

      a {
        padding: 6px 25px;
        background: none;
        display: block;
        color: $white;
        background-color: $red-light;

        &:hover {
          background-color: $red-dark;
        }
      }
    }
  }
}

.m-node--event__title {
  @extend %node_title;
  text-align: left;
  margin-top: 0;
}

.m-node--event__tags-share {
  padding-bottom: 70px;
  display: flex;
  flex-direction: row;

  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: space-between;
  place-content: flex-end space-between;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-content: flex-start;

  @include bp('mobile') {
    padding-bottom: 30px;
  }
}

.m-node--event__tags {
  @extend %global_tags;
  text-align: left;
  padding-right: 25px;
  padding-top: 7px;

  @include bp('mobile') {
    padding-top: 3px;
  }

  a {
      font-weight: $regular;
      color: $text2;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        color: $blue-light;
      }
  }
}

.m-node--event__socialshare {
  @extend %node_socialshare;
  text-align: right;
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;

  .m-socialmedia-share__linkedin {
    margin-right: 0;
  }
}

.m-node--event__teaser_image {
  margin-bottom: 40px;
  .subtitle > a {
    @include image_caption_link();
  }
}

.m-node--event__teaser_text {
  @extend %node_teaser;
  text-align: left;
}

.m-node--event__teaser {
  figure {
      margin: 0;
  }
}

.m-node--event__target-audience {
  margin-bottom: 20px;
  font-weight: $bold;

  @include bp('mobile') {
    font-size: px2em(15);
    line-height: px2em(18, 15);
  }
}

.m-node--event__meta {
  position: relative;
  border-top: 3px solid $blue-light;
  border-bottom: 3px solid $blue-light;
  padding: 11px 0 11px 0;
  margin-bottom: 25px;

  @include bp('mobile') {
    margin-bottom: 10px;
  }
}

.m-node--event__meta--bar {
  display: flex;
  flex-direction: row;

  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: space-between;
  place-content: flex-end space-between;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-content: flex-start;
}

.m-node--event__date-location {
  font-weight: $bold;
  font-size: px2em(19);
  line-height: px2em(26, 19);
  padding-right: 20px;
  flex: 6;

  @include bp('mobile') {
    flex: none;
    width: 100%;
    padding-right: 0;
    padding-bottom: 8px;
    font-size: px2em(17);
    line-height: px2em(21, 17);
  }
}

.m-node--event__date {
  display: inline-block;
  border-right: 2px solid $grey4;
  padding-right: 15px;
  margin-right: 10px;

  @include bp('mobile') {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
}

.m-node--event__location {
  display: inline;
}

.m-node--event__ical {
  line-height: 50px;
  text-indent: -9999px;
  flex: 1;

  @include icon($icon-calendar);

  &:before {
      font-size: 55px;
      line-height: 50px;
      color: $blue-light;

      @include bp('mobile') {
        font-size: 50px;
        left: auto;
        right: 17px;
      }
  }

  &:hover {
      &:before {
          color: $blue-dark;
      }
  }
}

.m-node--event__apply {
  text-transform: uppercase;
  font-weight: $semi-bold;
  font-size: px2em(14);
  line-height: px2em(19, 14);
  padding: 10px 15px;
  text-align: center;
  background-color: $red-light;
  color: $white;
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  flex: 1;

  @include bp('mobile') {
    flex: none;
  }

  &:hover {
      background-color: $red-dark;
  }
}

.m-node--event__content {
  @extend %tinymce-styles;

  @include bp('mobile') {
    font-size: px2em(14);
    line-height: px2em(21, 14);
  }
}
