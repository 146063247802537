$padding: 45px;

.m-node--event--teaserbox {
  position: relative;
  height: 100%;
}

.m-node--event--teaserbox__type {
  @extend %node_icon;
  position: absolute;
  left: 0;
  top: 41px;
  z-index: 3;
  margin-bottom: 26px;

  a {
    @include ci('event');
    padding-left: 97px;
    background-color: $blue-light;

    @include bp('mobile') {
      padding-left: 40px;
    }

    &:before {
      left: 45px;
      font-size: 40px;

      @include bp('mobile') {
        left: 13px;
        font-size: 16px;
      }
    }

    &:after {
      border-left-color: $blue-light;
    }

    &:hover {
      background-color: $blue-light;

      &:after {
        border-left-color: $blue-light;
      }
    }
  }

  @include bp('mobile') {
    margin-left: $mobile_diff;
  }
}

.m-node--event--teaserbox__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.m-node--event--teaserbox__inner {
  @extend %node_teaser_box_inner;
  position: relative;
  background-color: rgba($blue-dark, 0.8);
  padding-top: 134px;
  height: calc(100% - 134px - 40px);

  .m-paragraph--teaserbox[data-layout="23"] & {
    background-color: transparent;
    padding-left: calc(50% + 45px);
    z-index: 2;

    @include bp('tablet') {
      padding-left: 45px;
    }

    &:before {
      content: '';
      position: absolute;
      background-color: rgba($red-light, 1);
      top: 0;
      left: 50%;
      right: 0;
      bottom: 0;
      z-index: -1;
      @include bp('tablet') {
        left: 0;
      }
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: 50%;
      top: 50%;
      margin-top: -30px;
      border-right: 30px solid $red-light;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      z-index: 10;
      @include bp('tablet') {
        display: none;
      }
    }
  }
}

.m-node--event--teaserbox__title {
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 15px;

  .m-paragraph--teaserbox[data-layout="23"] & {
    margin-bottom: 36px;
  }

  a {
    @extend %node_title;
    text-align: left;
    margin-top: 0;
    font-size: px2em(26);
    font-weight: $semi-bold;
    line-height: px2em(33, 26);
    color: $white;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

.m-node--event--teaserbox__teaser {
  font-size: px2em(15);
  line-height: px2em(20, 15);
  color: $white;
}

.m-node--event--teaserbox__date {
  @extend %node_date;
  text-align: left;
  margin-bottom: 20px;
  color: $white;

  > div {
    display: inline-block;

    & + div {
      &:before {
        content: ' | ';
        display: inline-block;
      }
      > div {
        display: inline-block;
      }
    }
  }
}

.m-node--event--teaserbox__subtitle {
  @extend %node_subtitle;
  text-align: left;
}

.m-node--event--teaserbox__tags {
  @extend %node_tags;
  text-align: left;
  margin-bottom: 0;
}

.m-node--event--teaserbox__teaser {
  @extend %node_teaser;
  @include font1();
  text-align: left;
  font-weight: $regular;
  margin-bottom: 0;
}
