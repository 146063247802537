.m-blogpost-latest {

}

.m-blogpost-latest__meta {
  @extend .m-event-latest__meta;
}

.m-blogpost-latest__type {
  @extend .m-event-latest__type;
}

.m-blogpost-latest__date {

}

.m-blogpost-latest__title {
  @extend .m-event-latest__title;
}
