@charset "utf-8";

@import "_l-page--network";
@import "_l-page--research-map";
@import "_l-page--system";
@import "_l-page--blogpost";
@import "_l-page--dossier";
@import "_l-page--frontpage";
@import "_l-page--factsheet";
@import "_l-page--page";
@import "_l-page--specialpage";
@import "_l-page--webform";
@import "_l-page--list";
@import "_l-page--glossary";
@import "_l-page--landingpageguide";
