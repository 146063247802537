.l-page--page {
  @extend %page;
}

.l-page--page__main {
  @extend %content__main;
}

.l-page--page__sidebar {
  @extend %content__sidebar;
}
