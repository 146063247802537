.m-sidebars--experts {
  @extend %sidebars-list__block;
}

.m-sidebars--experts__title {
  @extend %sidebars-list__title;

  &:before {
    left: 18px;
    top: 5px;
    content: $icon-experts;
  }
}

.m-sidebars--experts__content {
  @extend %sidebars-list__content;
}

.m-sidebars--experts__list {
  @extend %sidebars_item_list;
}

.m-sidebars--experts__item {
  padding-bottom: 10px;
}

.m-sidebars--experts__item_title {
  @extend %sidebars_item_list_title;
}
