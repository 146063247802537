@mixin select2-skin($select2-height: $select2-height, $color-base: $color-base, $color-highlight: $color-highlight, $color-border: $color-border, $color-font: $color-font) {
  .select2-container {
    width: 100%;

    .select2-choice {
      background-image: none;
      background-color: $color-base;
      border-radius: 18px;
      border: 0;
      height: $select2-height;
      padding: 0 52px 0 30px;

      @include bp('mobile') {
        height: 36px;
      }

      &:focus, &:active {
        outline: 0;
      }

      .select2-chosen {
        font-size: 15px;
        line-height: $select2-height;
        color: $color-font;
        //padding-left: 10px;
        font-weight: $bold;
        margin-right: 0;

        @include bp('mobile') {
          font-size: px2em(14);
          line-height: 36px;
        }
      }

      .select2-arrow {
        background-image: none;
        background-color: transparent;
        height: $select2-height;
        border: 0;
        border-radius: 0;
        background-clip: initial;
        font-size: 20px;
        right: 20px;
        top: 48%;
        margin-top: -10px;

        b {
          @include icon($icon-arrow-down);
          background: none;
          &::before {
            color: $color-font;
            font-size: 18px;
          }
        }
      }
    }
    &.select2-dropdown-open {
      .select2-choice {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .select2-arrow {
          background: none;
          b {
            border-color: transparent transparent $color-font transparent;
          }
        }
      }
    }
    &:focus {
      outline: none;
      border: 2px solid $color-highlight;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
  }

  .select2-container .select2-choice {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .select2-drop {
    background-color: $color-base;
    border: 0;
    color: $color-font;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding-top: 20px;
    margin-top: 0;
    padding-bottom: 20px;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    ul.select2-results {
      max-height: 384px;

      li.select2-result {
        height: auto;
        padding: 12px 10px;
        width: 100%;
        box-sizing: border-box;
        line-height: 22px;
        font-weight: $regular;
        position: relative;
        @include font(15, $select2-height);

        &.select2-highlighted, &[aria-selected=true] {
          background-color: $color-highlight;
          color: $white;
        }
      }
    }
    .select2-search {
      .select2-search__field {
        border: 1px solid #ddd;
      }
    }

    &.select2blue {
      border-left: 1px solid $color-border;
      border-right: 1px solid $color-border;
      border-bottom: 1px solid $color-border;
    }
  }
}

// Variables

$select2-height: 42px;

$color-base: $grey2;
$color-highlight: $blue-light;
$color-border: $blue-medium;
$color-font: $blue-light;

@include select2-skin($select2-height, $color-base, $color-highlight, $color-border, $color-font);

// Font size
.select2-container {
  text-align: left;
  @include font(30, 27);
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0 24px;
  @include bp('mobile') {
    padding: 0 10px;
  }
}

.select2-dropdown .select2-results ul.select2-results__options li.select2-results__option {
  padding: 12px 24px;
  @include bp('mobile') {
    padding: 12px 10px;
  }
}

// Arrow Styling
.select2-container--default .select2-selection--single {
  border-radius: 0;
  .select2-selection__arrow {
    @include icon($icon-arrow-down, $blue-medium);
    top: 0;
    right: 27px;
    height: $select2-height;
    line-height: $select2-height;
    color: $white;
    > b {
      display: none;
    }
    @include bp('mobile') {
      right: 10px;
    }
    &::before {
      display: block;
    }
  }
}

.select2-container--select2blue .select2-selection--single {
  .select2-selection__arrow {
    @include icon($icon-arrow-down, $blue-medium);
    position: absolute;
    top: 10px;
    right: 27px;
    height: $select2-height;
    line-height: $select2-height;
    color: $white;
    > b {
      display: none;
    }
  }
}

.select2-container--default.select2-container--open,
.select2-container--select2blue.select2-container--open {
  .select2-selection__arrow {
    @include icon($icon-arrow-up, $blue-medium);

    &:before {
      color: $white;
      margin-left: 5px;
      margin-bottom: 20px;
      display: block;
    }
  }

  .select2-selection__placeholder {
    color: $white;
    @include font(18, $select2-height);
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx) {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice .select2-arrow b {
    background-image: none !important;
    background-size: auto !important;
  }
}
