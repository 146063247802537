$inner_head_width: 84%;

.l-page--system {
  > .innerWrapper {
    margin-top: -128px;
    background-color: $white;

    @include bp('tablet') {
      padding-top: 0;
    }

    @include bp('mobile') {
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.l-page--system__bgimage {
  clear: both;
  overflow: hidden;
  background-color: $blue-dark;
  min-height: 360px;
  margin-top: $header-height;

  .page-search & {
    padding-bottom: 128px;
  }

  @include bp('tablet') {
    margin-top: $tablet-header-height;
    height: 320px;
  }

  @include bp('mobile') {
    height: 146px;
  }
}
