.m-node--frontpage {
  @extend %node;
  background-color: $white;
  padding-top: 0;

  @include bp('tablet') {
    padding-top: 0;
  }

  @include bp('mobile') {
    padding-top: 0;
  }
}

.m-node--frontpage__header {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 24% 15%, #122f4e 13%, rgba(18, 47, 78, 0) 81%);
  }
}

.m-node--frontpage__headerimages {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.m-node--frontpage__headerimage {
  display: none;
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 2400px) {
    background-size: 120%;
  }

  &.is-active {
    display: block;
  }
}

.m-node--frontpage__top {
  position: relative;
  z-index: 2;
  // padding: 155px 8% 102px;
  padding: 110px 8% 80px;

  @include bp('smalldesktop') {
    width: $smalldesktop_width;
    margin: 0 auto;
    padding: 75px 8% 80px;
  }

  @include bp('tablet') {
    width: $tablet_width;
    margin: 0 auto;
    padding: 50px 0;
  }

  @include bp('mobile') {
    width: $mobile_width;
    margin: 0 auto;
  }
}

.m-node--frontpage__headertitle {
  width: 80%;
  font-weight: $bold;
  font-size: px2em(64);
  line-height: 1.25;
  color: $blue-light;
  margin: 0;

  @include bp('smalldesktop') {
    width: 80%;
    font-size: px2em(56);
  }

  @include bp('bigdesktop') {
    width: 65%;
    max-width: 963px;
  }

  @include bp('tablet') {
    width: 80%;
    font-size: px2em(50);
  }

  @include bp('mobile') {
    width: 100%;
    font-size: px2em(45);
  }
}

.m-node--frontpage__headerlink {
  display: inline-block;
  margin-top: 35px;

  a {
    @extend %button;
  }
}

.m-node--frontpage__topics {
  position: relative;
  z-index: 2;
  padding-bottom: 110px;

  @include bp('mobile') {
    padding-bottom: 70px;
  }
}

.m-node--frontpage__topicsrow {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 30px;

  @include bp('desktop') {
    padding: 0 108px;
  }
}

.m-node--frontpage__topicsitem {
  display: flex;
  width: calc((100% - 60px) / 3);

  @include bp('tablet') {
    width: calc(50% - 20px);
  }

  @include bp('mobile') {
    width: 100%;
    max-width: 321px;
  }
}

.m-node--frontpage__topicslink {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background-color: $white;
  text-align: center;

  @include bp('mobile') {
    padding: 20px;
  }

  &:hover {
    margin-bottom: -8px;

    .m-node--frontpage__topicssubline {
      padding-bottom: 24px;
      margin-bottom: 8px;

      &::before {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s 0.2s, opacity 0.2s linear;
      }
    }

    .m-node--frontpage__topicssublinetext {
      visibility: hidden;
      opacity: 0;
      max-height: 0;
      transition: visibility 0s 0.2s, opacity 0.2s linear;
    }
  }
}

.m-node--frontpage__topicstitle {
  font-weight: $bold;
  font-size: px2em(26);
  line-height: px2em(39, 26);
  letter-spacing: normal;
  color: $blue-light;
  margin: 0;

  @include bp('mobile') {
    font-size: 1.425em;
  }

  & + .m-node--frontpage__topicssubline {
    margin-top: 6px;
  }
}

.m-node--frontpage__topicssubline {
  position: relative;
  margin: 0;

  @include icon($icon-arrow-with-line);

  &:before {
    color: $blue-light;
    font-size: 24px;
    top: auto;
    bottom: 0;
    left: calc(50% - 12px);
    line-height: 1em;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
  }
}

.m-node--frontpage__topicssublinetext {
  @include font1();

  display: block;
  font-weight: $regular;
  font-size: px2em(17, 20);
  line-height: 1.55;
  letter-spacing: normal;
  color: $blue-dark;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.m-node--frontpage__ctypes {

  .paragraphs-items > .field > .field-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > .field-item {
      width: 100%;

      &:has(.m-paragraph--events-trainings),
      &:has(.m-paragraph--network) {
        display: flex;
        width: calc(50% - 66px);
        max-width: 595px;

        @include bp('tablet') {
          max-width: none;
        }

        @include bp('mobile') {
          width: 100%;
        }
      }

      &:has(.m-paragraph--events-trainings) {
        margin-left: 44px;
        margin-right: 22px;

        @include bp('mobile') {
          width: $mobile_width;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &:has(.m-paragraph--network) {
        margin-left: 22px;
        margin-right: 44px;

        @include bp('mobile') {
          width: $mobile_width;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  @include node_ctypes {
    &.m-paragraph--newsroom-slider,
    &.m-paragraph--guides,
    &.m-paragraph--events-trainings,
    &.m-paragraph--network,
    &.m-paragraph--newsletter,
    &.m-paragraph--toolbox {
      @extend %page_full_field;
    }

    &.m-paragraph--events-trainings,
    &.m-paragraph--network {
      display: flex;
      flex-direction: column;
    }
  }
}
