.m-pager {
  display: none;

  .page-node-webform-results &,
  .view-display-id-news_archive & {
    display: block;
    margin-top: 70px;
    text-align: center;

    @include bp('mobile') {
      margin-top: 30px;
    }
  }

  ul.pager {
    @extend %ul;

    display: inline-flex;
    gap: 15px;
    list-style: none;
    font-size: px2em(15);
    line-height: 1.55;

    .pager-item {
      @extend %list_item;
    }

    .pager-current {
      color: $blue-light;
      text-decoration: underline;
    }

    .pager-first {
      padding-right: 25px;

      @include bp('mobile') {
        display: none;
      }
    }

    .pager-previous {
      padding-right: 10px;

      @include bp('mobile') {
        padding-right: 0;
      }

      a {
        @include icon($icon-arrow-with-line);

        display: block;
        position: relative;
        padding-left: 21px;

        @include bp('mobile') {
          padding-left: 14px;
          width: 0;
          overflow: hidden;
        }

        &:before {
          transform: rotate(180deg);
          color: $black;
          font-size: 14px;
          line-height: 100%;
          top: 4px;
        }

        &:hover {
          &:before {
            color: $blue-light;
          }
        }
      }
    }

    .pager-next {
      padding-left: 10px;

      @include bp('mobile') {
        padding-left: 0;
      }

      a {
        @include icon($icon-arrow-with-line);

        display: block;
        position: relative;
        padding-right: 21px;

        @include bp('mobile') {
          padding-left: 14px;
          padding-right: 0;
          width: 0;
          overflow: hidden;
        }

        &:before {
          color: $black;
          font-size: 14px;
          line-height: 100%;
          left: auto;
          right: 0;
          top: 4px;
        }

        &:hover {
          &:before {
            color: $blue-light;
          }
        }
      }
    }

    .pager-last {
      padding-left: 25px;

      @include bp('mobile') {
        display: none;
      }
    }

    a {
      font-size: px2em(15, 15);
      line-height: 1.55;

      &:hover {
        color: $blue-light;
      }
    }
  }
}
