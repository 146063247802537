.m-node--dossier--topnews {
  position: relative;
}

.m-node--dossier--topnews__background {
  background-color: rgba($blue-dark, $bg_opacity);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.m-node--dossier--topnews__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 26px;
  width: 160px;

  a:before,
  span:before {
    content: $icon-dossiers;
  }

  @include bp('mobile') {
    margin-left: $mobile_diff;
  }
}

.m-node--dossier--topnews__inner {
  position: relative;
  z-index: 2;
  padding-top: 120px;
  padding-right: 50px;

  @include bp('tablet') {
    padding: 47px 20px 44px 0;
  }

  @include bp('mobile') {
    padding: 47px 10px 44px 0;
  }
}

.m-node--dossier--topnews__title {
  font-size: 1em;
  margin: 0 0 0.6em;

  a {
    @extend %node_title;
    font-size: px2em(50);
    line-height: 1.3em;
    margin-bottom: 20px;
    text-align: left;
    color: $white;

    @include bp('smalldesktop') {
      font-size: px2em(30);
    }

    @include bp('mobile') {
      font-size: px2em(30);
    }

    &:hover {
      color: rgba($white, $bg_opacity);
    }
  }

  @include bp('tablet') {
    width: 80%;
  }
}

.m-node--dossier--topnews__date {
  @extend %node_date;
  text-align: left;
  margin: 0 0 0.6em;

  > div {
    display: inline-block;
    color: $white;

    a {
      color: $white;
    }

    & + div {
      &:before {
        content: ' | ';
        display: inline-block;
      }
      > div {
        display: inline-block;
      }
    }
  }
}

.m-node--dossier--topnews__subtitle {
  @extend %node_subtitle;
  color: $white;
  text-align: left;
}

.m-node--dossier--topnews__tags {
  @extend %node_tags;
  text-align: left;
  margin-bottom: 0;

  a {
    color: $white;
  }
}

.m-node--dossier--topnews__teaser {
  @extend %node_teaser;
  text-align: left;
  color: $white;
}
