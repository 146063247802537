.m-topicpage {
  &.isLoading {
    .m-topicpage__content {

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($white, 0.4);
        background-image: url(../../img/loading.gif);
        background-position: 55px 45px;
        background-size: 40px;
        background-repeat: no-repeat;
        z-index: 10;
      }
    }
  }
}

.m-topicpage__actions {
  margin-top: 8px;
  position: relative;
  height: 80px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 23px;
    height: 8px;
    background-color: $grey2;
  }
}

.m-topicpage__load-more--wrapper {
  @include icon('\EA03');
  position: relative;
  display: inline-block;
  background-color: $white;
  padding: 0 50px;
  text-align: center;

  &:before {
    color: $blue-medium;
    bottom: 0;
    left: calc(50% - 20px);
    top: auto;
    font-size: px2em(40);
    line-height: 1em;
  }

  .m-topicpage.isLoading & {
    &:before {
      display: none;
    }
  }
}

.m-topicpage__load-more {
  display: inline-block;
  font-weight: $bold;
  color: $blue-light;
  opacity: 0.38;
  font-size: px2em(35);
  line-height: px2em(47, 35);
  text-transform: uppercase;
  padding-bottom: 40px;
}

.m-topicpage__load-more--loading {
  display: none;
  background-image: url(../../img/loading.gif);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 40px auto;
  text-indent: -9999px;
  width: 40px;

  .m-topicpage.isLoading & {
    display: block;
    left: calc(50% - 20px);
    position: absolute;
  }
}

.m-topicpage__header {
  @extend %node_header;
  position: relative;
  margin-bottom: 0px;
  min-height: 180px;
  padding-bottom: 180px;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    background: $blue-dark;
    left: -100%;
    right: -100%;
    top: -100px;
    bottom: 0;
  }

  @include bp('mobile') {
    min-height: 0;
    padding-bottom: 160px;
  }
}

.m-topicpage__title {
  @extend %node_header_title;
}

.m-topicpage__title--highlight {
  color: $blue-light;
}

.m-topicpage__description {
  @extend %node_header_description;
}

.m-topicpage__subtopic-selector {
  display: block;
  position: relative;
  padding: 45px 0 27px 0;
  text-align: center;

  @include bp('mobile') {
    padding-top: 27px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 40%;
    left: 30%;
    top: 100%;
    height: 3px;
    background-color: $blue-light;
  }

  .m-topicpage__subtopic-selector--label {
    font-size: px2em(15);
    line-height: px2em(20, 15);
    font-weight: $bold;
    text-transform: uppercase;
    padding-right: 13px;

    @include bp('mobile') {
      display: block;
      padding-right: 0;
      padding-bottom: 8px;
    }
  }

  select {
    width: 350px;

    @include bp('mobile') {
      width: 80%;
    }
  }
}

.m-topicpage__content {
  background-color: $white;
  margin-top: -120px;
  padding-top: 1px;
  position: relative;
}

.m-topicpage__nodetypes {
  display: flex;
  flex-direction: row;
  margin: 67px -1em 0;

  @include bp('mobile') {
    display: block;
  }
}

.m-topicpage__nodetype {
  flex-basis: 33.33%;
  padding: 0 1em;
  text-align: center;
}

.m-topicpage__nodetype--title {
  @include headline_flag(false);
  padding-left: px2em(70, 30);

  &:before {
    left: 25px;
  }

  &[rel="factsheet"] {
    &:before {
      content: '\EA09';
    }
  }

  &[rel="dossier"] {
    &:before {
      content: '\EA08';
    }
  }

  &[rel="article"] {
    &:before {
      content: '\EA0E';
    }
  }
}

.m-topicpage__nodetype--description {
  text-align: center;
  font-size: px2em(17);
  line-height: px2em(23, 17);
  padding: 27px 0 50px 0;
  height: px2em(70, 17);
  color: $text2;

  @include bp('tablet') {
    height: auto;
  }

  @include bp('mobile') {
    font-size: px2em(15);
    line-height: px2em(20, 15);
  }
}

.m-topicpage__topicnodes {
  margin: 0;
  padding: 0;
  text-align: left;
}

.m-topicpage__topicnode {
  list-style: none;
  margin: 0 0 40px 0;
  background-color: $grey2;
  position: relative;
  @extend %transitionAll;

  @include bp('mobile') {
    margin-bottom: 22px;
  }

  &:hover {
    background-color: darken($grey2, 5);
  }

  &.m-topicpage__topicnode--morebox:hover {
    background-color: transparent;
  }

  .m-topicpage__topicnode--inner {
    @extend %teaserlistgrid__inner;
  }

  &:hover {
    .m-topicpage__topicnode--inner-overlay {
      &:before {
        opacity: .9;
      }
    }
  }

  .m-topicpage__topicnode--dark {
    color: $white;

    a {
      color: $white;
    }

    .m-topicpage__topicnode--inner-overlay {
      @extend %teaserlistgrid__dark_overlay;
    }
  }

  .m-topicpage__topicnode--content {
    @extend %teaserlistgrid__content;
  }

  .m-topicpage__topicnode--title {
    @extend %teaserlistgrid__title;
  }

  .m-topicpage__topicnode--stamp {
    @extend %teaserlistgrid__stamp;
  }

  .m-topicpage__topicnode--date-author {
    @extend %teaserlistgrid__date;
  }

  .m-topicpage__topicnode--tags {
    @extend %teaserlistgrid__tags;
  }
}

.m-topicpage__topicnode--morebox {
  background-color: transparent;
  text-transform: uppercase;
  text-align: center;

  .m-topicpage__topicnode--inner {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: px2em(105);
    height: 228px;
  }

  .m-topicpage__topicnode--morebox-title {
    opacity: 0.38;
    font-size: px2em(33);
    line-height: px2em(43, 33);
    font-weight: $bold;
    color: $blue-light;
    padding-bottom: 27px;

    @include bp('mobile') {
      font-size: px2em(20);
      line-height: px2em(25, 20);
    }
  }

  .m-topicpage__topicnode--morebox-link {
    font-size: px2em(15);
    line-height: px2em(20, 15);
    text-transform: none;
    font-weight: $bold;
    color: $blue-light;

    @include bp('mobile') {
      font-size: px2em(14);
      line-height: px2em(18, 14);
    }
  }
}
