.m-paragraph--teaserbox {
  @extend %teaserlistgrid__trigger;

  &[data-layout="23"] {
    width: 65.5%;

    .m-node--event--teaserbox__background {
      display: block;
      width: 50%;
      float: left;
    }

    .m-node--event--teaserbox__type {
      a {
        @include ci('event');
        background-color: $red-dark;

        &:after {
          border-left-color: $red-dark;
        }

        &:hover {
          background-color: $red-light;

          &:after {
            border-left-color: $red-light;
          }
        }
      }
    }

    .m-node--event--teaserbox__inner {
      padding-top: 40px;
      height: calc(100% - 80px);
    }

    @include bp('tabletmobile') {
      .m-node--event--teaserbox__background {
        display: none;
      }

      .m-node--event--teaserbox__inner {
        margin-left: 0;
        padding-top: 134px;
        height: calc(100% - 134px - 40px);
      }
    }

    @include bp('tablet') {
      width: 47%;
    }

    @include bp('mobile') {
      width: 100%;
    }
  }

  &[data-layout="33"] {
    width: 100%;
  }

  > .m-paragraph--featuredreference__references {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }

  .field-type-blockreference {
    .block {
      background-color: $white;
      text-transform: uppercase;
      text-align: center;
      padding-left: 8%;
      padding-right: 8%;
      padding-top: px2em(73);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;

      .content {
        opacity: 0.38;
        font-size: px2em(33);
        line-height: px2em(43, 33);
        font-weight: $bold;
        color: $blue-light;
        padding-bottom: 15px;

        p {
          margin: 0 0 px2em(27, 33) 0;
        }

        a {
          font-size: px2em(15, 33);
          line-height: px2em(20, 15);
          text-transform: none;
          display: inline-block;
          color: $blue-light;
          font-weight: $bold;
        }
      }
    }
  }
}

.m-paragraph--teaserbox__dark {
  color: $white;

  a {
    color: $white;
  }

  .m-paragraph--teaserbox__inner-overlay {
    @extend %teaserlistgrid__dark_overlay;
  }
}

.m-paragraph--teaserbox__inner {
  padding: px2em(174) px2em(45) px2em(85) px2em(45);
  min-height: px2em(105);

  @include bp('tablet') {
    padding: 80px 35px 40px 35px;
  }

  @include bp('mobile') {
    padding: 70px 23px 40px 23px;
  }
}

.m-paragraph--teaserbox__type {
  @extend %teaserlistgrid__type;

  .m-node--frontpage & {
    a {
      font-size: px2em(18, 30);
      font-weight: $semi-bold;

      &:first-letter {
        color: $white;
      }
    }
    span {
      font-size: px2em(17, 30);

      @include bp('tablet') {
        font-size: px2em(15, 30);
      }
    }
  }
}

.m-paragraph--teaserbox__link {
  display: block;

  &:hover {
    .m-paragraph--teaserbox__type,
    .m-paragraph--teaserbox__type:after {
      background-color: lighten($blue-light, 10);
    }
  }
}

.m-paragraph--teaserbox__teaser {
  font-size: px2em(19);
  line-height: px2em(26, 19);
  font-weight: $regular;
  z-index: 1;
  position: relative;

  @include bp('tablet') {
    font-size: px2em(17);
    line-height: px2em(23, 17);
  }

  @include bp('mobile') {
    font-size: px2em(15);
    line-height: px2em(21, 15);
  }
}
