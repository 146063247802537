.m-experts {
  @include clearfix;
  max-width: 828px;
  width: 100%;
  margin: 0 auto;
}

.m-experts__title {
  $height: 46px;
  background-color: $blue-dark;
  width: 140px;
  display: block;
  position: relative;
  margin: 50px 0 13px 0;

  @include bp('tablet') {
   margin-top: 0;
  }

  @include bp('mobile') {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
  }

  span {
    @include icon($icon-experts);
    color: $white;
    padding-left: 40px;
    margin-left: 14px;
    line-height: $height;
    display: block;

    @include font1();
    font-size: px2em(17, 20);
    font-weight: $semi-bold;
    text-transform: uppercase;

    @include bp('mobile') {
      font-size: px2em(12, 16);
      line-height: 30px;
      padding-left: 35px;
      padding-right: 8px;
      margin-left: 0;
    }

    &:before {
      color: $white;
      line-height: $height;
      font-size: 30px;

      @include bp('mobile') {
        font-size: 16px;
        top: 2px;
        line-height: 26px;
        left: 13px;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    border-left: 0.4em solid $blue-dark;
    border-bottom: $height solid transparent;

    @include bp('mobile') {
      border-bottom: 30px solid transparent;
    }
  }
}

.m-experts__list {
  @extend %ul;
  width: 43.6%;
  float: left;

  @include bp('mobile') {
    width: 100%;
    float: none;
  }
}

.m-experts__list + .m-experts__list {
  margin-left: 12.4%;

  @include bp('mobile') {
    margin-left: 0;
    border-top: 1px solid $white;
  }
}

.m-experts__trigger {
  @extend %list_item;

  a {
    color: $white;
    margin: 25px 0;
    display: block;
    @include font2();
    font-size: px2em(16);
    font-weight: $semi-bold;
    line-height: px2em(25, 16);

    @include bp('mobile') {
      margin: 15px 0;
    }

    &:hover {
      @include text_indent_effect();

      text-decoration: none;
    }
  }
}

.m-experts__trigger + .m-experts__trigger {
  border-top: 1px solid $white;
}
