$padding: 45px;

.m-node--factsheet--teaserbox {
  position: relative;
  height: 100%;
}

.m-node--factsheet--teaserbox__type {
  @extend %node_icon;
  position: relative;
  top: auto;
  margin-bottom: 26px;
  margin-left: $teaser_box_padding * -1;
  font-size: 1em;

  a {
    @include ci('article');
  }

  @include bp('mobile') {
    margin-left: $mobile_diff;
  }
}

.m-node--factsheet--teaserbox__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.m-node--factsheet--teaserbox__inner {
  @extend %node_teaser_box_inner;
  background-color: rgba($blue-dark, $bg_opacity);
}

.m-node--factsheet--teaserbox__title {
  font-size: 1em;

  a {
    @extend %node_title;
    text-align: left;
    margin-top: 0;
    font-size: px2em(19);
    font-weight: $bold;
    line-height: px2em(27, 19);
    color: $white;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

.m-node--factsheet--teaserbox__date {
  @extend %node_date;
  text-align: left;
  margin-bottom: 9px;

  > div {
    display: inline-block;

    & + div {
      &:before {
        content: ' | ';
        display: inline-block;
      }
      > div {
        display: inline-block;
      }
    }
  }
}

.m-node--factsheet--teaserbox__subtitle {
  @extend %node_subtitle;
  text-align: left;
}

.m-node--factsheet--teaserbox__tags {
  @extend %node_tags;
  text-align: left;
  margin-bottom: 0;
}

.m-node--factsheet--teaserbox__teaser {
  @extend %node_teaser;
  text-align: left;
}
