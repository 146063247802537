%sidebargrid {

	@include bp('tablet') {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		justify-content: space-between;
		align-items: stretch;
	}

	@include bp('mobile') {
		display: block;
		width: 100%;
	}
}

%sidebargrid__trigger {

	@include bp('tablet') {
		&:first-of-type {
			width: 30%;
		}

		&:last-of-type {
			width: 66%;
		}
	}

	@include bp('mobile') {
		-ms-flex-wrap: wrap;
		width: 100%;
		align-self: left;

		&:first-of-type {
			width: 100%;
		}

		&:last-of-type {
			width: 100%;
		}
	}
}
