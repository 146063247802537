.m-node--list__header {
  @extend %node_header;
  position: relative;
  margin-bottom: 0;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    background: $blue-dark;
    left: -100%;
    right: -100%;
    top: -100px;
    bottom: 0;
  }
}

.m-node--list__title {
  @extend %node_header_title;

  #page[data-list="blog_posts"] & {
    @include node_header_icon_title($icon-blog, $white);
  }
  #page[data-list="news"] & {
    @include node_header_icon_title($icon-news);
  }
  #page[data-list="factsheets"] & {
    @include node_header_icon_title($icon-factsheet);
  }
  #page[data-list="dossiers"] & {
    @include node_header_icon_title($icon-dossiers);
    padding-left: 115px;

    &:before {
      font-size: 90px;

      @include bp('tablet') {
        font-size: 80px;
      }

      @include bp('mobile') {
        font-size: 70px;
      }
    }
  }
}

.m-node--list__description {
  @extend %node_header_description;
}

.m-node--list__content {
  position: relative;
}

.m-node--list__view,
.m-node--list--view--archive-events {
  .views-exposed-form {
    padding: 45px 0 25px 0;

    .views-exposed-widgets {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: 15px;
      margin: 0;

      &:after {
        content: '';
        position: absolute;
        width: 40%;
        left: 30%;
        top: calc(100% + 25px);
        height: 3px;
        background-color: $blue-light;
        visibility: visible;
      }

      .views-exposed-widget {
        padding: 0 1em;
      }
    }

    .views-submit-button {
      display: none;
    }

    label {
      text-transform: uppercase;
      display: block;
      padding-bottom: 3px;
      text-align: center;
    }

    select {
      min-width: 250px;
    }
  }
}

.m-node--list__view {
  .view-content {
    @extend %teaserlistgrid;
    margin: 60px 0 0;
  }

  .views-row {
    @extend %teaserlistgrid__trigger;
  }

  #views-exposed-form-lists-experts {
    .views-exposed-widgets {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;

      .views-exposed-widget {
        float: none;
      }
      @include bp('smalldesktop') {
        width: 700px;
      }
      @include bp('tablet') {
        width: 700px;
      }
      @include bp('mobile') {
        width: 260px;
      }
    }
  }
}

.m-node--list__view-archive_link {
  text-align: center;

  a {
    @extend %form_submit;
    font-size: px2em(14);
    display: inline-block;
    height: auto;
    line-height: 1em;
    padding: 1em 1.5em 0.9em;
  }
}

.m-node--list--teaser__type {
  @extend %teaserlistgrid__type_link;
}

.m-node--list--teaser__inner {
  @extend %teaserlistgrid__inner;
}

.m-node--list--teaser__content {
  @extend %teaserlistgrid__content;
}

.m-node--list--teaser__title {
  @extend %teaserlistgrid__title;
}

.m-node--list--teaser__stamp {
  @extend %teaserlistgrid__stamp;
}

.m-node--list--teaser__tags {
  @extend %teaserlistgrid__tags;

  a {
    font-weight: $light;
  }
}

.m-node--list--teaser__teaser {
  padding-top: 1em;
}

.m-node--list--teaser__dark {
  color: $white;

  a {
      color: $white;
  }

  .m-node--list--teaser__background {
      @extend %teaserlistgrid__dark_overlay;
  }
}

.m-node--list--teaser__date_author {
  @extend %teaserlistgrid__date;

  div {
    display: inline;
  }
}

.m-node--list--teaser__authors {
  &:before {
    content: ' | ';
  }

  .m-node--dossier__authors_author {
    &:not(:last-child):after {
      color: $white;
    }
  }

  & + .m-node--list--teaser__location {
    &:before {
      content: ' | ';
    }
  }
}

.m-node--dossier__authors_author {
  display: inline;

  a {
    font-size: px2em(15, 15);
    line-height: px2em(20, 15);
  }
}

#block-views-lists-events-archive {
  position: relative;
  text-align: center;
  margin-top: -90px;
}

#block-views-lists-events-archive h2 {
  @include font1();
  color: $grey5;
  margin: 0 auto 50px;
  display: inline-block;
  font-weight: $bold;
  font-size: px2em(35);
  line-height: px2em(47, 35);
  text-transform: uppercase;
  position: relative;
  background-color: $white;
  padding: 0 50px;
}

.m-node--list--view--archive-events {
  text-align: left;

  .view-content {
    @extend %teaserlistgrid;
    margin: 60px 0 0;
  }

  .views-row {
    @extend %teaserlistgrid__trigger;
  }

  .view-intertitle {
    position: relative;
    text-align: center;
    margin-top: 20px;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      top: 23px;
      height: 8px;
      background-color: $grey2;
    }

    & + .view-content {
      margin-top: 0;
    }
  }
}

.m-node--list--view--archive {
  .views-row {
    width: 100%;
    background-color: transparent;
    min-height: 0;
    display: block;
    margin: 16px 0 0 0;

    &:before {
      margin-bottom: 17px;
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $grey3;
    }

    &.views-row-first {
      margin-top: 0;

      &:before {
        display: none;
      }
    }
  }

  .m-node--list--teaser__inner {
    padding-top: 0;
  }

  .m-node--list--teaser__content {
    padding: 0;
  }

  .m-node--list--teaser__type {
    display: none;
  }

  .m-node--list--teaser__date_author{
    color: $text2;
  }

  .m-node--list--teaser__title {
    margin-top: px2em(13, 19);
    font-size: px2em(19, 20);
    line-height: px2em(27, 19);

    a {
      color: $text1;
    }
  }

  .m-node--list--teaser__tags {
    margin-top: px2em(0);
  }
}
