.l-page--network {
  padding-top: $header-height;

  @include bp('tablet') {
    padding-top: $tablet-header-height;
  }

  @include bp('mobile') {
    padding-top: $mobile-header-height;
  }
}
