%mainnavgrid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  place-content: flex-end space-between;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  align-content: flex-start;
  column-gap: 30px;

  @include bp('mobile') {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

%mainnavgrid__trigger {
  position: relative;
  padding-bottom: 36px;

  @include bp('tablet') {
    padding-bottom: 20px;
  }

  .shrink & {
    padding-bottom: 16px;
  }

  @include bp('mobile') {
    -ms-flex-wrap: wrap;
    width: 100%;
    align-self: left;
    padding-bottom: 0;
  }

  &:hover {
    .m-menuitem__subnavi {
      display: block;
    }
  }

  &:last-of-type {
    .m-menuitem__subnavi {
      @include bp('desktop') {
        left: auto;
        right: -20px;
      }

      @include bp('bigdesktop') {
        left: -56px;
        right: auto
      }

      @include bp('smalldesktop') {
        left: auto;
        right: -20px;
      }

      @include bp('tablet') {
        left: auto;
        right: -20px;
      }

      @include bp('mobile') {
        right: auto;
      }
    }
  }
}
