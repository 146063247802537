
%footermenu_item {
  a {
    color: $white;
    font-size: px2em(15);
    line-height: px2em(27, 15);
    font-weight: $regular;
  }
}

.m-footer__nav {
  width: 73%;
  float: left;
  margin-bottom: 32px;

  @include bp('tablet') {
    margin-bottom: 5px;
  }
}

.m-footer__footermenu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: stretch;

  -ms-flex-pack: justify;
  justify-content: space-between;
  place-content: flex-end space-between;
  // align-items: stretch;
  margin-bottom: 15px;

  @include bp('tablet') {
    display: none;
  }

  @include bp('print') {
    display: none;
  }
}

.m-footer__footermenu__list__lvl1 {
  @extend %ul;
  float: left;
  width: auto;
  padding-right: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @include bp('tablet') {
    margin-bottom: 20px;
  }
}

.m-footer__footermenu__list__lvl1__trigger {
  @extend %list_item;
  @extend %footermenu_item;

  > a {
    font-weight: $bold;
    font-size: px2em(16);
    line-height: px2em(22, 16);
    text-transform: uppercase;
  }
}

.m-footer__footermenu__list__lvl2 {
  @extend %ul;
}

.m-footer__footermenu__list__lvl2__trigger {
  @extend %list_item;
  @extend %footermenu_item;
}

.m-footer__servicemenu {
  width: 100%;
  float: left;

  @include bp('print') {
    display: none;
  }
}

.m-footer__servicemenu__list {
  @extend %ul;
  float: right;
}

.m-footer__servicemenu__list__trigger {
  @extend %list_item;
  @extend %footermenu_item;
  display: inline-block;

  a {
    font-size: px2em(12);
    line-height: px2em(25, 12);
    display: block;
    vertical-align: bottom;

    @include bp('mobile') {
      font-size: px2em(11);
      line-height: px2em(15, 11);
    }
  }
}

.m-footer__servicemenu__list__trigger + .m-footer__servicemenu__list__trigger {
  a {
    &:before {
      content: ' | ';
    }
  }
}

.m-footer__servicemenu_copyrights {
  float: right;
  margin-top: 3px;

  @include bp('mobile') {
    margin-top: 3px;
  }

  @include bp('print') {
    float: left;
  }

  span {
    font-size: px2em(12);
    line-height: px2em(25, 12);
    margin-left: 48px;
    display: block;
    vertical-align: bottom;
    font-weight: $regular;

    @include bp('mobile') {
      font-size: px2em(11);
      line-height: px2em(15, 11);
    }
  }
}
