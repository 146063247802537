.l-inner-wrapper {
	width: 1500px;
	margin: 0 auto;
	position: relative;

	@include bp('small-desktop') {
		width: 100%;
	}
}

@include bp('print') {
	@page {
		margin: 2.5cm 2.5cm 3cm;
	}
}
