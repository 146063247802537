.m-form--application__event {
    visibility: hidden;
    clear: both;
    height: 0;
    overflow: hidden;

    * {
        visibility: hidden;
        height: 0;
    }

    #webform-component-reasons-event {
        display: none;
    }

    &.m-form--application__event--simple {
        #webform-component-reasons,
        #webform-component-outlets-for-publication,
        #webform-component-recommendation,
        #webform-component-travel-expenses,
        #webform-component-travel-expenses-support,
        #webform-component-visa,
        #webform-component-valid-passport,
        #webform-component-documents-and-visa {
            display: none;
        }
        #webform-component-reasons-event {
            display: block;
        }
    }

    &.active {
        visibility: visible;
        height: auto;

        * {
            visibility: visible;
            height: auto;
        }
    }

    .webform-client-form {
        padding: 65px 0 45px 0;
    }

    .webform-component-markup {
        p {
            margin-top: 0;
        }
    }

    .webform-component-fieldset {
        margin: 0;
        padding: 0;
        border: 0;
        background-color: transparent;

        &#webform-component-personal-details {
            legend {
                padding-top: 0;
            }
        }

        legend {
            font-size: 1em;
            margin-bottom: 0;
            padding-top: 1em;
            font-weight: $semi-bold;

            @include bp('mobile') {
                font-size: px2em(14);
                line-height: px2em(18, 14);
            }

            .fieldset-legend {
                padding: 0 0 10px 0;
                font-weight: $semi-bold;
                display: inline-block;
                color: $text2;
            }
        }

        .fieldset-description {
            font-weight: 300;
        }

        ul {
            margin-left: 0;
            padding-left: 1.4em;
        }
    }

    #webform-component-country,
    #webform-component-journalist-for,
    #webform-component-social-media-channel {
        input,
        select,
        .select2-container {
            vertical-align: top;
        }
    }

    #webform-component-social-media-channel {
        margin-bottom: 0;
    }

    #webform-component-social-media-channel-00 {
        padding-left: $webform-label-width;
        margin-top: -20px;

        @include bp('mobile') {
            padding-left: 0;
            margin-top: 0;
        }

        label {
            display: inline-block;
            margin-right: 0;
            cursor: pointer;
            padding: 0.45em 1em 0.5em;
            background-color: $red-light;
            color: #FFF;
            font-weight: 600;
            text-align: center;
            width: auto;
            font-size: px2em(16);
            line-height: px2em(18, 16);

            &:hover {
                background-color: $red-dark;
            }
        }

        input {
            width: 99.3%;
            display: none;
        }

        &.active {
            padding-bottom: 10px;

            label {
                display: none;
            }

            input {
                display: block;
            }
        }
    }

    #webform-component-clew-journalism-network--network-member {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 0;

        label {
            width: 20%;
            margin-right: 2%;

            @include bp('mobile') {
                width: 93%;
                margin-right: 0;
            }
        }
        .form-radios {
            width: 77.2%;
            align-self: flex-start;

            @include bp('mobile') {
                width: 93%;
            }
        }
    }

    #webform-component-freelance-journalist {
        margin-top: 0;
    }

    #webform-component-travel--travel-expenses,
    #webform-component-outlets-for-publication,
    #webform-component-hear-about {
        textarea {
            height: 60px;
        }
    }

    #webform-component-travel {
        .webform-component-markup {
            margin-bottom: 5px;

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    #webform-component-travel--travel-expenses {
        margin-top: 0;
    }

    #webform-component-freelance-journalist {
        .form-checkboxes {
            padding-left: $webform-label-width;

            @include bp('mobile') {
                padding-left: 0;
            }
        }
    }

    #webform-component-publications {
        legend {
            float: left;
            width: 20%;
            margin-right: 2%;
            padding-top: 0;

            .fieldset-legend {
                font-weight: $regular;
                font-style: italic;
            }

            @include bp('mobile') {
                float: none;
                width: 100%;
                margin-right: 0;
            }
        }

        #webform-component-publications--publications-info {
            float: left;
            width: 77.2%;

            @include bp('mobile') {
                float: none;
                width: 100%;
                font-size: px2em(14);
                line-height: px2em(20, 14);
            }

            p {
                margin-top: 0;
            }
        }

        .webform-component-textfield {
            clear: both;
        }
    }

    #webform-component-recommendation {
        padding-top: 1em;

        .fieldset-description {
            padding-bottom: $webform-item-padding;
            font-weight: $regular;

            @include bp('mobile') {
                font-size: px2em(14);
                line-height: px2em(20, 14);
            }
        }
    }

    #webform-component-documents-and-visa,
    #webform-component-travel-expenses,
    #webform-component-travel-expenses-support,
    #webform-component-professional-profile--freelance-journalist,
    #webform-component-professional-profile--working-time,
    #webform-component-clew-journalism-network--note,
    #webform-component-social-media--note,
    #webform-component-travel,
    #webform-component-reasons,
    #webform-component-reasons-event,
    #webform-component-outlets-for-publication,
    #webform-component-request,
    #webform-component-financing,
    #webform-component-visa,
    #webform-component-valid-passport,
    #webform-component-hear-about,
    #webform-component-agreement,
    #webform-component-note,
    #webform-component-yes,
    #webform-component-data-protection,
    #webform-component-informed-intro,
    #webform-component-informed,
    #edit-actions {
        padding-left: $webform-label-width;

        @include bp('mobile') {
            padding-left: 0;
            font-size: px2em(14);
            line-height: px2em(20, 14);

            label, a {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }

    #webform-component-professional-profile--working-time {
        flex-wrap: wrap;

        .description {
            width: 100%;
            padding-left: 0;
        }

        .form-text {
            width: 25px;
            margin: 0 7px;
            padding: 3px 1%;
        }
    }

    #webform-component-professional-profile--how-long-number {
        position: relative;

        select {
            width: 10em;
        }

        .select2-container {
            width: 8em;
        }

        #webform-component-professional-profile--how-long-type {
            margin: 0 0 0 1em;
            align-self: flex-start;

            @include bp('mobile') {
                margin: 1em 0 0 0;
            }
        }
    }

    #webform-component-professional-profile--media-outlets,
    #webform-component-professional-profile--topics {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        @include bp('mobile') {
            display: block;
        }

        label {
            width: 20%;
            margin-right: 2%;

            @include bp('mobile') {
                width: 93%;
                margin-right: 0;
            }
        }
        .form-textarea-wrapper {
            width: 77.2%;
            align-self: flex-start;

            @include bp('mobile') {
                width: 93%;
            }
        }
        .description {
            padding-left: $webform-label-width;
            width: 78%;

            @include bp('mobile') {
                padding-left: 0;
                width: 93%;
            }
        }
    }

    #webform-component-data-protection {
        padding-bottom: 15px;
    }

}
